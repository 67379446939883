// User Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import { CommunityRepo } from './Community'
import axios from 'axios'
import router from '@/router'
import { Globalconfig } from '@/models/Globalconfig'
import { UserCommunity, UserCommunityRepo } from './UserCommunity'
import { defineStore, getActivePinia } from 'pinia'
import emitter from 'tiny-emitter/instance'

class User extends Model {

  // This is the name used as module name of the Vuex Store.
  static entity = 'users'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null).notNullable(),
      community_id: this.attr(null),
      name: this.string(''),
      email: this.string(''),
      avatar: this.string(''),
      usercommunities: this.hasMany(UserCommunity, 'user_id'),
      created: this.attr(null)
    }
  }

  static casts() {
    return {
      id: NumberCast,
      community_id: NumberCast
    }
  }

  static getCurrentUser() {
    const config = useRepo(Globalconfig).find("currentUser")
    if (config) {
      return config.value
    } else {
      return null
    }
  }

  static getToken() {
    return localStorage.getItem('user-token') || ''
  }
}

class UserRepo extends Repository {
  use = User

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const users = await axios.get('/user/get.php')
        resolve(this.save(users.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await axios.get('/user/get.php?id=' + id)
        resolve(this.save(user.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await axios.post('/user/post.php', data)
        resolve(this.save(user.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $validate(email) {
    return axios.post('/user/validate/post.php', {
      email: email
    })
  }

  async $setPassword(data) {
    return axios.post('/user/password/post.php', data)
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await axios.put('/user/put.php?id=' + id, data)
        resolve(this.save(user.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/delete.php?id='+id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $forgottenPassword(username) {
    return axios.post('/user/forgottenpassword/post.php', {
      username: username
    })
  }

  async $login(username, password) {
    return new Promise((resolve, reject) => {
      axios.post('/user/login/post.php', {
          username: username,
          password: password
        },{
            save: false
      })
        .then(async function(resp) {
          const token = resp.data.token
          const userId = resp.data.userId
          localStorage.setItem('user-token', token) // store the token in localstorage
          localStorage.setItem('user-id', userId) // store the token in localstorage
          axios.defaults.headers.common['Authorization'] = 'Bearer '+token

          // Insert for global availability
          useRepo(Globalconfig).save({
            name: 'isAuthenticated',
            value: true
          })

          await useRepo(UserRepo).$loadCurrentUser()

          emitter.emit('loggedIn')

          resolve(resp)
        })
        .catch(err => {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
    })
  }

  async $loginWithGoogle(code) {
    return new Promise((resolve, reject) => {
      axios.post('/user/login/post.php', {
          googleAuthCode: code
        },{
          save: false
      })
        .then(async function(resp) {
          const token = resp.data.token
          const userId = resp.data.userId
          localStorage.setItem('user-token', token) // store the token in localstorage
          localStorage.setItem('user-id', userId) // store the token in localstorage
          axios.defaults.headers.common['Authorization'] = 'Bearer '+token

          // Insert for global availability
          useRepo(Globalconfig).save({
            name: 'isAuthenticated',
            value: true
          })

          await useRepo(UserRepo).$loadCurrentUser()

          emitter.emit('loggedIn')

          resolve(resp)
        })
        .catch(err => {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
    })
  }

  async logout() {
    localStorage.removeItem('user-token')
    localStorage.removeItem('user-id')
    localStorage.removeItem('community-id')
    axios.defaults.headers.common['Authorization'] = ''

    await router.push('login').catch(err => { })

    // Delete entire data.
    const activepinia = getActivePinia()
    if (activepinia) {
      Object.entries(activepinia.state.value).forEach(([storeName, state]) => {
        const storeDefinition = defineStore(storeName, state)
        const store = storeDefinition(activepinia)
        store.$reset()
      });

      // Put back offline info since it is expected everywhere
      useRepo(Globalconfig).save({
        name: 'isOffline',
        value: false
      })
    }

  }

  async autolog() {
    const token = localStorage.getItem('user-token')
    if(token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

      // Insert for global availability
      useRepo(Globalconfig).save({
        name: 'isAuthenticated',
        value: true
      })

      await this.$loadCurrentUser()

      emitter.emit('loggedIn')
    }
  }

  isAuthenticated() {
    return !!localStorage.getItem('user-token')
  }

  async $loadCurrentUser() {
    const id = localStorage.getItem('user-id')
    if(id) {
      const user = await this.$get(id)

      // Insert for global availability
      useRepo(Globalconfig).save({
        name: 'currentUser',
        value: user
      })

      await Promise.all([
        useRepo(CommunityRepo).$fetch(),
        useRepo(UserCommunityRepo).$fetch()
      ])

      const userCommunities = this.has('usercommunities').with('usercommunities').get()
      if (userCommunities.length && userCommunities[0].usercommunities.length) {
        useRepo(CommunityRepo).setCurrentCommunity(userCommunities[0].usercommunities[0].community_id)
      }
    }
    return null
  }

  async $getAvatars() {
    return new Promise((resolve, reject) => {
      axios.get('/user/avatars/get.php')
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export {
  User,
  UserRepo
}
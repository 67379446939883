<template>
  <v-container fluid>
    <v-dialog
      v-if="scanDialog"
      id="scanner"
      v-model="scanDialog"
      width="800px"
      scrollable
      :fullscreen="$vuetify.display.xs"
    >
      <v-card height="600">
        <v-card-title class="bg-primary text-white text-h5 d-flex justify-space-between align-center">
          Prenez une photo du menu
          <v-btn
            icon
            variant="text"
            @click="scanDialog = false"
          >
            <v-icon size="x-large">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 scrollbars">
          <recipe-image-scanner @done="doneScanning" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <date-switch :days="days" :allow-selection="daysToDisplay.length < 7" @previousWeek="previousWeek" @nextWeek="nextWeek" @selectDay="selectDay" />

    <v-row dense justify="center" align="center">
      <v-col cols="auto">
        <v-btn variant="text" :size="$vuetify.display.xs ? 'x-small' : 'default'" title="Remplir la liste de courses" @click="openGroceryDialog">
          <v-icon>
            mdi-cart-plus
          </v-icon>
          Ajouter aux courses
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn variant="text" :size="$vuetify.display.xs ? 'x-small' : 'small'" class="ml-2" @click="today()">
          <v-icon start :size="$vuetify.display.xs ? 'x-small' : 'small'">
            mdi-calendar-today
          </v-icon>
          Aujourd'hui
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          variant="text"
          :size="$vuetify.display.xs ? 'x-small' : 'small'"
          @click="scanDialog = true"
        >
          <v-icon start :size="$vuetify.display.xs ? 'x-small' : 'small'">
            mdi-qrcode
          </v-icon>
          Scanner
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <VDropdown
          class="inlineTooltip"
          auto-hide
        >
          <v-btn
            density="comfortable"
            icon="mdi-format-list-text"
            variant="outlined"
            :size="$vuetify.display.xs ? 'x-small' : 'small'"
          />
          <template #popper>
            <div class="pa-2 pt-0 allIngsContainer scrollbars">
              <p class="sticky text-h5 text--primary py-3 my-0 bg-white">
                Utilisé cette semaine :
              </p>
              <div class="ideasContainer scrollbars">
                <template v-for="section in allIngs" :key="section.value">
                  <p v-if="section.ingredients && section.ingredients.length > 0" class="text-h6 text-primary">
                    {{ section.text }}
                  </p>
                  <div v-for="ingredient in section.ingredients" :key="ingredient.id" class="d-flex align-start pb-2">
                    <span :class="!ingredient.image_url ? 'ml-6 pl-2 d-flex align-center' : 'd-flex align-center'">
                      <v-img
                        v-if="ingredient.image_url"
                        :src="ingredient.image_url"
                        height="24"
                        width="24"
                        class="ma-0 mr-2"
                      />
                      {{ ingredient.name }}
                      <VDropdown
                        class="inlineTooltip ml-2"
                        auto-hide
                      >
                        <v-chip
                          pill
                          title="Repas"
                          size="small"
                        >
                          <v-avatar start color="secondary">
                            <v-icon>
                              mdi-silverware-fork-knife
                            </v-icon>
                          </v-avatar>
                          {{ ingredient.composition.length }}
                        </v-chip>
                        <template #popper>
                          <div class="pa-2">
                            <div v-for="compo in ingredient.composition" :key="compo.id" class="upperFirstLetter">
                              {{ compo.meal.name }} ({{ compo.meal.shortDate + getMealBakingTypes(compo.meal) }})
                            </div>
                          </div>
                        </template>
                      </VDropdown>
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </VDropdown>
      </v-col>
    </v-row>

    <v-container
      v-touch="{
        left: () => nextDay(),
        right: () => previousDay()
      }"
      fluid
    >
      <v-row dense class="sticky daysRow">
        <v-col
          v-for="item in daysToDisplay"
          :id="dayId(item.date)"
          :key="item.key"
          class="weekDay"
        >
          <div :class="'text-h5 text-center text-capitalize ' + (item.today ? 'today' : '')">
            {{ item.dayLabel }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <span class="mealType">
            Déjeuner
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col
          v-for="item in daysToDisplay"
          :id="dayId(item.date)"
          :key="item.key"
          class="weekDay"
        >
          <meal-summary-card
            :key="item.lunchId"
            class="mealCard"
            :meal-id="item.lunch ? item.lunch.id : 0"
            :show-alt-btn="!item.lunchalt"
            :date="item.date"
            type="lunch"
            :loading="loading || (item.lunch && loadingMealId === item.lunch.id)"
            @moveMeal="openMoveSelector"
            @create="createMeal('lunch', item.date)"
            @createalt="createMeal('lunchalt', item.date)"
            @edit="editMeal(item.lunch, 'lunch', item.date)"
            @delete="deleteMeal(item.lunch)"
          />
          <meal-summary-card
            v-if="item.lunchalt"
            :key="item.lunchaltId"
            class="mealCard"
            :meal-id="item.lunchalt ? item.lunchalt.id : 0"
            :date="item.date"
            type="lunchalt"
            :loading="loading || (item.lunchalt && loadingMealId === item.lunchalt.id)"
            @moveMeal="openMoveSelector"
            @edit="editMeal(item.lunchalt, 'lunchalt', item.date)"
            @delete="deleteMeal(item.lunchalt)"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <span class="mealType">
            Diner
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col
          v-for="item in daysToDisplay"
          :id="dayId(item.date)"
          :key="item.key"
          class="weekDay"
        >
          <meal-summary-card
            :key="item.dinerId"
            class="mealCard"
            :meal-id="item.diner ? item.diner.id : 0"
            :show-alt-btn="!item.dineralt"
            :date="item.date"
            :loading="loading || (item.diner && loadingMealId === item.diner.id)"
            type="diner"
            @moveMeal="openMoveSelector"
            @create="createMeal('diner', item.date)"
            @createalt="createMeal('dineralt', item.date)"
            @edit="editMeal(item.diner, 'diner', item.date)"
            @delete="deleteMeal(item.diner)"
          />
          <meal-summary-card
            v-if="item.dineralt"
            :key="item.dineraltId"
            class="mealCard"
            :meal-id="item.dineralt ? item.dineralt.id : 0"
            :date="item.date"
            :loading="loading || (item.dineralt && loadingMealId === item.dineralt.id)"
            type="dineralt"
            @moveMeal="openMoveSelector"
            @edit="editMeal(item.dineralt, 'dineralt', item.date)"
            @delete="deleteMeal(item.dineralt)"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="editedMeal"
      v-model="editMealDialog"
      :max-width="mealCardDialogBig ? '1000px' : '500px'"
      :fullscreen="$vuetify.display.xs"
      persistent
      scrollable
    >
      <meal-card
        :key="editedMeal.id ? editedMeal.id : (editedMeal.type + (editedMeal.date ? editedMeal.date.getTime() : '0'))"
        :meal="editedMeal"
        :focus="editMealDialog"
        @close="closeEditMeal"
        @toggleSize="toggleMealCardSize"
      />
    </v-dialog>

    <v-dialog v-model="groceryDialog" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="bg-primary text-white">
          <span class="text-h5">Nouvelle liste de courses</span>
        </v-card-title>

        <v-card-text class="pb-3 px-2">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                Dates des repas à ajouter :
              </v-col>
              <v-col cols="12" class="pt-0">
                <VueDatePicker
                  v-model="groceryDates"
                  :range="true"
                  :enable-time-picker="false"
                  select-text="OK"
                  cancel-text="ANNULER"
                  locale="fr"
                  :teleport="true"
                  position="bottom"
                  allow-prevent-default
                />
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="createList" hide-details density="compact">
                  <v-radio label="Créer une nouvelle liste de course" :value="true" />
                  <v-radio label="Remplacer dans la liste actuelle" :value="false" />
                </v-radio-group>
                <span
                  class="groceryListDetail"
                >
                  Liste actuelle :
                  <VDropdown
                    placement="right"
                    class="inlineTooltip"
                    @apply-show="openCurrentGroceryList"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon>
                    <template #popper>
                      <template v-if="!loadingCurrentGroceryList">
                        <div class="pa-2">
                          <template v-for="item in currentGroceryList">
                            <div v-if="!item.gathered" :key="item.id">
                              {{ item.name }}
                            </div>
                          </template>
                          <div v-if="nbCurrentGroceryListGathered > 0">
                            (+ {{ nbCurrentGroceryListGathered }} déjà pris)
                          </div>
                        </div>
                      </template>
                      <div v-if="loadingCurrentGroceryList" class="pa-2">
                        Chargement...
                      </div>
                    </template>
                  </VDropdown>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  variant="outlined"
                  class="mr-5"
                  :disabled="generateGroceryLoading"
                  @click="groceryDialog = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!areGroceryDatesSet"
                  :variant="!areGroceryDatesSet ? 'outlined' : 'flat'"
                  :loading="generateGroceryLoading"
                  @click="generateGroceries"
                >
                  Ajouter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="moveMeal"
      v-model="moveDialog"
      width="960px"
      content-class="moveDialog"
      :fullscreen="$vuetify.display.smAndDown"
      scrollable
    >
      <v-card
        :loading="loadingMove"
      >
        <v-card-title class="bg-primary text-white d-flex justify-space-between align-center">
          Où déplacer le repas ?
          <v-btn
            icon
            variant="text"
            @click="moveDialog = false"
          >
            <v-icon size="x-large">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container class="scrollbars mainContainer pa-0">
            <v-row class="switchWeek mt-2 ma-0">
              <v-col cols="12" class="px-0 d-flex align-center justify-center">
                <v-btn icon color="primary" variant="text" size="small" @click="setMoveFirstDay(-7)">
                  <v-icon size="x-large">
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                Semaine du {{ moveFirstDayLabel }} au {{ moveLastDaylabel }}
                <v-btn icon color="primary" variant="text" size="small" @click="setMoveFirstDay(7)">
                  <v-icon size="x-large">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
            <v-row dense class="flex-nowrap ma-0" align="stretch">
              <v-col class="eighth pr-2 stickyLeft rowTitle" />
              <v-col
                v-for="item in moveDays"
                :key="item.key"
                class="eighth"
              >
                <div class="day">
                  {{ $vuetify.display.xs ? item.shortDayLabel : item.dayLabel }}
                </div>
              </v-col>
            </v-row>
            <v-row dense class="flex-nowrap ma-0">
              <v-col class="text-center eighth stickyLeft pr-2 rowTitle" align-self="stretch">
                Déjeuner
              </v-col>
              <v-col
                v-for="item in moveDays"
                :key="item.key"
                class="eighth"
              >
                <div
                  :class="'elevation-2 meal upperFirstLetter text-body-2 ' + (item.lunch && item.lunch.id === moveMeal.id ? ' moveSource ' : ' moveTarget ') + (!item.lunch ? ' emptyTarget ' : '') + (item.lunch && item.lunch.ignored ? ' ignored ' : '')"
                  @click="exchangeMeal(item.lunch, item.date, 'lunch')"
                >
                  <template v-if="item.lunch && !item.lunch.ignored">
                    {{ item.lunch.name }}
                  </template>
                  <template v-if="item.lunch && item.lunch.ignored">
                    Rien à préparer
                    <template v-if="item.lunch.comment">
                      <br>
                      ({{ item.lunch.comment }})
                    </template>
                  </template>
                  <template v-if="!item.lunch">
                    <v-icon size="56">
                      mdi-plus-circle-outline
                    </v-icon>
                  </template>
                  <template v-if="item.lunch && item.lunch.id === moveMeal.id">
                    <div class="moveSourceIndicator">
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </div>
                  </template>
                  <template v-if="!item.lunch || item.lunch.id !== moveMeal.id">
                    <div class="moveTargetIndicator">
                      <v-icon>
                        mdi-import
                      </v-icon>
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="ma-0 mb-8 flex-nowrap">
              <v-col class="text-center eighth stickyLeft pr-2 rowTitle" align-self="stretch">
                Déjeuner alternatif
              </v-col>
              <v-col
                v-for="item in moveDays"
                :key="item.key"
                class="eighth"
              >
                <div
                  :class="'alt elevation-2 meal upperFirstLetter text-body-2 ' + (item.lunchalt && item.lunchalt.id === moveMeal.id ? ' moveSource ' : ' moveTarget ') + (!item.lunchalt ? ' emptyTarget ' : '') + (item.lunchalt && item.lunchalt.ignored ? ' ignored ' : '')"
                  @click="exchangeMeal(item.lunchalt, item.date, 'lunchalt')"
                >
                  <template v-if="item.lunchalt && !item.lunchalt.ignored">
                    {{ item.lunchalt.name }}
                  </template>
                  <template v-if="item.lunchalt && item.lunchalt.ignored">
                    Rien à préparer
                    <template v-if="item.lunchalt.comment">
                      <br>
                      ({{ item.lunchalt.comment }})
                    </template>
                  </template>
                  <template v-if="!item.lunchalt">
                    <v-icon size="56">
                      mdi-plus-circle-outline
                    </v-icon>
                  </template>
                  <template v-if="item.lunchalt && item.lunchalt.id === moveMeal.id">
                    <div class="moveSourceIndicator">
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </div>
                  </template>
                  <template v-if="!item.lunchalt || item.lunchalt.id !== moveMeal.id">
                    <div class="moveTargetIndicator">
                      <v-icon>
                        mdi-import
                      </v-icon>
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="ma-0 flex-nowrap">
              <v-col class="eighth text-center stickyLeft pr-2 rowTitle" align-self="stretch">
                Dîner
              </v-col>
              <v-col
                v-for="item in moveDays"
                :key="item.key"
                class="eighth"
              >
                <div
                  :class="'elevation-2 meal upperFirstLetter text-body-2 ' + (item.diner && item.diner.id === moveMeal.id ? ' moveSource ' : ' moveTarget ') + (!item.diner ? ' emptyTarget ' : '') + (item.diner && item.diner.ignored ? ' ignored ' : '')"
                  @click="exchangeMeal(item.diner, item.date, 'diner')"
                >
                  <template v-if="item.diner && !item.diner.ignored">
                    {{ item.diner.name }}
                  </template>
                  <template v-if="item.diner && item.diner.ignored">
                    Rien à préparer
                    <template v-if="item.diner.comment">
                      <br>
                      ({{ item.diner.comment }})
                    </template>
                  </template>
                  <template v-if="!item.diner">
                    <v-icon size="56">
                      mdi-plus-circle-outline
                    </v-icon>
                  </template>
                  <template v-if="item.diner && item.diner.id === moveMeal.id">
                    <div class="moveSourceIndicator">
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </div>
                  </template>
                  <template v-if="!item.diner || item.diner.id !== moveMeal.id">
                    <div class="moveTargetIndicator">
                      <v-icon>
                        mdi-import
                      </v-icon>
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="ma-0 mb-8 flex-nowrap">
              <v-col class="eighth text-center stickyLeft pr-2 rowTitle" align-self="stretch">
                Dîner alternatif
              </v-col>
              <v-col
                v-for="item in moveDays"
                :key="item.key"
                class="eighth"
              >
                <div
                  :class="'alt elevation-2 meal upperFirstLetter text-body-2 ' + (item.dineralt && item.dineralt.id === moveMeal.id ? ' moveSource ' : ' moveTarget ') + (!item.dineralt ? ' emptyTarget ' : '') + (item.dineralt && item.dineralt.ignored ? ' ignored ' : '')"
                  @click="exchangeMeal(item.dineralt, item.date, 'dineralt')"
                >
                  <template v-if="item.dineralt && !item.dineralt.ignored">
                    {{ item.dineralt.name }}
                  </template>
                  <template v-if="item.dineralt && item.dineralt.ignored">
                    Rien à préparer
                    <template v-if="item.dineralt.comment">
                      <br>
                      ({{ item.dineralt.comment }})
                    </template>
                  </template>
                  <template v-if="!item.dineralt">
                    <v-icon size="56">
                      mdi-plus-circle-outline
                    </v-icon>
                  </template>
                  <template v-if="item.dineralt && item.dineralt.id === moveMeal.id">
                    <div class="moveSourceIndicator">
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </div>
                  </template>
                  <template v-if="!item.dineralt || item.dineralt.id !== moveMeal.id">
                    <div class="moveTargetIndicator">
                      <v-icon>
                        mdi-import
                      </v-icon>
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Meal, MealRepo } from '@/models/Meal'
import { CompositionRepo } from '@/models/Composition'
import { Ingredient, IngredientRepo } from '@/models/Ingredient'
import { GroceryList, GroceryListRepo } from '@/models/GroceryList'
import { RecipeMealRepo } from '@/models/RecipeMeal'
import { Grocery, GroceryRepo } from '@/models/Grocery'
import { RecipeRepo } from '@/models/Recipe'
import MealSummaryCard from '@/components/MealSummaryCard'
import MealCard from '@/components/MealCard'
import DateSwitch from '@/components/DateSwitch'
import RecipeImageScanner from '@/components/RecipeImageScanner'
import moment from 'moment'
import _ from 'lodash'
import shared from '@/shared'
import emitter from 'tiny-emitter/instance'

export default {
  name: 'WeekCalendar',
  components: {
    MealSummaryCard,
    MealCard,
    DateSwitch,
    RecipeImageScanner
  },
  data: () => ({
    loading: false,
    loadingMealId: 0,
    firstDay: null,
    selectedDay: null,
    groceryDialog: false,

    moveDialog: false,
    loadingMove: false,
    moveFirstDay: null,
    moveFirstDayLabel: '',
    moveLastDaylabel: '',
    moveMeal: null,
    groceryDates: [],
    groceryDatesMenu: false,
    createList: true,
    generateGroceryLoading: false,

    editMealDialog: false,
    editedMeal: null,
    mealCardDialogBig: false,

    loadingCurrentGroceryList: false,

    scanDialog: false
  }),
  computed: {
    currentGroceryList() {
      const list = useRepo(GroceryList).orderBy(gl => gl.created.getTime(), 'desc').limit(1).get()
      if (list.length === 1) {
        const lid = list[0].id
        return useRepo(Grocery).where('grocerylist_id', lid).get()
      }
      return []
    },
    nbCurrentGroceryListGathered() {
      const list = useRepo(GroceryList).orderBy(gl => gl.created.getTime(), 'desc').limit(1).get()
      if (list.length === 1) {
        const lid = list[0].id
        return useRepo(Grocery).where('grocerylist_id', lid).where('gathered', true).get().length
      }
      return 0
    },
    currentBreakpoint() {
      const bps = ['xs', 'sm', 'md', 'lg', 'xl']
      const bp = bps.filter(b => this.$vuetify.display[b])
      return bp.length ? bp[0] : '';
    },
    daysToDisplay() {
      const spread = {
        'xs': 1,
        'sm': 3,
        'md': 5,
        'lg': 7,
        'xl': 7
      }
      const nbDays = spread[this.currentBreakpoint] || 1
      const startNb = moment.utc(this.selectedDay).weekday()
      const start = Math.min(7 - nbDays, Math.ceil(Math.max(0, startNb - nbDays / 2)))
      return this.days.slice(start, start + nbDays)
    },
    days() {
      const firstDay = this.firstDay
      let d = 0
      let days = []
      for (d = 0; d < 7; d++) {
        const day = moment.utc(firstDay).add(d, 'days').toDate()

        const diner = useRepo(Meal)
          .where("type", 'diner')
          .where("date", value => moment.utc(value).isSame(day, "day"))
          .get()
        const dineralt = useRepo(Meal)
          .where("type", 'dineralt')
          .where("date", value => moment.utc(value).isSame(day, "day"))
          .get()
        const lunch  = useRepo(Meal)
          .where("type", 'lunch')
          .where("date", value => moment.utc(value).isSame(day, "day"))
          .get()
        const lunchalt = useRepo(Meal)
          .where("type", 'lunchalt')
          .where("date", value => moment.utc(value).isSame(day, "day"))
          .get()
        const timestamp = day.getTime()
        days.push({
          date: day,
          dayLabel: moment.utc(day).format('dddd D'),
          weekDayLetter: moment.utc(day).format('dd')[0],
          weekDayLabel: moment.utc(day).format('ddd'),
          monthLabel: moment.utc(day).format('MMMM'),
          monthShortLabel: moment.utc(day).format('MMM'),
          dayNumber: moment.utc(day).format('D'),
          today: moment.utc().isSame(day, 'day'),
          selected: moment.utc(this.selectedDay).isSame(day, 'day'),
          lunch: lunch.length > 0 ? lunch[0] : null,
          lunchId: lunch.length > 0 ? timestamp + '_lunch_' + lunch.id : timestamp + '_lunch',
          lunchalt: lunchalt.length > 0 ? lunchalt[0] : null,
          lunchaltId: lunchalt.length > 0 ? timestamp + '_lunchalt_' + lunchalt.id : timestamp + '_lunchalt',
          diner: diner.length > 0 ? diner[0] : null,
          dinerId: diner.length > 0 ? timestamp + '_diner_' + diner.id : timestamp + '_diner',
          dineralt: dineralt.length > 0 ? dineralt[0] : null,
          dineraltId: dineralt.length > 0 ? timestamp + '_dineralt_' + dineralt.id : timestamp + 'dineralt_'
        })
      }
      return days;
    },
    moveDays() {
      const firstDay = this.moveFirstDay
      let days = []

      // Get current week meals
      const weekMeals = useRepo(Meal).where('date', (value) => moment.utc(value).isSameOrAfter(firstDay, 'week')).orderBy('date', 'asc').get()

      let d
      for (d = 0; d < 7; d++) {
        const mDay = moment.utc(firstDay).add(d, 'days')
        const lunchMeal = weekMeals.filter((meal) => meal.type === 'lunch' && moment.utc(meal.date).isSame(mDay, 'day'))
        const lunchaltMeal = weekMeals.filter((meal) => meal.type === 'lunchalt' && moment.utc(meal.date).isSame(mDay, 'day'))
        const dinerMeal = weekMeals.filter((meal) => meal.type === 'diner' && moment.utc(meal.date).isSame(mDay, 'day'))
        const dineraltMeal = weekMeals.filter((meal) => meal.type === 'dineralt' && moment.utc(meal.date).isSame(mDay, 'day'))
        days.push({
          date: mDay.toDate(),
          dayLabel: this.$vuetify.display.smAndDown ? mDay.format('ddd D') : mDay.format('dddd D'),
          shortDayLabel: mDay.format('ddd D'),
          lunch: lunchMeal[0],
          lunchalt: lunchaltMeal[0],
          diner: dinerMeal[0],
          dineralt: dineraltMeal[0]
        })
      }

      return days
    },
    weekLabel() {
      const firstDay = this.firstDay;
      const mLastDay = moment.utc(this.firstDay).endOf('week');
      const mToday = moment.utc();

      if (firstDay.week() === mToday.week() - 1) {
        return 'Semaine dernière'
      }
      else if (mToday.week() === firstDay.week()) {
        return 'Cette semaine'
      }
      else if (firstDay.week() === mToday.week() + 1) {
        return "Semaine prochaine"
      }
      else {
        return 'Semaine du ' + firstDay.format('DD/MM/YY') + ' au ' + mLastDay.format('DD/MM/YY')
      }
    },
    areGroceryDatesSet() {
      return this.groceryDates && this.groceryDates.length === 2 && this.groceryDates[0] && this.groceryDates[1]
    },
    allIngs() {
      const ings = useRepo(Ingredient)
        .withAllRecursive()
        .whereHas('composition', (compoQuery) => {
          compoQuery.whereHas('meal', (mealQuery) => {
            mealQuery.where((meal) => moment(meal.date).isSame(this.firstDay, 'week'))
          })
        })
        .get()

      ings.map(i => {
        i.composition.sort((a, b) => {
          return moment(a.meal.date).isAfter(b.meal.date) ? 1 : -1
        })
        return i
      })

      // Sorting by type of gorcery
      const sections = Ingredient.allTypes()
      const found = {}
      sections.map(t => {
        t.ingredients = ings.filter(i => {
          if (i.type === t.value) {
            found[i.id] = true
            return true
          }
        })
      })
      // Getting all groceries with ingredient with no type
      const otherIngs = ings.filter(i => {
        return !found[i.id]
      })
      sections.push({
        text: "Autre",
        pluralText: "Autres",
        value: "_",
        ingredients: otherIngs
      })

      return sections
    }
  },
  created() {
    let date = null
    const vars = shared.getUrlVars()
    if (vars['date']) {
      date = moment.utc(vars['date'])
    }
    else {
      const localStart = localStorage.getItem('calendar-start')
      if (localStart) {
        date = moment.utc(localStart, moment.ISO_8601)
      }
    }

    if (date && date.isValid()) {
      this.selectDay(date.toDate())
    }
    else {
      this.today()
    }
  },
  mounted: async function () {
    if (!this.isOffline) {
      this.loading = true
      await Promise.all([
        useRepo(IngredientRepo).$fetch(),
        this.fetchMeals(),
        useRepo(GroceryListRepo).$getLatest()
      ])
      this.loading = false
    }
  },
  methods: {
    async openCurrentGroceryList() {
      this.loadingCurrentGroceryList = true
      const list = useRepo(GroceryList).orderBy(gl => gl.created.getTime(), 'desc').limit(1).get()
      if (list.length === 1) {
        const lid = list[0].id
        await useRepo(GroceryRepo).$fetchByList(lid)
      }
      this.loadingCurrentGroceryList = false
    },
    async fetchMeals() {
      if (!this.isOffline) {
        this.loading = true
        const meals = await useRepo(MealRepo).$fetch(this.firstDay)
        if (meals && meals.length > 0) {
          await Promise.all([
            // Fetch composition only for loaded meals
            useRepo(CompositionRepo).$fetch(meals),
            // Fetch recipes only for loaded meals
            useRepo(RecipeMealRepo).$fetch(meals),
            // Load recipes used that week
            useRepo(RecipeRepo).$fetchForWeek(this.firstDay)
          ])
        }
        this.loading = false
      }
    },
    dayId(date) {
      if (moment.utc().isSame(date, 'day')) {
        return 'today'
      }

      return 'day_' + moment.utc(date).format('X')
    },
    selectDay(date) {
      this.selectedDay = date
      this.firstDay = moment.utc(this.selectedDay).startOf('week').toDate()
      localStorage.setItem('calendar-start', moment.utc(this.selectedDay).toISOString())
    },
    previousDay() {
      const curr = moment.utc(this.selectedDay)
      const newDay = moment(curr).subtract(1, 'days')
      this.selectDay(newDay.toDate())
      if (!newDay.isSame(curr, 'week')) {
        this.fetchMeals()
      }
    },
    nextDay() {
      const curr = moment.utc(this.selectedDay)
      const newDay = moment(curr).add(1, 'days')
      this.selectDay(newDay.toDate())
      if (!newDay.isSame(curr, 'week')) {
        this.fetchMeals()
      }
    },
    previousWeek() {
      this.selectDay(moment.utc(this.selectedDay).subtract(7, 'days').endOf('week').toDate())
      this.fetchMeals()
    },
    nextWeek() {
      this.selectDay(moment.utc(this.selectedDay).add(7, 'days').startOf('week').toDate())
      this.fetchMeals()
    },
    today() {
      const fetch = !moment.utc().isSame(this.selectedDay, 'week')
      this.selectDay(moment.utc().toDate())
      if (fetch) {
        this.fetchMeals()
      }
    },
    createMeal(type, date) {
      this.editedMeal = {
        ...Meal.defaults(),
        type: type,
        date: date
      }
      this.editMealDialog = true
    },
    async editMeal(meal, type, date) {
      this.loadingMealId = meal.id
      await useRepo(MealRepo).$loadRecipes(meal.id)
      const clone = _.cloneDeep(useRepo(Meal).withAllRecursive().find(meal.id))
      this.editedMeal = {
        type: type,
        date: date,
        ...clone
      }
      this.editMealDialog = true
      this.loadingMealId = 0
    },
    closeEditMeal() {
      this.editMealDialog = false
      if (this.editedMeal) {
        this.editedMeal = {}
      }
      this.mealCardDialogBig = false
    },
    deleteMeal(meal) {
      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer ce repas ?',
        validate: async function () {
          await useRepo(MealRepo).$delete(meal.id)
        }
      })
    },
    openGroceryDialog() {
      this.groceryDates = [];
      const mStartDay = moment.utc()
      const startOfWeek = mStartDay.startOf('week').format('Y-MM-DD')
      this.groceryDates.push(startOfWeek)
      const endOfWeek = mStartDay.endOf('week').format('Y-MM-DD')
      this.groceryDates.push(endOfWeek)
      this.groceryDialog = true
    },
    async generateGroceries() {
      if (this.groceryDates.length === 2) {
        this.generateGroceryLoading = true
        if (moment.utc(this.groceryDates[0]).isBefore(this.groceryDates[1])) {
          await useRepo(GroceryListRepo).$newListFromMeals(this.groceryDates[0], this.groceryDates[1], this.createList)
        }
        else {
          await useRepo(GroceryListRepo).$newListFromMeals(this.groceryDates[1], this.groceryDates[0], this.createList)
        }
        this.generateGroceryLoading = false
        this.groceryDialog = false
        this.groceryDates = []
      }
    },
    openMoveSelector(mealId) {
      this.moveMeal = useRepo(Meal).find(mealId)
      this.setMoveFirstDay()
      this.moveDialog = true
    },
    async setMoveFirstDay(daysDelay) {
      this.loadingMove = true
      this.moveFirstDay = daysDelay ? moment.utc(this.moveFirstDay).add(daysDelay, 'days').toDate() : moment.utc(this.moveMeal.date).startOf('week').toDate()
      this.moveFirstDayLabel = moment.utc(this.moveFirstDay).format('DD/MM/YY')
      this.moveLastDaylabel = moment.utc(this.moveFirstDay).add(6, 'days').format('DD/MM/YY')
      await useRepo(MealRepo).$fetch(this.moveFirstDay)
      this.loadingMove = false
    },
    async exchangeMeal(targetMeal, defaultDate, defaultType) {
      this.loadingMove = true

      const sourceId = this.moveMeal.id
      const sourceDate = this.moveMeal.date
      const sourceType = this.moveMeal.type
      const targetId = targetMeal ? targetMeal.id : 0
      const targetDate = targetMeal ? targetMeal.date : defaultDate
      const targetType = targetMeal ? targetMeal.type : defaultType
      if (targetMeal) {
        // This is an exchange
        // But there is a constraint on date+type for unicity so use a bogus type for swap
        await useRepo(MealRepo).$update(targetId, {
          date: moment.utc(sourceDate).toDate(),
          type: 'temporaryForSwap'
        }, false)
      }

      // This is just a displacement
      await useRepo(MealRepo).$update(sourceId, {
        date: moment.utc(targetDate).toDate(),
        type: targetType
      }, false)

      if (targetMeal) {
        // Put back the right type instead of the bogus
        await useRepo(MealRepo).$update(targetId, {
          type: sourceType
        }, false)
      }

      this.moveMeal = null
      this.loadingMove = false
      this.moveDialog = false
    },
    toggleMealCardSize() {
      this.mealCardDialogBig = !this.mealCardDialogBig
    },
    doneScanning() {
      this.scanDialog = false
      this.fetchMeals()
    },
    getMealBakingTypes(meal) {
      if (meal && meal.recipes && meal.recipes.length) {
        const bt = meal.bakingTypes
        if (bt) {
          return ' - ' + bt
        }

        return ''
      }

      return ''
    }
  }
};
</script>

<style lang="scss" scoped>
.daysRow {
  background-color: rgb(var(--v-theme-background));
  box-shadow: 0px 5px 5px 0px rgb(var(--v-theme-background));
}

.weekDay {
  // This to force equal width
  width: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  > * {
    flex-grow: 1;
  }

  .today::after {
    content: '';
    height: 3px;
    display: absolute;
    background: rgb(var(--v-theme-primary));
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: -3px;
  }
}
.weekNavigation {
  display: flex;
  align-items: center;
  justify-content: center;

  .weekTitle {
    font-weight: bold;
    min-width: 140px;
    display: inline-block;
  }
}

.mealType {
  border-bottom: 5px solid rgb(var(--v-theme-secondary));
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(var(--v-theme-primary));
}

.day {
  text-align: center;
  text-transform: capitalize;
}

.mealCard {
  flex-basis: 100%;
}

.moveDialog {
  .mainContainer {
    overflow-x: auto;
    position: relative;
    min-height: 100%;
  }

  .switchWeek {
    position: sticky;
    top: 0;
    left: 0;
  }

  .eighth {
    width: 12.5%;
    min-width: 75px;
  }

  .rowTitle {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .day {
    padding: 0;
    white-space: nowrap;
    font-size: 1.1rem;
  }

  .meal {
    margin-bottom: 5px;

    border-radius: 3px;
    padding: 5px;
    display: block;
    min-height: 4rem;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    line-height: 1rem;

    &.ignored {
      font-style: italic;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 200;
    }

    &.moveTarget {
      background: rgb(var(--v-theme-secondary));
      color: white;
      position: relative;

      &.alt {
        background: rgb(var(--v-theme-primary));
      }

      &.emptyTarget {
        background: rgb(var(--v-theme-background));
        color: rgb(var(--v-theme-secondary));
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 3rem;

        &.alt {
          color: rgb(var(--v-theme-primary));
        }
      }

      .moveTargetIndicator {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 32px;
        background: rgba(255, 255, 255, .3);
        display: none;
        align-items: center;
        justify-content: center;

      }
      &:hover .moveTargetIndicator {
        display: flex;
      }
    }
    &.moveSource {
      cursor: default;
      background: rgb(var(--v-theme-background));
      color: rgb(var(--v-theme-secondary));
      border: solid 2px rgb(var(--v-theme-secondary));
      font-weight: bold;
      position: relative;

      &.alt {
        color: rgb(var(--v-theme-primary));
        border-color: rgb(var(--v-theme-primary));
      }

      .moveSourceIndicator {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 32px;
        background: rgba(255, 255, 255, .3);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.groceryListDetail {
  line-height: 1em;
  margin-left: 32px;
  margin-bottom: 0;
  font-style: italic;
}

.inlineTooltip {
  display: inline-block;
}

.allIngsContainer {
  max-height: 60vh;
  overflow: auto;
}
</style>
// Meal Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'
import { Composition, CompositionRepo } from '@/models/Composition'
import { Recipe, RecipeRepo } from '@/models/Recipe'
import { RecipeMeal, RecipeMealRepo } from '@/models/RecipeMeal'
import { Globalconfig } from '@/models/Globalconfig'
import moment from 'moment'
import axios from 'axios'

class Meal extends Model {

  // This is the name used as module name of the Vuex Store.
  static entity = 'meals'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.string(''),
      date: this.string(''),
      type: this.string(''), // type of meal amongst breakfast, lunch, diner
      nb_people: this.attr(0),
      ignored: this.attr(false),
      comment: this.string(''),
      composition: this.hasMany(Composition, 'meal_id'),
      recipes: this.hasMany(RecipeMeal, 'meal_id'),
    }
  }

  static casts() {
    return {
      id: NumberCast,
      nb_people: NumberCast,
      ignored: BooleanCast
    }
  }

  static defaults() {
    let nb_people = 1
    const cc = useRepo(Globalconfig).find("currentCommunity")
    if (cc) {
      nb_people = cc.value.default_nb_people
    }
    return {
      id: 0,
      name: '',
      date: moment().today,
      type: 'lunch', // type of meal amongst breakfast, lunch, diner
      nb_people: nb_people,
      ignored: false,
      comment: '',
      composition: [],
      recipes: []
    }
  }

  static allTypes() {
    return [
      { name: 'lunch', label: 'Déjeuner' },
      { name: 'lunchalt', label: 'Déjeuner alternatif' },
      { name: 'diner', label: 'Dîner' },
      { name: 'dineralt', label: 'Dîner alternatif' }
    ]
  }

  get isAlt() {
    return ['lunchalt', 'dineralt'].indexOf(this.type) > -1
  }

  get shortDate() {
    return moment.utc(this.date).format('ddd DD/MM')
  }

  get bakingTypes() {
    if (this && this.recipes && this.recipes.length) {
      const recipesId = this.recipes.reduce((prev, curr) => prev.concat([curr.recipe_id]), [])
      const recipes = useRepo(Recipe).find(recipesId)
      if (recipes && recipes.length) {
        const bt = Recipe.bakingTypes()
        const types = []
        recipes.map((r) => {
          if (r.baking_type) {
            types.push(bt.filter((v) => v.value === r.baking_type)[0].text)
          }
        })
        if (types.length) {
          return types.join(', ')
        }

        return ''
      }

      return ''
    }

    return ''
  }
}

class MealRepo extends Repository {
  use = Meal

  async $fetch(firstDay) {
    let data = {}
    if(firstDay) {
      data.firstDay = firstDay
    }
    return new Promise(async (resolve, reject) => {
      try {
        const meals = await axios.get('/meals/get.php', {
          params: data
        })
        resolve(this.save(meals.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchForGrocery(listId) {
    let uri = '/meals/get.php?filter=hasGrocery'
    if (listId) {
      uri += '&listId=' + encodeURIComponent(listId)
    }
    return new Promise(async (resolve, reject) => {
      try {
        const meals = await axios.get(uri)
        resolve(this.save(meals.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const meal = await axios.get('/meals/get.php?id=' + id)
        resolve(this.save(meal.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getByDayOfWeek(day, type = '') {
    return new Promise(async (resolve, reject) => {
      try {
        const meals = await axios.get('/meals/get.php?filter=byDayOfWeek&dayOfWeek=' + encodeURIComponent(day) + '&type=' + encodeURIComponent(type))
        resolve(meals)
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    let filteredData = data
    if (data.recipes) {
      data.recipes.map(i => {
        delete i.recipe
      })
    }
    return new Promise(async (resolve, reject) => {
      try {
        const meal = await axios.post('/meals/post.php', filteredData)
        resolve(this.save(meal.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data, resetRecipes = true) {
    let filteredData = data
    if (resetRecipes) {
      if (data.recipes) {
        data.recipes.map(i => {
          delete i.recipe
        })
      }
      // Delete linked between recipe and ingredients since they will be re-created
      useRepo(RecipeMeal).where('meal_id', id).delete()
    }
    else if (data.recipes) {
      data.recipes.map(i => {
        delete i.recipe
      })
    }
    return new Promise(async (resolve, reject) => {
      try {
        const meal = await axios.put('/meals/put.php?id=' + id, filteredData)
        resolve(this.save(meal.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/meals/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $bulkDelete(ids) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post('/meals/bulkDelete/post.php', { ids: ids })
        resolve(this.where(m => ids.indexOf(m.id)>-1).delete())
      }
      catch (e) {
        reject()
      }
    })
  }

  async $loadRecipes(id) {
    return new Promise(async function (resolve, reject) {
      try {
        await useRepo(RecipeRepo).$getByMeal(id)
        await useRepo(RecipeMealRepo).$getByMeal(id)
        resolve()
      }
      catch (e) {
        reject()
      }
    })
  }

  async $updateIngredients(id) {
    await axios.post('/meals/ingredients/post.php?id=' + id, {})
    // Deleting previous composition for this meal
    useRepo(Composition).where('meal_id', id).delete()
    // Getting all new compositions
    await useRepo(CompositionRepo).$fetch([{ id: id }])
  }
}

export {
  Meal,
  MealRepo
}
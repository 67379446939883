<template>
  <Popper
    popper-class="tooltipv2 elevation-12"
    :arrow-class="'arrow-'+dynamicPlacement"
    :show="show"
    arrow
    :class="customClass"
    @popper="onMount"
  >
    <slot />
    <template #content>
      <slot name="content" />
    </template>
  </Popper>
</template>

<script>
import _ from 'lodash';
import Popper from 'vue3-popper';

export default {
  name: "TooltipPopper",
  components: {
    Popper,
  },
  props: {
    placement: {
      type: String,
      default: 'bottom'
    },
    show: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    boundaryId: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    popperInstance: null,
    dynamicPlacement: '',
    options: {
      modifiers: []
    }
  }),
  computed: {
  },
  watch: {
    show: async function(val) {
      if(val) {
        if(this.popperInstance) {
          // Enable the event listeners
          this.popperInstance.setOptions({
            modifiers: [{ name: 'eventListeners', enabled: true }],
          });

          // Update its position
          await this.popperInstance.update()
          this.dynamicPlacement = this.popperInstance.state.placement
        }
      }
      else {
        if(this.popperInstance) {
          // Disable the event listeners
          this.popperInstance.setOptions({
            modifiers: [{ name: 'eventListeners', enabled: false }],
          });
        }
      }
    }
  },
  created () {
    this.dynamicPlacement = this.placement
  },
  mounted() {
    if (this.boundaryId) {
      const boundary = document.getElementById(this.boundaryId) ? document.body : null;
      if(boundary) {
        const boundaryModifier = {
          name: 'preventOverflow',
          options: {
            boundary,
            rootBoundary: 'document',
          },
        };
        this.options.modifiers.push(boundaryModifier);
      }
    }
  },
  methods: {
    onMount(popperInstance) {
      this.popperInstance = popperInstance
      this.dynamicPlacement = this.popperInstance.state.placement

      if(this.parentId) {
        const content = this.popperInstance.state.elements.popper;
        const parent = document.getElementById(this.parentId);
        if(parent) {
          content.parentNode.removeChild(content);
          parent.appendChild(content);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <v-card
    elevation="3"
    class="fill-height"
    @click="click"
  >
    <v-card-text class="pa-2">
      <v-row align="center">
        <v-col cols="auto" class="pr-1">
          <v-img
            v-if="ingredient.image_url"
            :src="ingredient.image_url"
            height="100"
            width="100"
          />
          <div
            v-if="!ingredient.image_url"
            class="noIngredientImage"
          />
        </v-col>
        <v-col class="pl-1">
          <v-row
            dense
            class="flex-nowrap"
          >
            <v-col
              class="text-center text-h6 text-primary"
            >
              {{ ingredient.name }}
              <VDropdown
                :triggers="['hover']"
                :hide-triggers="triggers => [...triggers, 'click']"
                :auto-hide="true"
                :hide-on-target-click="false"
                :dispose-timeout="100"
                class="aliases"
              >
                <template #popper>
                  <v-container class="pa-2">
                    <div v-for="a in ingredient.aliases" :key="a" class="alias">
                      {{ a }}
                    </div>
                    <div v-if="ingredient.aliases.length === 0" class="noAlias">
                      Aucun alias défini
                    </div>
                  </v-container>
                </template>
                <v-btn
                  icon
                  variant="flat"
                  size="small"
                >
                  <v-icon :color="ingredient.aliases.length === 0 ? 'grey-lighten-1' : 'secondary'" size="x-large">
                    mdi-book-alphabet
                  </v-icon>
                </v-btn>
              </VDropdown>
            </v-col>
          </v-row>
          <v-row
            dense
            class="middleRow"
            align-content="center"
          >
            <v-col
              v-if="ingredient.need_bag"
              cols="auto"
              class="px-2"
            >
              <v-btn icon size="x-small" variant="outlined">
                <v-icon
                  v-tooltip="{ content: 'Besoin d\'un sac pour les courses' }"
                  color="primary"
                  size="medium"
                >
                  mdi-shopping
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="ingredient.need_box"
              cols="auto"
              class="px-2"
            >
              <v-btn icon size="x-small" variant="outlined">
                <v-icon
                  v-tooltip="{ content: 'Besoin d\'une boîte pour les courses' }"
                  color="primary"
                  size="medium"
                >
                  mdi-basket-fill
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center" align-self="center">
              Par défaut : {{ formattedQuantity }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="hasSeasons" no-gutters class="text-center mt-2">
        <v-col v-for="m in months" :key="m.nb" cols="1">
          <div class="month">
            {{ m.shortText }}
          </div>
          <v-icon
            :color="ingredient.season_months.indexOf(m.nb+1) > -1 ? 'secondary' : 'grey-lighten-1'"
            :size="ingredient.season_months.indexOf(m.nb+1) === -1 ? 'medium' : 'small'"
          >
            {{ ingredient.season_months.indexOf(m.nb+1) > -1 ? 'mdi-check-circle-outline' : 'mdi-timer-sand' }}
          </v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Ingredient } from "@/models/Ingredient"
import moment from 'moment'

export default {
  name: "IngredientCard",
  props: {
    ingredient: {
      type: Object,
      default: null
    },
  },
  emits: ['clickedCard'],
  data: () => ({
  }),
  computed: {
    months () {
      const current = moment().month()
      const shorts = moment.monthsShort()
      const all = moment.months().map((m, i) => {
        return {
          nb: i,
          text: m,
          mediumText: shorts[i],
          shortText: m[0],
          current: i === current
        }
      })
      return all
    },
    hasSeasons () {
      return Ingredient.seasonTypes().indexOf(this.ingredient.type) > -1
    },
    formattedQuantity () {
      return Ingredient.formatQuantity(this.ingredient.default_amount, this.ingredient.default_unit_shorthand)
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    click () {
      this.$emit('clickedCard')
    }
  }
}
</script>

<style lang="scss" scoped>
.aliases {
  display: inline-block;
}
.alias {
  text-transform: uppercase;
  text-align: center;
}
.noAlias {
  font-style: italic;
}
.middleRow {
  height: 48px;
}
.month {
  text-align: center;
  text-transform: uppercase;
}
.noIngredientImage {
  height: 100%;
  min-height: 100px;
  width: 100px;
  padding-top: 100%;
  position: relative;

  &:after {
    content: "";
    border: 2px dashed rgb(var(--v-theme-background-darken-1));
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
<template>
  <v-container class="pa-0">
    <v-btn
      v-for="i in values"
      :key="i"
      icon
      class="starBtn"
      variant="flat"
      @click="click(i)"
      @mouseover="mouseover(i)"
      @mouseleave="mouseleave(i)"
    >
      <v-icon
        v-if="hover"
        color="secondary"
      >
        {{ tmpValue > i ? 'mdi-star' : 'mdi-star-outline' }}
      </v-icon>
      <v-icon
        v-if="!hover"
        color="primary"
      >
        {{ value > i ? 'mdi-star' : 'mdi-star-outline' }}
      </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'StarVote',
  components: {
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 4
    }
  },
  emits: ['clickedStar'],
  data: () => ({
    hover: false,
    in: false,
    tmpValue: -1
  }),
  computed: {
    values () {
      return new Array(this.max - this.min + 1).fill(0).map((v, i) => i)
    }
  },
  created () {
  },
  methods: {
    mouseover (i) {
      this.hover = true
      this.in = true
      this.tmpValue = i+1
    },
    mouseleave (i) {
      this.in = false
      _.delay(() => {
        if(!this.in) {
          this.hover = false
          this.tmpValue = -1
        }
      }, 50)
    },
    click (index) {
      // Force a reset of the hovering first
      this.hover = false
      this.in = false
      this.$emit('clickedStar', this.min + index)
    }
  }
}
</script>

<style lang="scss" scoped>
.starBtn {
  border-radius: 0;
}
</style>
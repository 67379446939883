<template>
  <v-card elevation="0" :loading="saving" :disabled="saving">
    <v-card-title class="bg-primary text-white d-flex align-center">
      {{ timeLabel }}

      <v-spacer v-if="$vuetify.display.xs" />
      <span v-if="$vuetify.display.xs">
        <v-btn
          icon
          title="Annuler"
          variant="text"
          size="small"
          @click="close"
        >
          <v-icon size="large">
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          icon
          title="Enregistrer"
          variant="text"
          size="small"
          @click="saveMeal"
        >
          <v-icon size="large">
            mdi-check-circle
          </v-icon>
        </v-btn>
      </span>
    </v-card-title>

    <v-card-text ref="mealCardContent" class="mealEditionCard pb-3">
      <v-row>
        <v-col cols="12" :md="showRecipeSearch || showIdeas ? 6 : 12" class="pa-2">
          <v-row v-if="!localMeal.ignored" class="my-0" align="center">
            <v-col class="pb-0">
              <v-textarea
                ref="name"
                v-model="localMeal.name"
                label="Plat"
                hide-details
                auto-grow
                rows="1"
                variant="outlined"
                class="name"
              />
            </v-col>
          </v-row>
          <v-row v-if="!localMeal.ignored" dense align="center">
            <v-col cols="4" sm="auto" class="text-caption">
              Pas d'idée ?
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                size="x-small"
                :variant="showRecipeSearch ? 'flat' : 'outlined'"
                title="Chercher dans les recettes"
                class="ml-2"
                @click="toggleRecipeSearch"
              >
                <v-icon class="mr-2">
                  mdi-pasta
                </v-icon>
                Voir les recettes
              </v-btn>
            </v-col>
            <v-col cols="auto" offset="4" offset-sm="0">
              <v-btn
                color="primary"
                size="x-small"
                :variant="showIdeas ? 'flat' : 'outlined'"
                title="On a fait quoi avant ?"
                class="ml-2"
                @click="toggleIdeas"
              >
                <v-icon class="mr-2">
                  mdi-lightbulb-on
                </v-icon>
                Voir des idées
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="localMeal.recipes && localMeal.recipes.length > 0"
            dense
            class="mt-4"
          >
            <v-col cols="auto" align-self="start" class="pt-2">
              Recettes :
            </v-col>
            <v-col grow>
              <v-chip
                v-for="recipe in localMeal.recipes"
                :key="recipe.id"
                closable
                color="lowkey"
                class="mr-2 mb-2"
                variant="elevated"
                size="small"
                @click:close="removeRecipe(recipe)"
              >
                {{ recipe.recipe ? recipe.recipe.name : '...' }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!localMeal.ignored" cols="auto">
              Nombre de personnes :
              <int-stepper v-model="localMeal.nb_people" :min="1" />
            </v-col>
          </v-row>
          <v-row
            class="mt-4 mb-0"
            dense
          >
            <v-col>
              <v-btn variant="text" size="small" title="Ajouter un commentaire" class="pa-0" @click="toggleComment">
                <v-icon v-if="!(localMeal.ignored || localMeal.comment || displayComment)" class="mr-2">
                  mdi-checkbox-blank-outline
                </v-icon>
                <v-icon v-if="localMeal.ignored || localMeal.comment || displayComment" class="mr-2">
                  mdi-checkbox-marked-outline
                </v-icon>
                Ajouter un commentaire
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="localMeal.ignored || localMeal.comment || displayComment" dense class="my-0">
            <v-col cols="12">
              <v-textarea
                ref="comment"
                v-model="localMeal.comment"
                variant="outlined"
                rows="2"
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showRecipeSearch" cols="12" md="6" class="pa-2">
          <v-divider v-if="$vuetify.display.xs" />
          <v-row class="my-0">
            <v-col>
              <v-text-field
                v-if="searchby==='recipe'"
                ref="searchRecipeInput"
                v-model="searchRecipe"
                variant="outlined"
                density="compact"
                hide-details
                clearable
                label="Recherche"
                placeholder="Cherchez une recette..."
                :loading="searchLoading"
                @update:model-value="triggerSearchRecipe"
              />
              <v-autocomplete
                v-if="searchby=='ingredient'"
                ref="searchIngredientInput"
                v-model="selectedRecipeIngredient"
                v-model:search="searchRecipeIngredient"
                :loading="searchLoading"
                :items="searchIngredientsResults"
                item-value="id"
                item-title="name"
                density="compact"
                hide-details
                label="Cherchez un ingrédient..."
                variant="outlined"
                single-line
                clearable
                return-object
                no-data-text="Aucun ingrédient trouvé"
                no-filter
                :readonly="!!selectedRecipeIngredient"
                @update:model-value="triggerSearchRecipeByIngredient"
              />
            </v-col>
            <v-col cols="auto">
              <VDropdown
                :container="$refs.mealCardContent.$el"
              >
                <v-btn
                  icon
                  variant="outlined"
                  size="small"
                  color="primary"
                  :loading="searchLoading"
                >
                  <v-icon>
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
                <template #popper>
                  <v-container>
                    <v-btn variant="text" @click="searchby='recipe'">
                      <v-icon class="mr-1">
                        {{ searchby === 'recipe' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      Par recette
                    </v-btn>
                    <v-btn variant="text" @click="searchby='ingredient'">
                      <v-icon class="mr-1">
                        {{ searchby === 'ingredient' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      Par ingredient
                    </v-btn>
                    <div>
                      <v-select
                        v-if="searchby === 'recipe'"
                        v-model="searchDishType"
                        :menu-props="{ attach: true }"
                        :items="dishTypes"
                        item-title="text"
                        item-value="value"
                        label="Type de plat"
                        clearable
                        variant="outlined"
                        multiple
                        hide-details
                        density="compact"
                        class="mt-2"
                      />
                    </div>
                  </v-container>
                </template>
              </VDropdown>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col v-if="searchRecipeResults.length > 0" class="searchResults scrollbars">
              <recipe-card
                v-for="recipe in searchRecipeResults"
                :key="recipe.id"
                :recipe-id="parseInt(recipe.id)"
                class="mb-2"
                @clicked-card="addRecipe(recipe)"
              />
            </v-col>
            <v-col v-if="searchRecipeByIngredientsResults.length > 0" class="searchResults scrollbars">
              <recipe-card
                v-for="recipe in searchRecipeByIngredientsResults"
                :key="recipe.id"
                :recipe-id="parseInt(recipe.id)"
                class="mb-2"
                @clicked-card="addRecipe(recipe)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showIdeas" cols="12" md="6">
          <v-divider v-if="$vuetify.display.xs" />
          <v-tabs
            v-model="ideasTab"
            color="primary"
          >
            <v-tab value="ideas">
              Dans le passé
            </v-tab>
            <v-tab value="batch">
              Batch cooking
            </v-tab>
          </v-tabs>

          <v-window v-model="ideasTab">
            <v-window-item value="ideas">
              <template v-if="ideasLoading">
                <p class="text-center text-h5 loadingText">
                  Chargement
                </p>
              </template>
              <template v-else>
                <template v-if="ideas && ideas.length > 0">
                  <p class="sticky text-h5 text--primary my-3">
                    Le {{ mealDay }} c'est :
                  </p>
                  <div class="ideasContainer scrollbars">
                    <div v-for="idea in ideas" :key="idea.id" class="d-flex align-start pb-2">
                      <v-btn
                        size="x-small"
                        variant="outlined"
                        class="mr-2"
                        @click="localMeal.name = idea.name"
                      >
                        On fait ça !
                      </v-btn>
                      <span>
                        {{ idea.name }}
                      </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <p>
                    Rien à reprendre des semaines précédentes...
                  </p>
                </template>
              </template>
            </v-window-item>
            <v-window-item value="batch">
              <template v-if="batchIngs && batchIngs.length > 0">
                <p class="sticky text-h5 text--primary my-3">
                  Déjà utilisé cette semaine :
                </p>
                <div class="ideasContainer scrollbars">
                  <div v-for="section in batchIngs" :key="section.value">
                    <p v-if="section.ingredients && section.ingredients.length > 0" class="text-h6 text-primary">
                      {{ section.text }}
                    </p>
                    <div v-for="ingredient in section.ingredients" :key="ingredient.id" class="d-flex align-start pb-2">
                      <span :class="!ingredient.image_url ? 'ml-6 pl-2 d-flex align-center' : 'd-flex align-center'">
                        <v-img
                          v-if="ingredient.image_url"
                          :src="ingredient.image_url"
                          height="24"
                          width="24"
                          class="ma-0 mr-2"
                        />
                        {{ ingredient.name }}
                        <VDropdown
                          class="inlineTooltip ml-2"
                          auto-hide
                        >
                          <v-chip
                            pill
                            title="Repas"
                            size="small"
                          >
                            <v-avatar start color="secondary">
                              <v-icon>
                                mdi-silverware-fork-knife
                              </v-icon>
                            </v-avatar>
                            {{ ingredient.composition.length }}
                          </v-chip>
                          <template #popper>
                            <div class="pa-2">
                              <div v-for="compo in ingredient.composition" :key="compo.id" class="upperFirstLetter">
                                {{ compo.meal.name }} ({{ compo.meal.shortDate + getMealBakingTypes(compo.meal) }})
                              </div>
                            </div>
                          </template>
                        </VDropdown>
                        <v-btn
                          variant="outlined"
                          density="comfortable"
                          class="ml-4"
                          prepend-icon="mdi-magnify"
                          size="small"
                          @click="toggleRecipeSearch({filter: 'ingredient', filterValue: ingredient.id})"
                        >
                          Recettes
                        </v-btn>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <p>
                  Aucun ingrédient utilisé cette semaine...
                </p>
              </template>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="$vuetify.display.smAndUp" class="elevation-4">
      <div class="flex-grow-1" />
      <v-btn color="primary" variant="text" @click="close">
        Annuler
      </v-btn>
      <v-btn color="primary" variant="text" @click="saveMeal">
        {{ !meal || !localMeal.id ? 'Créer' : 'Enregistrer' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import { useRepo } from "pinia-orm"
import { Meal, MealRepo } from "@/models/Meal"
import { Ingredient, IngredientRepo } from "@/models/Ingredient"
import { Recipe, RecipeRepo } from "@/models/Recipe"
import RecipeCard from "@/components/RecipeCard"
import IntStepper from '@/components/IntStepper.vue'
import { useToast } from 'vue-toastification'

export default {
  name: "MealCard",
  components: {
    RecipeCard,
    IntStepper
  },
  props: {
    meal: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ""
    },
    date: {
      type: Date,
      default: null
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'toggleSize'],
  data: () => ({
    localMeal: {},

    displayComment: false,
    saving: false,
    showSaving: false,
    searchingIngredients: false,

    showRecipeSearch: false,
    searchby: 'recipe',
    searchBySwitch: false,
    searchDishType: null,
    allowCloseSearchBy: false,
    searchLoading: false,
    selectedRecipe: null,

    searchRecipe: null,
    searchRecipeResults: [],

    selectedRecipeIngredient: null,
    searchIngredientsResults: [],
    searchRecipeIngredient: null,
    searchRecipeByIngredientsResults: [],

    showIdeas: false,
    ideasTab: 'ideas',
    ideasLoading: false,
    ideas: []
  }),
  computed: {
    timeLabel() {
      const type = this.localMeal.type || this.type
      if(!type) return ""; // for saving since there is a re-render with empty meal

      const currType = Meal.allTypes().filter(t => t.name === type)[0]
      return currType.label + ' du ' + _.capitalize(moment(this.localMeal.date).format('dddd D'))
    },
    mealDay() {
      return moment(this.localMeal.date).format('dddd')
    },
    dishTypes() {
      return Recipe.dishTypes()
    },
    batchIngs() {
      const ings = useRepo(Ingredient)
        .withAllRecursive()
        .whereHas('composition', (compoQuery) => {
          compoQuery.whereHas('meal', (mealQuery) => {
            mealQuery.where((meal) => moment(meal.date).isSame(this.localMeal.date, 'week'))
          })
        })
        .where('type', (t) => t !== 'spice' && t !== 'drink')
        .get()

      ings.map(i => {
        i.composition.sort((a, b) => {
          return moment(a.meal.date).isAfter(b.meal.date) ? 1 : -1
        })
        return i
      })

      // Sorting by type of gorcery
      const sections = Ingredient.allTypes()
      const found = {}
      sections.map(t => {
        t.ingredients = ings.filter(i => {
          if (i.type === t.value) {
            found[i.id] = true
            return true
          }
        })
      })
      // Getting all groceries with ingredient with no type
      const otherIngs = ings.filter(i => {
        return !found[i.id]
      })
      sections.push({
        text: "Autre",
        pluralText: "Autres",
        value: "_",
        ingredients: otherIngs
      })

      return sections
    }
  },
  watch: {
    searchRecipeIngredient: _.debounce(async function (val) {
      if(!this.selectedRecipeIngredient && val) {
        this.searchLoading = true
        try {
          const results = await useRepo(IngredientRepo).$search(val)
          this.searchIngredientsResults = results.data.matches
        }
        catch(e) {
          const toast = useToast()
          toast('Impossible de chercher parmis les ingrédients pour le moment.', {
            type: 'error'
          })
        }
        this.searchLoading = false
      }
    }, 500)
  },
  created() {
    this.localMeal = this.meal
  },
  mounted() {
    if(this.focus) {
      const that = this
      if(this.localMeal.id && this.localMeal.ignored) {
        setTimeout(() => that.$refs.comment && that.$refs.comment.focus(), 100)
      }
      else {
        setTimeout(() => that.$refs.name && that.$refs.name.focus(), 100)
      }
    }
  },
  methods: {
    toggleComment() {
      this.displayComment = !this.displayComment
      if(this.displayComment) {
        setTimeout(() => this.$refs.comment.focus(), 100)
      }
    },
    saveMeal: async function(mealData) {
      this.saving = true
      let response
      if(this.localMeal.id) {
        response = await useRepo(MealRepo).$update(this.localMeal.id, this.localMeal)
      }
      else {
        response = await useRepo(MealRepo).$create(this.localMeal)
      }
      const newId = response.id
      await useRepo(MealRepo).$loadRecipes(newId)
      await this.updateIngredients(newId)
      this.saving = false
      this.$emit('close')
    },
    close() {
      this.$emit('close')
    },
    updateIngredients: async function(mealId) {
      this.searchingIngredients = true
      const meal = useRepo(Meal).find(mealId)
      if(meal) {
        await useRepo(MealRepo).$updateIngredients(meal.id)
        this.searchingIngredients = false
      }
      else {
        //console.error('No meal found for mealId '+mealId)
      }
    },
    toggleRecipeSearch (presets) {
      this.showRecipeSearch = !this.showRecipeSearch
      if(!(this.showIdeas && this.showRecipeSearch)) this.$emit('toggleSize')
      if(this.showRecipeSearch) {
        this.showIdeas = false
        setTimeout(()=>{
          if(presets && presets.filter === 'ingredient' && presets.filterValue) {
            this.selectedRecipeIngredient = useRepo(Ingredient).find(presets.filterValue)
            this.triggerSearchRecipeByIngredient()
          }
          else {
            this.$refs.searchRecipeInput.focus()
          }
        }, 100)
      }
      else {
        // reset
        this.searchby = 'recipe'
        this.searchRecipe = null
        this.searchRecipeResults = []

        this.selectedRecipeIngredient = null
        this.searchIngredientsResults = []
        this.searchRecipeIngredient = null
        this.searchRecipeByIngredientsResults = []
      }
    },
    triggerSearchRecipe: _.debounce(async function (value) {
      if (value) {
        this.searchLoading = true
        useRepo(RecipeRepo).$search(value,
                       'recipe',
                       this.searchDishType ? {'dish_type': this.searchDishType} : null)
          .then(res => {
            // Use entities cleanup by store
            this.searchRecipeResults = res.data || []
          })
          .catch(err => {
            const toast = useToast()
            toast('Impossible de chercher parmis les recettes pour le moment.', {
              type: 'error'
            })
          })
          .finally(() => {
            this.searchLoading = false
          })
      }
    }, 500),
    triggerSearchRecipeByIngredient: _.debounce(async function () {
      if(this.selectedRecipeIngredient) {
        this.searchLoading = true
        this.searchRecipeIngredient = ''
        try {
          const res = await useRepo(RecipeRepo).$search(this.selectedRecipeIngredient.id, 'ingredient')

          // Use entities cleanup by store
          this.searchRecipeByIngredientsResults = res.data || []
        }
        catch(err) {
          const toast = useToast()
          toast('Impossible de chercher parmis les recettes pour le moment.', {
            type: 'error'
          })
        }

        this.searchLoading = false

      }
    }, 500),
    addRecipe (recipe) {
      if(recipe) {
        const existing = this.localMeal.recipes.filter(r => r.id === recipe.id).length > 0
        if(!existing) {
          if(this.localMeal.recipes.length === 0) {
            this.localMeal.nb_people = parseInt(recipe.nb_people)
          }
          this.localMeal.recipes.push({
            recipe_id: recipe.id,
            recipe: recipe
          })

          if(this.localMeal.name === '') {
            this.localMeal.name = recipe.name
          }
        }
      }
    },
    removeRecipe (recipe) {
      if(recipe) {
        const recipes = this.localMeal.recipes.filter(r => r.recipe_id !== recipe.recipe_id)
        this.localMeal.recipes = recipes
      }
    },
    async toggleIdeas () {
      this.showIdeas = !this.showIdeas
      if(!(this.showIdeas && this.showRecipeSearch)) this.$emit('toggleSize')
      if(this.showIdeas) {
        this.showRecipeSearch = false
        if(this.ideas.length === 0) {
          this.ideasLoading = true
          useRepo(MealRepo).$getByDayOfWeek(parseInt(moment(this.localMeal.date).format('d')) + 1, '')
            .then(response => {
              this.ideas = response.data
              this.ideasLoading = false
            })
        }
      }
    },
    getMealBakingTypes(meal) {
      if(meal && meal.recipes && meal.recipes.length) {
        const bt = meal.bakingTypes
        if(bt) {
          return ' - ' + bt
        }

        return ''
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.mealEditionCard {
  .nbPeople {
    input {
      text-align: center;
    }
  }
}
.searchResults {
  max-height: 300px;
  overflow: auto;
}
.name :deep(textarea) {
  max-height: 150px;
  overflow-y: auto;
  margin: 5px 0;
}

.ideasContainer {
  max-height: 300px;
  overflow: auto;
}

.inlineTooltip {
  display: inline-block;
}
</style>
<template>
  <ingredient-list />
</template>

<script>
// @ is an alias to /src
import IngredientList from '@/components/IngredientList'

export default {
  name: 'IngredientsView',
  components: {
    IngredientList
  }
}
</script>
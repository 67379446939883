// RecipeIngredient Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import { Recipe } from './Recipe'
import { Ingredient } from './Ingredient'
import axios from 'axios'

class RecipeIngredient extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'recipeIngredients'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      amount: this.attr(0),
      unit: this.string(''),
      ingredient_id: this.attr(0),
      ingredient: this.belongsTo(Ingredient, 'ingredient_id'),
      recipe_id: this.attr(0),
      recipe: this.belongsTo(Recipe, 'recipe_id'),
      original: this.string('')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      amount: NumberCast,
      ingredient_id: NumberCast,
      recipe_id: NumberCast
    }
  }

  get unit_shorthand() {
    const units = Ingredient.units()
    if (units && units.length) {
      const match = units.filter((a) => a.value === this.unit)
      if (match.length === 1) {
        return match[0].shorthand
      }
    }

    return this.unit
  }
}

class RecipeIngredientRepo extends Repository {
  use = RecipeIngredient

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const ris = await axios.get('/recipeingredient/get.php')
        resolve(this.save(ris.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const ri = await axios.get('/recipeingredient/get.php?id=' + id)
        resolve(this.save(ri.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getByRecipe(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const ri = await axios.get('/recipeingredient/get.php?recipe_id=' + id)
        resolve(this.save(ri.data))
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  RecipeIngredient,
  RecipeIngredientRepo
}
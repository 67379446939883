// RecipeMeal Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import { Recipe } from './Recipe'
import { Meal } from './Meal'
import axios from 'axios'

class RecipeMeal extends Model {
  // This is the name used as module name of the Vuex Store.
  static get entity() { return 'recipeMeals' }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      meal_id: this.attr(0),
      meal: this.belongsTo(Meal, 'meal_id'),
      recipe_id: this.attr(0),
      recipe: this.belongsTo(Recipe, 'recipe_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      meal_id: NumberCast,
      recipe_id: NumberCast
    }
  }
}

class RecipeMealRepo extends Repository {
  use = RecipeMeal

  async $fetch(meals) {
    let params = ''
    if (meals) {
      const ids = meals.reduce((prev, curr) => {
        prev.push(curr.id)
        return prev
      }, [])
      params += 'mealIds=' + encodeURIComponent(JSON.stringify(ids))
    }
    return new Promise(async (resolve, reject) => {
      try {
        const rms = await axios.get('/recipemeal/get.php?' + params)
        resolve(this.save(rms.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const rm = await axios.get('/recipemeal/get.php?id=' + id)
        resolve(this.save(rm.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getByMeal(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const rm = await axios.get('/recipemeal/get.php?meal_id=' + id)
        resolve(this.save(rm.data))
      }
      catch (e) {
        reject()
      }
    })
  }

}

export {
  RecipeMeal,
  RecipeMealRepo
}
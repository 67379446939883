<template>
  <v-card
    :class="'groceryCard' + (small ? ' small ' : '') + (item.gathered ? ' gathered ' : '')"
    :loading="loading"
    :elevation="item.gathered ? '0' : '1'"
  >
    <v-card-text class="pa-0">
      <v-row no-gutters class="flex-nowrap">
        <v-col v-if="!(item.gathered && small)" cols="auto" align-self="center" class="ma-2">
          <v-img
            v-if="item.ingredient && item.ingredient.image_url"
            :src="item.ingredient.image_url"
            :height="small ? 50 : 100"
            :width="small ? 50 : 100"
            :class="item.gathered ? 'dimmed' : ''"
          />
          <div
            v-if="!item.ingredient || !item.ingredient.image_url"
            class="noIngredient"
          />
        </v-col>

        <v-col v-if="!(item.gathered && small)" class="middleContainer my-2">
          <v-row no-gutters>
            <v-col>
              <p
                :class="'xsName upperFirstLetter mb-2 '+(item.gathered ? 'dimmed' : '')"
              >
                {{ item.name }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" class="flex-nowrap">
            <v-col v-if="linkedGroceryMeals.length" cols="auto">
              <linked-meal-tooltip :linked-grocery-meals="linkedGroceryMeals" :ingredient-id="item.ingredient.id" />
            </v-col>
            <v-col v-if="small && item.ingredient && (item.ingredient.need_bag || item.ingredient.need_box)" cols="auto" class="ml-1">
              <v-icon class="bagBoxMarker" size="small" color="primary" :title="item.ingredient.need_bag ? 'Besoin d\'un sac' : 'Besoin d\'une boîte'">
                {{ item.ingredient.need_bag ? 'mdi-shopping' : item.ingredient.need_box ? 'mdi-basket-fill' : '' }}
              </v-icon>
            </v-col>

            <v-spacer />

            <v-col cols="auto" align-self="center">
              <v-btn
                icon
                size="x-small"
                variant="text"
                color="grey-darken-1"
                @click="editGrocery"
              >
                <v-icon :size="24">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                variant="text"
                size="x-small"
                color="grey-darken-1"
                @click="deleteGrocery"
              >
                <v-icon :size="24">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!(item.gathered && small)" cols="auto">
          <span class="separator mr-0">&nbsp;</span>
        </v-col>

        <v-col v-if="!(item.gathered && small)" cols="auto" align-self="center">
          <div class="quantity">
            <v-text-field
              v-model="amount"
              name="amount"
              variant="outlined"
              hide-details
              density="compact"
              :suffix="item.unit === 'nb' ? '' : item.unit"
              :disabled="isOffline || item.gathered"
              @update:model-value="updateAmount"
            />
          </div>
        </v-col>

        <v-col v-if="item.gathered && small">
          <v-row dense align="center" class="mx-0">
            <v-col cols="6">
              {{ grocerySummary(item.id) }}
            </v-col>
            <v-spacer />
            <v-col v-if="linkedGroceryMeals.length" cols="auto">
              <linked-meal-tooltip :linked-grocery-meals="linkedGroceryMeals" :ingredient-id="item.ingredient.id" />
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                variant="text"
                size="small"
                color="grey-darken-1"
                @click="deleteGrocery"
              >
                <v-icon size="x-large">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="auto"
          align-self="stretch"
          :class="item.gathered && !small ? 'checkContainer outlined' : 'checkContainer'"
        >
          <v-btn
            variant="text"
            :color="item.gathered && !small ? 'rgb(var(--v-theme-background-darken-2))' : 'white'"
            class="gatherBtn"
            :ripple="false"
            :disabled="isOffline"
            @click="gather(!item.gathered)"
          >
            <v-icon size="x-large">
              {{ item.gathered ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash"
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Grocery, GroceryRepo } from "@/models/Grocery"
import { GroceryMeal } from "@/models/GroceryMeal"
import { Ingredient } from "@/models/Ingredient"
import LinkedMealTooltip from '@/components/LinkedMealTooltip'
import emitter from 'tiny-emitter/instance'
import moment from 'moment'

export default {
  name: "GroceryCard",
  components: {
    LinkedMealTooltip
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['edit'],
  data: () => ({
    loading: false,
    linkedGroceryMeals: [],
    amount: 0
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    ingredientUnits() {
      return Ingredient.units()
    },
    small() {
      return this.$vuetify.display.xs
    }
  },
  watch: {
    item: {
      handler(newval, oldVal) {
        this.refreshItem()
      },
      deep: true
    }
  },
  mounted () {
    this.refreshItem()
  },
  methods: {
    refreshItem() {
      if (this.item.ingredient) {
        this.linkedGroceryMeals = useRepo(GroceryMeal)
          .where("grocery_id", this.item.id)
          .with("meal")
          .get()
        this.linkedGroceryMeals.sort((a, b) => {
            return moment(a.meal.date).isAfter(b.meal.date) ? 1 : -1
          })
      }
      this.amount = this.item.amount
    },
    updateAmount: _.debounce(async function(newValue) {
      if(!isNaN(newValue) && !isNaN(parseFloat(newValue))) {
        this.loading = true
        // DO NOT USE ARROW FUNCTION HERE OR "this" GETS LOST
        await useRepo(GroceryRepo).$update(this.item.id, {
          ...this.item,
          amount: newValue
        })
        this.loading = false
      }
      else {
        this.amount = this.item.amount
      }
    }, 1000),
    gather: async function(value) {
      useRepo(Grocery).save({
        id: this.item.id,
        gathered: value
      })
      const data = {
        ...useRepo(Grocery).find(this.item.id)
      }
      // Async update to make UI faster
      useRepo(GroceryRepo).$update(data.id, data)
    },
    deleteGrocery() {
      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer l\'élément "'+this.item.name+'" ?',
        validate: async () => {
          this.loading = true
          await useRepo(GroceryRepo).$delete(this.item.id)
          this.loading = false
        }
      })
    },
    editGrocery() {
      this.$emit('edit', this.item.id)
    },
    grocerySummary(id) {
      return useRepo(Grocery).find(id).summary()
    }
  }
}
</script>

<style lang="scss" scoped>
.groceryCard:not(:last-child) {
  margin-bottom: 5px;
}
.groceryCard {
  &.gathered {
    outline: 1px solid rgba(0, 0, 0, .12);
    color: rgba(0, 0, 0, .6);
  }

  .separator {
    border-left: 1px solid silver;
    width: 1px;
    height: 100%;
    min-height: 100%;
    align-self: stretch;
    margin: 0 4px;
    display: inline-block;
  }

  .middleContainer {
    display: flex;
    flex-direction: column;
  }

  .chipGroup {
    white-space: nowrap;
    overflow: auto;
    width: 0; // WHY DO WE NEED THIS TO AVOID OVERFLOW ??????
    padding-bottom: 3px;

    > * {
      flex: 0 0 auto; // Since parent is flex by adding media query classes
    }
  }
}
</style>
<style lang="scss">
.groceryCard {
  .v-card__text {
    padding: 8px;
  }

  .quantity {
    width: 70px;
    margin: 2px;

    * {
      padding: 0;
    }

    input {
      text-align: center;
      font-size: 1.5em;
      padding: 2px;
    }

    .v-text-field__suffix {
      padding-right: 4px;
    }
  }

  .unit {
    .v-field__input {
      text-align: center;
      width: 100%;
      justify-content: center;
      padding-left: 5px;
    }
  }

  .noIngredient {
    height: 100%;
    width: 100px;
    padding-top: 100%;
    position: relative;

    &:after {
      content: "";
      border: 2px dashed rgb(var(--v-theme-background-darken-1));
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.small .noIngredient {
    width: 50px;
  }

  .checkContainer {
    background-color: rgb(var(--v-theme-background-darken-2));
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.outlined {
      background-color: transparent;
    }

    .gatherBtn {
      height: 100%;
      min-width: 32px;
      padding: 0;
    }
  }

  .xsName {
    color: rgba(0, 0, 0, .87);
    line-height: 1.5em;
    font-size: 1.5em;
    font-weight: 500;
    align-self: center;
    margin: 0;
    border-bottom: 1px solid rgb(var(--v-theme-background-darken-2));
  }

  .mealList {
    .meal:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .bagBoxMarker {
    border: 1px solid rgb(var(--v-theme-primary));
    border-radius: 50%;
    padding: 10px;
  }

  .dimmed {
    opacity: .5;
  }
}
</style>
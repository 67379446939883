import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default createVuetify({
  defaults: {
    VTextField: {
      variant: 'underlined',
    },
  },
  theme: {
    defaultTheme: 'croqueMonsieur',
    themes: {
      croqueMonsieur: {
        dark: false,
        colors: {
          // CroqueMonsieur
          primary: '#072F40',
          secondary: '#DA8D02',
          accent: '#BE5B03',
          lowkey: '#F2C790',


          background: '#F5F5F5',

          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
      },
    },
    variations: {
      colors: ['background', 'primary'],
      lighten: 1,
      darken: 2,
    },
  },
  icons: {
    defaultSet: 'mdi',
  },
});

<template>
  <v-container fluid :class="'timeline pa-0 d-flex flex-column' + ($vuetify.display.smAndDown ? ' small' : '')">
    <v-tabs v-model="tabs" grow class="flex-grow-0 flex-shrink-0">
      <v-tab>
        En ce moment
      </v-tab>
      <v-tab>
        A l'année
      </v-tab>
    </v-tabs>
    <v-window v-model="tabs" class="tabContainer">
      <v-window-item class="thisMonth">
        <v-row justify="center" align="center" class="ma-0">
          <v-col v-for="item in thisMonth" :key="item.id" cols="auto" class="mx-1 thisMonthItem">
            <v-row class="ma-0">
              <v-img
                v-if="item.image_url"
                :src="item.image_url"
                :height="$vuetify.display.xs ? 50 : 100"
                :width="$vuetify.display.xs ? 50 : 100"
              />
              <div
                v-if="!item.image_url"
                class="noIngredient"
              />
            </v-row>
            <v-row justify="center" class="text-center">
              {{ item.name }}
            </v-row>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item>
        <v-row dense no-gutters class="pt-2 sticky monthNames">
          <v-col cols="2" />
          <v-col class="months">
            <span
              v-for="month in months"
              :key="month.nb"
              class="month"
            >
              <p
                :class="'title ' + (month.current ? 'current' : '')"
              >
                {{ $vuetify.display.smAndDown ? month.shortText : $vuetify.display.lgAndUp ? month.text : month.mediumText }}
              </p>
            </span>
          </v-col>
        </v-row>
        <v-row
          dense
          class="flex-grow-1 content ma-0 scrollbars"
        >
          <v-col>
            <v-row
              v-for="(item, index) in items"
              :key="item.id"
              dense
              no-gutters
              :class="index % 2 ? 'odd ma-0' : 'even ma-0'"
            >
              <v-col cols="2" class="pb-2 pt-2 pl-2">
                {{ item.name }}
              </v-col>
              <v-col class="months">
                <span
                  v-for="month in months"
                  :key="month.nb"
                  :class="'month ' + (month.current ? ' current' : '')"
                >
                  <span v-if="item.season_months.indexOf(month.nb+1) > -1" :class="cellClass(item, month)" />
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import moment from "moment"

export default {
  name: "SeasonTimeline",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    tabs: null,
  }),
  computed: {
    months() {
      const current = moment().month()
      const shorts = moment.monthsShort()
      const all = moment.months().map((m, i) => {
        return {
          nb: i,
          text: m,
          mediumText: shorts[i],
          shortText: m[0],
          current: i === current
        }
      })
      const tmp = all.concat(all).concat(all)
      return tmp.slice(12 + current - 5, 12 + current + 7)
    },
    thisMonth() {
      const current = moment().month()+1
      return this.items.filter((i) => i.season_months.indexOf(current) > -1)
    }
  },
  methods: {
    cellClass(item, month) {
      let c = "block"
      if (item.season_months.indexOf((month.nb+11)%12 + 1) === -1) {
        c += " start "
      }
      if (item.season_months.indexOf((month.nb+1) % 12 + 1) === -1) {
        c += " end "
      }
      if (month.current) {
        c += " current "
      }
      return c
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline {
  background: white;
  height: 100%;
  position: relative;

  .tabContainer {
    overflow: auto;
  }

  .odd {
    background-color: rgb(var(--v-theme-background));
  }

  .monthNames {
    margin-right: 12px;
    background-color: white;
    box-shadow: 0px 5px 5px 0px white;
  }
  &.small .monthNames {
    margin-right: 0;
  }

  .thisMonthItem {
    max-width: 100px;
  }

  .noIngredient {
    height: 100%;
    width: 100px;
    padding-top: 100%;
    position: relative;

    &:after {
      content: "";
      border: 2px dashed rgb(var(--v-theme-background-darken-1));
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &.small .noIngredient {
    width: 50px;
  }

  .thisMonth {
    min-height: 50vh;
    display: flex;
  }

  .months {
    display: flex;

    .month {
      flex: 0 0 8.33333%;
      max-width: 8.3333333333%;
      text-transform: capitalize;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .title.current {
        color: #fff;
        background-color: rgb(var(--v-theme-secondary));
        border-radius: 10px;
        padding: 0 5px;
      }
      &.current:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;

        border-left: 1px solid rgb(var(--v-theme-secondary));
      }

      .block {
        display: inline-block;
        width: 100%;
        height: 10px;

        background-color: rgb(var(--v-theme-secondary));

        &.start {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &.end {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
</style>
// COmposition Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import { Meal } from './Meal'
import { Ingredient } from './Ingredient'
import axios from 'axios'

class Composition extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'compositions'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      amount: this.attr(0),
      unit: this.string(''),
      ingredient_id: this.attr(0),
      ingredient: this.belongsTo(Ingredient, 'ingredient_id'),
      meal_id: this.attr(0),
      meal: this.belongsTo(Meal, 'meal_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      amount: NumberCast,
      ingredient_id: NumberCast,
      meal_id: NumberCast
    }
  }

  get unit_shorthand() {
    const units = Ingredient.units()
    if (units && units.length) {
      const match = units.filter((a) => a.value === this.unit)
      if (match.length === 1) {
        return match[0].shorthand
      }
    }

    return this.unit
  }
}

class CompositionRepo extends Repository {
  use = Composition

  async $fetch(meals) {
    let params = ''
    if(meals) {
      const ids = meals.reduce((prev, curr) => {
        prev.push(curr.id)
        return prev
      }, [])
      params += 'mealIds=' + encodeURIComponent(JSON.stringify(ids))
    }
    return new Promise(async (resolve, reject) => {
      try {
        const compos = await axios.get('/composition/get.php?' + params)
        resolve(this.save(compos.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchForGrocery(listId) {
    let uri = '/composition/get.php?filter=hasGrocery'
    if (listId) {
      uri += '&listId=' + encodeURIComponent(listId)
    }
    return new Promise(async (resolve, reject) => {
      try {
        const compos = await axios.get(uri)
        resolve(this.save(compos.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const compos = await axios.post('/composition/post.php', data)
        resolve(this.save(compos.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/composition/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

}

export {
  Composition,
  CompositionRepo
}
// User Model
import { Model } from 'pinia-orm'

class Globalconfig extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'globalconfig'

  static primaryKey = 'name'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      name: this.attr('').notNullable(),
      value: this.attr('')
    }
  }
}

export {
  Globalconfig
}
import { createRouter, createWebHistory } from 'vue-router'
import Calendar from './views/CalendarView.vue'
import Groceries from './views/GroceriesView.vue'
import Ingredients from './views/IngredientsView.vue'
import Recipes from './views/RecipesView.vue'
import Storage from './views/StorageView.vue'
import Login from './views/LoginView.vue'
import ForgottenPwd from './views/ForgottenPwdView.vue'
import NewPassword from './views/NewPasswordView.vue'
import Users from './views/UsersView.vue'
import SignUp from './views/SignUpView.vue'
import { useRepo } from 'pinia-orm'
import { UserRepo } from '@/models/User'

const ifNotAuthenticated = (to, from) => {
  if (!useRepo(UserRepo).isAuthenticated()) {
    return true
  }
  return { name: 'home' }
}

const ifAuthenticated = (to, from) => {
  if (useRepo(UserRepo).isAuthenticated()) {
    return true
  }
  return { name: 'login' }
}

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/forgotten-password',
      name: 'forgottenpwd',
      component: ForgottenPwd,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/new-password',
      name: 'newpwd',
      component: NewPassword,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/sign-up',
      name: 'signup',
      component: SignUp,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/',
      name: 'home',
      component: Calendar,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/groceries',
      name: 'gorceries',
      component: Groceries,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/ingredients',
      name: 'ingredients',
      component: Ingredients,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/recipes',
      name: 'recipes',
      component: Recipes,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/storage',
      name: 'storage',
      component: Storage,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: ifAuthenticated
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  }
})

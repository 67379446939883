<template>
  <v-card
    :class="'baseCard ' + (selected ? ' selected ' : '') + (dense ? ' my-2' : ' my-8 ')"
    rounded="lg"
  >
    <v-card-text class="pa-0">
      <v-row align="stretch" class="ma-0">
        <v-col
          cols="auto"
          class="d-flex align-center"
          :style="'border-left: 8px solid '+storage.color+';'"
          @click="select"
        >
          <v-icon color="primary">
            {{ icon }}
          </v-icon>
        </v-col>
        <v-col
          class="pa-0 text-center"
          @click="select"
        >
          <h2 class="py-4">
            {{ storage.name }}
          </h2>
          <v-divider />
          <a class="text-caption font-italic mr-2" @click="edit">
            Editer
          </a>
          -
          <a class="text-caption font-italic ml-2" @click="remove">
            Supprimer
          </a>
        </v-col>
        <v-col
          v-if="selectButton"
          cols="auto"
          class="d-flex align-center"
        >
          <v-btn
            v-if="!selected"
            icon
            size="small"
            variant="flat"
            @click="select"
          >
            <v-icon size="x-large">
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-btn
            v-if="selected"
            icon
            size="small"
            variant="flat"
            disabled
          >
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Storage } from '@/models/Storage'

export default {
  name: "StorageCard",
  components: {

  },
  props: {
    storageId: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectButton: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'edit', 'remove'],
  data: () => ({
  }),
  computed: {
    icon() {
      return Storage.allTypes().filter(s => s.value === this.storage.type)[0].icon
    },
    storage() {
      return useRepo(Storage).find(this.storageId)
    },
  },
  methods: {
    select() {
      this.$emit('select')
    },
    edit() {
      this.$emit('edit')
    },
    remove() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.baseCard {
  overflow: hidden;
}

.selected {
  background-color: rgb(var(--v-theme-background));
}
</style>
<template>
  <div class="groceries">
    <v-btn
      rounded
      color="primary"
      class="pl-0 ml-n4 mt-n2 rounded-ts-0 rounded-bs-0 historyBtn"
      :loading="loadingHistory"
      @mouseenter="showHistoryTitle = true"
      @mouseleave="showHistoryTitle = false"
      @click="openHistory"
    >
      <v-icon class="mx-3">
        mdi-history
      </v-icon>
      <v-expand-x-transition>
        <span v-if="showHistoryTitle">Historique</span>
      </v-expand-x-transition>
    </v-btn>
    <current-grocery-list />
    <v-navigation-drawer
      v-model="showHistory"
      absolute
      temporary
    >
      <h2 class="pa-3 historyTitle sticky">
        Listes précédentes
      </h2>
      <template
        v-for="list in groceryLists"
        :key="list.id"
      >
        <v-col
          class="px-2 py-0 d-flex align-top justify-space-between mt-4"
          cols="12"
        >
          <div class="pr-1 flex-grow-1">
            <v-text-field
              :model-value="list.name"
              name="name"
              label="Nom"
              single-line
              hide-details
              density="compact"
              variant="plain"
              :loading="loadingName === list.id"
              class="list mr-1"
              :append-inner-icon="loadingName === list.id ? 'mdi-loading' : 'mdi-pencil'"
              @update:model-value="(newValue) => updateListName(list.id, newValue)"
            />
            <div class="listSubtitle">
              <span class="listLeftover">
                {{ list.nb_items }} items
              </span>
              -
              <span class="deleteList" @click="deleteList(list.id)">
                supprimer
              </span>
            </div>
          </div>
          <v-btn
            icon
            size="small"
            class="collapseBtn"
            :loading="loadingList === list.id"
            variant="text"
            @click="toggleList(list.id)"
          >
            <v-icon size="x-large">
              {{ openedSections[list.id] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </v-col>
        <template
          v-for="(item, i) in listGroceries(list.id)"
          :key="'grocery_'+item.id"
        >
          <v-col
            cols="12"
            :class="'px-2 py-0 item ' + (openedSections[list.id] ? '' : 'collapsed')"
          >
            <v-row dense class="pa-0" align="center">
              <v-col class="itemName d-flex align-center">
                <v-icon>
                  mdi-circle-medium
                </v-icon>
                {{ grocerySummary(item.id) }}
                <linked-meal-tooltip
                  v-if="item.ingredient_id && linkedCompos(item).length > 0"
                  :linked-compos="linkedCompos(item)"
                  :compact="true"
                  color="accent"
                  class="ml-1"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  icon
                  size="x-small"
                  title="Ajouter à la liste actuelle"
                  :loading="loadingAdding === 'addbtn_'+i"
                  @click="addToLatest(item.id, 'addbtn_'+i)"
                >
                  <v-icon>
                    mdi-cart-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useRepo } from "pinia-orm"
import { GroceryList, GroceryListRepo } from "@/models/GroceryList"
import { Grocery, GroceryRepo } from "@/models/Grocery"
import { IngredientRepo } from "@/models/Ingredient"
import { MealRepo } from "@/models/Meal"
import { GroceryMeal, GroceryMealRepo } from "@/models/GroceryMeal"
import CurrentGroceryList from "@/components/CurrentGroceryList.vue"
import LinkedMealTooltip from '@/components/LinkedMealTooltip'
import _ from 'lodash'
import emitter from 'tiny-emitter/instance'

export default {
  name: "GroceriesView",
  components: {
    CurrentGroceryList,
    LinkedMealTooltip
  },
  data: () => ({
    showHistoryTitle: false,
    loadingHistory: false,
    showHistory: false,
    openedSections: {},
    loadingList: null,
    loadingAdding: null,
    loadingName: null
  }),
  computed: {
    groceryLists() {
      return useRepo(GroceryList)
              .orderBy(gl => gl.created.getTime(), 'desc')
              .offset(1)
              .get()
    }
  },
  methods: {
    openHistory: async function() {
      this.loadingHistory = true
      await useRepo(GroceryListRepo).$fetch()
      // Init collapse sections to get reactivity
      this.openedSections = useRepo(GroceryList).all().reduce((prev, curr) => {
        prev[curr.id] = false
        return prev
      }, {})
      this.showHistory = true
      this.loadingHistory = false
    },
    toggleList: async function(id) {
      if(!this.openedSections[id]) {
        this.loadingList = id
        await Promise.all([
          useRepo(IngredientRepo).$fetchForGrocery(id),
          useRepo(MealRepo).$fetchForGrocery(id),
          useRepo(GroceryRepo).$fetchByList(id),
          useRepo(GroceryMealRepo).$fetchByList(id)
        ])
        this.loadingList = null
      }
      this.openedSections[id] = !this.openedSections[id]
    },
    listGroceries(id) {
      return useRepo(Grocery)
              .where('grocerylist_id', id)
              .orderBy('name', 'asc')
              .get()
    },
    grocerySummary(id) {
      return useRepo(Grocery).find(id).summary()
    },
    linkedCompos(item) {
      if (item.ingredient_id) {
        return useRepo(GroceryMeal)
          .where("grocery_id", item.id)
          .with("meal")
          .get()
      }

      return []
    },
    addToLatest: async function(groceryId, refButton) {
      this.loadingAdding = refButton
      const response = await useRepo(GroceryListRepo).$copyFromList(groceryId)
      await useRepo(GroceryRepo).$get(response.data.id)
      this.loadingAdding = null
    },
    deleteList(id) {
      const list = useRepo(GroceryList).find(id)

      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer la liste de course "'+list.name+'" ?',
        validate: () => {
          useRepo(GroceryListRepo).$delete(id)
        }
      })
    },
    updateListName: _.debounce(async function(id, newName) {
      this.loadingName = id
      // DO NOT USE ARROW FUNCTION HERE OR "this" GETS LOST
      const data = {
        name: newName
      }
      await useRepo(GroceryListRepo).$update(id, data)
      this.loadingName = null
    }, 2000),
  }
}
</script>

<style lang="scss" scoped>
.historyBtn {
  z-index: 1;
  position: absolute;
  top: 80px;
  left: 15px;
}
.historyTitle {
  background-color: white;
}

.list {
  border-bottom: 5px solid rgb(var(--v-theme-secondary));
  font-weight: bold;
  color: rgb(var(--v-theme-primary));

  white-space: nowrap;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listSubtitle {
  font-size: .7em;

  .listLeftover {
    white-space: nowrap;
    font-weight: bold;
    color: rgb(var(--v-theme-secondary));
  }
  .deleteList {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.item {
  .itemName {
    font-size: .8em;
  }
}
.collapseBtn {
  display: inline-block;
}
.collapsed {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
</style>
<template>
  <VDropdown
    placement="bottom"
    class="mealList"
  >
    <v-chip
      :color="color"
      :size="$vuetify.display.xs || compact ? 'small' : 'default'"
      variant="elevated"
      elevation="0"
      @mouseleave="allowCloseMeals = true"
      @click="toggleMeals"
    >
      <template v-if="compact">
        <v-icon size="small" class="mx-n2">
          mdi-information-variant
        </v-icon>
      </template>
      <template v-else>
        {{ linkedGroceryMeals.length }} repas
      </template>
    </v-chip>
    <template #popper>
      <v-container>
        <h3 v-if="compact">
          {{ linkedGroceryMeals.length }} repas :
        </h3>
        <p
          v-for="gm in linkedGroceryMeals"
          :key="gm.id"
          class="upperFL meal"
        >
          <template v-if="gm.meal">
            {{ gm.meal.name }} ({{ gm.meal.shortDate }} - {{ gm.meal.nb_people }}p. - {{ getAmountFromCompo(gm.meal_id) }})
          </template>
        </p>
      </v-container>
    </template>
  </VDropdown>
</template>

<script>
import _ from "lodash"
import { useRepo } from "pinia-orm"
import { Composition } from "@/models/Composition"

export default {
  name: "LinkedMealTooltip",
  components: {
  },
  props: {
    linkedGroceryMeals: {
      type: Array,
      default: () => []
    },
    ingredientId: {
      type: Number,
      default: 0
    },
    compact: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'lowkey'
    }
  },
  data: () => ({
  }),
  computed: {
  },
  mounted () {
  },
  methods: {
    toggleMeals () {
      this.allowCloseMeals = false
      this.showMeals = !this.showMeals
    },
    getAmountFromCompo(mealId) {
      const compo = useRepo(Composition)
        .where('meal_id', mealId)
        .where('ingredient_id', this.ingredientId)
        .get()

      if(compo && compo.length === 1) {
        return '' + compo[0].amount + compo[0].unit_shorthand
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.mealList {
  display: inline-block;

  .meal:last-of-type {
    margin-bottom: 0 !important;
  }
}
</style>
<template>
  <v-card
    :id="componentId"
    max-height="80vh"
  >
    <v-card-text class="scrollbars pt-0 pb-6">
      <v-row
        class="mt-3"
      >
        <v-col
          cols="4"
        >
          <v-img
            :src="recipe.image ? recipe.image : defaultRecipeImage"
            width="100%"
            aspect-ratio="1"
            cover
            class="image"
          />
        </v-col>
        <v-col
          cols="8"
        >
          <v-row
            justify="center"
          >
            <v-col
              id="here"
              class="text-center text-h5 text-primary py-0 upperFirstLetter"
              cols="auto"
            >
              <recipe-share :recipe="recipe" :small="true" color="primary" class="mr-2" :container-id="componentId" />
              {{ recipe.name }}
              <v-btn
                v-if="recipe.source"
                icon
                variant="flat"
                size="small"
                :href="recipe.source"
                target="_blank"
                class="ml-2"
                title="Ouvrir la recette d'origine"
              >
                <v-icon
                  size="small"
                >
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            dense
          >
            <v-col cols="auto" class="pa-0">
              <star-vote :value="recipe.score" :min="1" :max="4" @clicked-star="setScore" />
            </v-col>
          </v-row>
          <v-row
            justify="space-around"
          >
            <v-col
              v-if="recipe.dish_type"
              cols="4"
              class="text-subtitle-1 text-center"
            >
              {{ dishTypes.filter(m => m.value === recipe.dish_type)[0].text }}
            </v-col>
            <v-col
              v-if="recipe.cuisine_type"
              cols="4"
              class="text-subtitle-1 text-center"
            >
              {{ cuisineTypes.filter(m => m.value === recipe.cuisine_type)[0].text }}
            </v-col>
          </v-row>
          <v-row
            justify="space-around"
            dense
          >
            <v-col
              cols="auto"
            >
              <picto-info
                icon="mdi-clock-outline"
                title="Préparation"
                :value="formatTime(recipe.preparation_time)"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <picto-info
                icon="mdi-pot-steam-outline"
                title="Cuisson"
                :value="formatTime(recipe.baking_time)"
              />
            </v-col>
            <v-col cols="auto">
              <picto-info
                icon="mdi-account-group"
                title="Convives"
                :value="''+recipe.nb_people"
              />
            </v-col>
            <v-col v-if="recipe.difficulty" cols="auto">
              <picto-info
                icon="mdi-chef-hat"
                title="Difficulté"
                :value="difficultyLevels.filter(d => d.value === recipe.difficulty)[0].text"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="recipe.comment"
          >
            <v-col>
              <div class="blockquote pa-2">
                <v-icon color="primary" size="small" class="quoteIcon">
                  mdi-format-quote-open
                </v-icon>
                {{ recipe.comment }}
                <v-icon color="primary" size="small" class="quoteIcon">
                  mdi-format-quote-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="4"
          class="text-h5 text-center"
        >
          Ingrédients
        </v-col>
        <v-col
          cols="8"
          class="text-h5 text-center"
        >
          Instructions
        </v-col>
      </v-row>
      <v-row
        dense
      >
        <v-col
          cols="4"
        >
          <v-row
            v-for="ingredient in recipe.ingredients"
            :key="ingredient.ingredient_id"
            dense
          >
            <v-col
              cols="4"
              class="text-center"
              align-self="center"
            >
              {{ ingredient.amount ? ingredient.amount : '' }}
              {{ ingredient.unit_shorthand }}
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-img
                v-if="ingredient.ingredient.image_url"
                :src="ingredient.ingredient.image_url"
                width="30"
                height="30"
              />
              <div
                v-if="!ingredient.ingredient.image_url"
                class="noIngredientImage"
              />
            </v-col>
            <v-col align-self="center">
              {{ ingredient.ingredient ? ingredient.ingredient.name : '' }}
              <v-icon
                v-if="ingredient.original"
                v-tooltip.bottom="ingredient.original"
                color="secondary"
              >
                mdi-information-outline
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="8"
        >
          <v-row
            v-for="(instruction, index) in recipe.instructions"
            :key="index"
          >
            <v-col
              cols="auto"
              class="py-2"
            >
              <v-chip
                class="instructionIndex"
              >
                {{ index+1 }}
              </v-chip>
            </v-col>
            <v-col
              align-self="center"
              class="upperFirstLetter"
            >
              {{ instruction }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      v-if="$vuetify.display.smAndUp"
      class="elevation-4"
    >
      <v-btn
        v-if="canDelete"
        variant="text"
        :disabled="isOffline"
        @click="remove"
      >
        Supprimer
      </v-btn>
      <div class="flex-grow-1" />
      <v-btn
        icon
        color="primary"
        size="small"
        @click="toggleAddToPrint"
      >
        <v-icon>
          {{ addedToPrint ? 'mdi-printer-check' : 'mdi-printer' }}
        </v-icon>
      </v-btn>
      <v-btn
        v-if="canEdit"
        color="primary"
        variant="text"
        :disabled="isOffline"
        @click="edit"
      >
        Editer
      </v-btn>
      <v-btn
        color="primary"
        variant="outlined"
        @click="close"
      >
        Fermer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Recipe } from '@/models/Recipe'
import { EntityToPrint } from '@/models/EntityToPrint'
import { Ingredient } from '@/models/Ingredient'
import { RecipeRepo } from "@/models/Recipe"
import PictoInfo from '@/components/PictoInfo'
import StarVote from '@/components/StarVote'
import RecipeShare from '@/components/RecipeShare'
import moment from 'moment'
import shared from '@/shared'
import recipeImg from '@/assets/recipe.png'

export default {
  name: 'RecipeDetails',
  components: {
    PictoInfo,
    StarVote,
    RecipeShare
  },
  props: {
    recipe: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Recette'
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close', 'edit', 'remove', 'updateScore'],
  data: () => ({
    defaultRecipeImage: recipeImg,
    uid: shared.getUid()
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    componentId () {
      return 'recipeDetail-'+this.uid
    },
    dishTypes () {
      return Recipe.dishTypes()
    },
    cuisineTypes () {
      return Recipe.cuisineTypes()
    },
    ingredientUnits () {
      return Ingredient.units()
    },
    difficultyLevels () {
      return Ingredient.difficultyLevels()
    },
    addedToPrint () {
      return useRepo(EntityToPrint).where('entity_id', this.recipe.id).get().length > 0
    }
  },
  created () {
  },
  methods: {
    formatTime (minutes) {
      const d = moment.duration(minutes, 'minutes')
      let time = ''
      time +=  d.hours() === 0 ? '' : d.hours() + ' h '
      time += d.minutes() === 0 ? '' : (d.minutes() < 10 ? '0' + d.minutes() : d.minutes()) + ' m'
      return time || '-'
    },
    close () {
      this.$emit('close')
    },
    edit () {
      this.$emit('edit')
    },
    remove () {
      this.$emit('remove')
    },
    async setScore (score) {
      try {
        await useRepo(RecipeRepo).$update(this.recipe.id, {
          score: score
        })
        // We are working on a copy of the recipe: update local copied data
        this.$emit('updateScore', score)
      }
      catch(e) {}
    },
    toggleAddToPrint () {
      if(this.addedToPrint) {
        EntityToPrint.delete((rtp) => rtp.entity_id === this.recipe.id)
      }
      else {
        EntityToPrint.save({
            entity_id: this.recipe.id,
            entity_type: 'recipe'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  border-radius: 10px;
  background-color: rgb(var(--v-theme-primary));
}

.quoteIcon {
  vertical-align: top;
}

.instructionIndex {
  background-color: rgb(var(--v-theme-primary));
  min-width: 40px;
  justify-content: center;
  color: white;
}

.noIngredientImage {
  height: 100%;
  min-height: 30px;
  width: 30px;
  padding-top: 100%;
  position: relative;

  &:after {
    content: "";
    border: 2px dashed rgb(var(--v-theme-background-darken-1));
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
<template>
  <span>
    <v-dialog
      v-model="showPrint"
      content-class="printArea"
    >
      <template
        v-for="(rtp, index) in entitiesToPrint"
        :key="'print_'+rtp.entity_type+'_'+rtp.id"
      >
        <print-card
          :recipe-id="rtp.entity_type === 'recipe' ? rtp.entity_id : 0"
          :ingredient-id="rtp.entity_type === 'ingredient' ? rtp.entity_id : 0"
          :entity-type="rtp.entity_type"
          :custom-data="rtp.entity_data"
          :image-full-view="rtp.entity_type === 'ingredient'"
          class="printCard"
        />
        <p
          v-if="index > 0 && (index+1) % printPerPage === 0"
          :key="'break_'+index"
          class="pagebreak"
        >
          Page {{ Math.floor(index/(printPerPage-1)) }} de {{ Math.ceil(entitiesToPrint.length / printPerPage) }}
        </p>
      </template>
    </v-dialog>
    <v-btn
      v-if="entitiesToPrint.length > 0"
      :loading="loadingData"
      variant="outlined"
      class="mr-2"
      @click="printIt"
    >
      <v-icon class="mr-2" size="small">
        mdi-printer
      </v-icon>
      Imprimer ({{ entitiesToPrint.length }})
    </v-btn>
  </span>
</template>

<script>
import QrScanner from 'qr-scanner'
import { useRepo } from 'pinia-orm'
import { EntityToPrint } from '@/models/EntityToPrint'
import { Ingredient } from '@/models/Ingredient'
import { RecipeRepo } from '@/models/Recipe'
import { IngredientRepo } from '@/models/Ingredient'
import PrintCard from '@/components/PrintCard'

export default {
  name: 'PrintButton',
  components: {
    PrintCard
  },
  emits: ['printed'],
  data: () => ({
    loadingData: false,
    showPrint: false,
    printPerPage: 10,
  }),
  computed: {
    entitiesToPrint () {
      return useRepo(EntityToPrint).all()
    }
  },
  mounted: async function () {
    window.addEventListener('afterprint', ()=> {
      const realPrintable = document.getElementById('tmpPrint')
      if(realPrintable) {
        document.body.removeChild(realPrintable)
      }
      this.showPrint = false
    })

    this.checkCameraAvailability()
  },
  methods: {
    async checkCameraAvailability() {
      QrScanner.hasCamera().then(val => {
        this.canScanCode = true
      })
      .catch(err => {
        this.canScanCode = false
      })
    },
    async printIt () {
      this.loadingData = true
      await Promise.all(
        useRepo(EntityToPrint).all().map((r) => {
          return new Promise((resolve, reject) => {
            if(r.entity_type === 'recipe') {
              useRepo(RecipeRepo).$get(r.entity_id).then(() => {
                useRepo(RecipeRepo).$loadIngredients(r.entity_id).then(resolve).catch(reject)
              }).catch(reject)
            }
            else if(r.entity_type === 'ingredient') {
              const cachedIng = useRepo(Ingredient).find(r.entity_id)
              if(cachedIng.id) {
                resolve()
              }
              else {
                useRepo(IngredientRepo).$get(r.entity_id).then(resolve).catch(reject)
              }
            }
            else {
              resolve()
            }
          })
        })
      )
      this.loadingData = false

      this.showPrint = true

      // Sleep 1s
      await new Promise(r => setTimeout(r, 1000))

      const ref = document.getElementsByClassName('printArea')[0]
      const clone = ref.cloneNode(true)
      // Special copy for canvas
      Array.from(ref.getElementsByClassName('barcode')).forEach((src) => {
        let classId
        src.classList.forEach((cl) => {
          if(cl.indexOf('barcode_') === 0) {
            classId = cl
          }
        })
        const dest = clone.getElementsByClassName(classId)[0]
        //set dimensions
        dest.width = src.width;
        dest.height = src.height;

        //apply the old canvas to the new one
        dest.getContext('2d').drawImage(src, 0, 0);
      })

      const realPrintable = document.createElement('div')
      realPrintable.id = 'tmpPrint'
      document.body.appendChild(realPrintable)

      realPrintable.appendChild(clone)

      // Sleep 500ms
      //await new Promise(r => setTimeout(r, 1000));
      window.print()
      this.$emit('printed')
    }
  }
}
</script>
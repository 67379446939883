// StoredItem Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast, DateCast } from 'pinia-orm/casts'
import { Ingredient } from '@/models/Ingredient'
import { Recipe } from '@/models/Recipe'
import { Storage } from '@/models/Storage'
import axios from 'axios'

class StoredItem extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'storeditems'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.string(''),
      storage_date: this.attr(null),
      expiration_date: this.attr(null),
      type: this.string(''),
      quantity_parts: this.attr(0),
      quantity_volume: this.attr(0),
      quantity_unit: this.string(''),
      stack: this.attr(0),
      storage_id: this.attr(0),
      storage: this.belongsTo(Storage, 'storage_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      storage_date: DateCast,
      quantity_parts: NumberCast,
      quantity_volume: NumberCast,
      stack: NumberCast,
      storage_id: NumberCast
    }
  }

  static allTypes() {
    return Ingredient.allTypes().concat(Recipe.dishTypes())
  }

  static units() {
    return Ingredient.units()
  }

}

class StoredItemRepo extends Repository {
  use = StoredItem

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.get('/storeditem/get.php')
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.get('/storeditem/get.php?id=' + id)
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getLatest(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.get('/storeditem/get.php?filter=latest')
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    if (!data) {
      data = {}
    }
    if (!data.name) {
      throw "Name is mandatory"
    }
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.post('/storeditem/post.php', data)
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.put('/storeditem/put.php?id=' + id, data)
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const sis = await axios.delete('/storeditem/delete.php?id=' + id)
        resolve(this.save(sis.data))
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  StoredItem,
  StoredItemRepo
}
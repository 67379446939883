<template>
  <canvas
    :id="'barcode_'+id"
    :class="'barcode barcode_'+id"
  />
</template>

<script>
import bwipjs from 'bwip-js'
import shared from '@/shared'

export default {
  name: "BarcodeRender",
  components: {
  },
  props: {
    data: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'qrcode'
    },
    height: {
      type: Number,
      default: 10
    },
    width: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    id: shared.getUid()
  }),
  mounted() {
    bwipjs.toCanvas("barcode_"+this.id, {
        bcid:        this.type,       // Barcode type
        text:        this.data,    // Text to encode
        scale:       1,               // 3x scaling factor
        height:      this.height,              // Bar height, in millimeters
        width:       this.width,              // Bar width, in millimeters
        includetext: false,            // Show human-readable text
        textxalign:  'center',        // Always good to set this
    })
  }
}
</script>
// Ingredient Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'
import { Ingredient } from './Ingredient';
import { GroceryMeal } from './GroceryMeal';
import { GroceryList } from './GroceryList';
import moment from 'moment';
import axios from 'axios';

class Grocery extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'groceries'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(0).notNullable(),
      name: this.attr(''),
      gathered: this.attr(false),
      ignored: this.attr(false),
      frommeals: this.attr(false),
      amount: this.attr(0),
      unit: this.string('nb'),
      section: this.string(''),
      ingredient_id: this.attr(null),
      ingredient: this.belongsTo(Ingredient, 'ingredient_id'),
      grocerymeal: this.hasMany(GroceryMeal, 'grocery_id'),
      grocerylist_id: this.attr(null),
      grocerylist: this.belongsTo(GroceryList, 'grocerylist_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      gathered: BooleanCast,
      ignored: BooleanCast,
      frommeals: BooleanCast,
      amount: NumberCast,
      ingredient_id: NumberCast,
      grocerylist_id: NumberCast,
    }
  }

  summary() {
    return this.name + ' (' + this.amount + (this.unit != 'nb' ? ' ' + this.unit : '') + ')'
  }
}

class GroceryRepo extends Repository {
  use = Grocery

  async $fetch () {
    return new Promise(async (resolve, reject) => {
      try {
        const groceries = await axios.get('/grocery/get.php')
        resolve(this.save(groceries.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchByList(listId) {
    return new Promise(async (resolve, reject) => {
      try {
        const grocery = await axios.get('/grocery/get.php?listId=' + encodeURIComponent(listId))
        resolve(this.save(grocery.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get (id) {
    return new Promise(async (resolve, reject) => {
      try {
        const grocery = await axios.get('/grocery/get.php?id=' + id)
        resolve(this.save(grocery.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create (data) {
    return new Promise(async (resolve, reject) => {
      try {
        const grocery = await axios.post('/grocery/post.php', data)
        resolve(this.save(grocery.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update (id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const grocery = await axios.put('/grocery/put.php?id=' + id, data)
        resolve(this.save(grocery.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete (id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/grocery/delete.php?id='+id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $deleteAll (includeUncollected) {
    let data = {}
    if(includeUncollected) {
      data.uncollected = true
    }
    return new Promise((resolve, reject) => {
      axios.delete('/grocery/bulk/delete.php', {
          params: data,
          save: false
        })
      .then(() => {
        if(includeUncollected) {
          this.flush()
        }
        else {
          this.where('gathered', true).delete()
        }
        resolve()
      })
      .catch(reject)
    })
  }


  async $addFromMeals(startDate, endDate, listId) {
    const oldGenerated = this.with('grocerymeal')
      .get()
    oldGenerated.map((g) => {
      // Deleting previously generated groceries
      this.destroy(g.id)
    })
    const data = {
      start: moment(startDate).format('Y-MM-DD'),
      end: moment(endDate).format('Y-MM-DD')
    }
    if (listId) {
      data.listId = encodeURIComponent(listId)
    }
    await axios.post('/grocery/fillfrommeals/post.php', data)
    // Getting all new groceries
    this.$fetch()
  }
}

export {
  Grocery,
  GroceryRepo
}
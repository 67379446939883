export default {
  getUrlVars: function () {
    const vars = {}
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[decodeURIComponent(key)] = decodeURIComponent(value)
      }
    )
    return vars
  },

  isHexDark(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness < 155;
  },

  getUid() {
    return String(
      Date.now().toString(32) +
      Math.random().toString(16)
    ).replace(/\./g, '')
  }
}
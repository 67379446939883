// Community Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import axios from 'axios'
import { Globalconfig } from '@/models/Globalconfig'

class Community extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'communities'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null).notNullable(),
      name: this.string(''),
      code: this.string(''),
      code_date: this.attr(null),
      default_nb_people: this.attr('1'),
      ical: this.string(''),
      ical_date: this.attr(null)
    }
  }

  static casts() {
    return {
      id: NumberCast,
      default_nb_people: NumberCast
    }
  }

  static getCurrentCommunity() {
    const c = useRepo(Globalconfig).find('currentCommunity')
    if (c) {
      return c.value
    }
    else {
      const lc = localStorage.getItem('community-id')
      if (lc && !isNaN(parseInt(lc))) {
        return {
          id: lc
        }
      }
    }
    return null
  }
}

class CommunityRepo extends Repository {
  use = Community

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const cs = await axios.get('/community/get.php')
        resolve(this.save(cs.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.get('/community/get.php?id=' + id)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.post('/community/post.php', data)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.put('/community/put.php?id=' + id, data)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/community/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $join(code) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.post('/community/join/post.php?code=' + code)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $renewCode(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.post('/community/join/generate/post.php?id=' + id)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $renewICal(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const c = await axios.post('/community/ical/generate/post.php?id=' + id)
        resolve(this.save(c.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  setCurrentCommunity(id) {
    let cId;
    if(id) {
      cId = id
    }
    else {
      cId = parseInt(localStorage.getItem('community-id'))
    }
    if (!isNaN(cId) && cId) {
      // Insert for global availability
      const c = this.find(cId)
      if(c) {
        localStorage.setItem('community-id', c.id)
        useRepo(Globalconfig).save({
          name: 'currentCommunity',
          value: c
        })
        return;
      }
    }

    // Fallback if we did not provide any id or could not use the localstorage one
    const c = this.all()[0]
    if(c) {
      localStorage.setItem('community-id', c.id)
      // Insert for global availability
      useRepo(Globalconfig).save({
        name: 'currentCommunity',
        value: c
      })
    }
  }

}

export {
  Community,
  CommunityRepo
}
// Store Model
import axios from 'axios'
import { Model, Repository } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'

class Store extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'stores'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.string('Magasin'),
      sections: this.attr(null),
      default_store: this.attr(false),
      community_id: this.attr(0)
    }
  }

  static casts() {
    return {
      id: NumberCast,
      default_store: BooleanCast,
      community_id: NumberCast
    }
  }


  static mutators() {
    return {
      sections(value) {
        if (typeof value === 'string') {
          try {
            return JSON.parse(value)
          }
          catch (e) {
            return []
          }
        }
        else {
          if (Array.isArray(value)) {
            return value
          }
          else {
            return []
          }
        }
      },
    }
  }
}

class StoreRepo extends Repository {
  use = Store

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const stores = await axios.get('/store/get.php')
        resolve(this.save(stores.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchForCommunity(communityId) {
    return new Promise(async (resolve, reject) => {
      try {
        const stores = await axios.get('/store/get.php?community_id=' + encodeURIComponent(communityId))
        resolve(this.save(stores.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const store = await axios.get('/store/get.php?id=' + id)
        resolve(this.save(store.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const store = await axios.post('/store/post.php', data)
        resolve(this.save(store.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const store = await axios.put('/store/put.php?id=' + id, data)
        resolve(this.save(store.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/store/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

}

export {
  Store,
  StoreRepo
}
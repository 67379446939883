// Recipe Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'
import { RecipeIngredient, RecipeIngredientRepo } from '@/models/RecipeIngredient'
import { RecipeMeal } from '@/models/RecipeMeal'
import { IngredientRepo } from '@/models/Ingredient'
import axios from 'axios'

class Recipe extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'recipes'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.string(''),
      comment: this.string(''),
      dish_type: this.attr(''),
      cuisine_type: this.attr(''),
      ingredients: this.hasMany(RecipeIngredient, 'recipe_id').onDelete('cascade'),
      meals: this.hasMany(RecipeMeal, 'recipe_id').onDelete('cascade'),
      instructions: this.attr(''),
      preparation_time: this.attr(0),
      baking_time: this.attr(0),
      baking_type: this.attr(''),
      difficulty: this.attr(0),
      nb_people: this.attr(0),
      score: this.attr(0),
      source: this.string(''),
      image: this.string('')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      preparation_time: NumberCast,
      baking_time: NumberCast,
      difficulty: NumberCast,
      nb_people: NumberCast,
      score: NumberCast
    }
  }

  static difficultyLevels() {
    const types = [
      { text: '-', value: 0 },
      { text: 'Novice', value: 1 },
      { text: 'Régulier', value: 2 },
      { text: 'Avancé', value: 3 },
      { text: 'Expert', value: 4 }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }

  static dishTypes() {
    const types = [
      { text: 'Pain', value: 'bread' },
      { text: 'Céréales', value: 'cereals' },
      { text: 'Condiment et sauce', value: 'condiments and sauces' },
      { text: 'Boisson', value: 'drinks' },
      { text: 'Dessert', value: 'desserts' },
      { text: 'Plat principal', value: 'main course' },
      { text: 'Pancake', value: 'pancake' },
      { text: 'Préparations', value: 'preps' },
      { text: 'Conservation', value: 'preserve' },
      { text: 'Salade', value: 'salad' },
      { text: 'Sandwiche', value: 'sandwiches' },
      { text: 'Accompagnement', value: 'side dish' },
      { text: 'Soupe', value: 'soup' },
      { text: 'Entrée', value: 'starter' },
      { text: 'Bonbon', value: 'sweets' }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }

  static cuisineTypes() {
    const types = [
      { text: 'Américaine', value: 'American' },
      { text: 'Asiatique', value: 'Asian' },
      { text: 'Britannique', value: 'British' },
      { text: 'Des Caribbes', value: 'Caribbean' },
      { text: 'D\'Europe Centrale', value: 'Central Europe' },
      { text: 'Chinoise', value: 'Chinese' },
      { text: 'D\'Europe de l\'Est', value: 'Eastern Europe' },
      { text: 'Française', value: 'French' },
      { text: 'Indienne', value: 'Indian' },
      { text: 'Italienne', value: 'Italian' },
      { text: 'Japonaise', value: 'Japanese' },
      { text: 'Kasher', value: 'Kosher' },
      { text: 'Méditerranéenne', value: 'Mediterranean' },
      { text: 'Mexicaine', value: 'Mexican' },
      { text: 'Du Moyen Orient', value: 'Middle Eastern' },
      { text: 'Nordique', value: 'Nordic' },
      { text: 'D\'Amérique du Sud', value: 'South American' },
      { text: 'D\'Asie du Sud-Est', value: 'South East Asian' }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }


  static bakingTypes() {
    const types = [
      { text: 'Four', value: 'oven' },
      { text: 'Poêle', value: 'pan' },
      { text: 'Casserole', value: 'pot' },
      { text: 'Robot cuisinier', value: 'robot' }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }

  static units() {
    const units = [
      { text: 'Item(s)', shorthand: '', value: 'nb' },
      { text: 'Gramme(s)', shorthand: 'g', value: 'g' },
      { text: 'Kilo-gramme(s)', shorthand: 'kg', value: 'kg' },
      { text: 'Litre(s)', shorthand: 'l', value: 'l' },
      { text: 'Milli-litre(s)', shorthand: 'ml', value: 'ml' },
    ]
    return units
  }

  static mutators() {
    return {
      instructions(value) {
        if (value && typeof value === 'string' && value.length > 0) {
          return value.split('\n').filter(l => l.length > 0)
        }

        if (value === '') {
          return []
        }

        return value
      },
      score(value) {
        if (typeof value === 'string') {
          const newVal = parseInt(value)
          if (!isNaN(newVal)) {
            return newVal
          }
          else {
            return 0
          }
        }

        return value
      },
      nb_people(value) {
        if (typeof value === 'string') {
          const newVal = parseInt(value)
          if (!isNaN(newVal)) {
            return newVal
          }
          else {
            return 0
          }
        }

        return value
      }
    }
  }

  seasonnality() {
    if (this.ingredients && this.ingredients.length > 0) {
      let months = parseInt('111111111111', 2)
      this.ingredients.forEach((ing) => {
        const ingredient = ing.ingredient
        if (ingredient.season_months && ingredient.season_months.length > 0) {
          // Months are in the right order, starting at 1
          const ingMonths = ingredient.season_months.reduce((prev, curr) => {
            if (prev === '') {
              // Bootstrap with a string of 0
              prev = (new Array(curr - 1)).fill('0').join('')
              return prev + '1'
            }
            else {
              // Need to insert a filler in case there is a gap between months
              let filler = ''
              if (curr > prev.length) {
                filler = (new Array(curr - prev.length - 1)).fill('0').join('')
              }
              return prev + filler + '1'
            }
          }, '')
          // Bitwise AND to continue building the mask
          months &= parseInt(ingMonths, 2)
        }
      })
      // Apply the mask
      const strMonths = (months >>> 0).toString(2)

      const finalMonths = (new Array(12 - strMonths.length)).fill('0').concat(strMonths.split(''))
      return finalMonths
    }

    return []
  }

  get dishTypeLabel() {
    const found = Recipe.dishTypes().filter(m => m.value === this.dish_type)
    if (found && found.length === 1) {
      return found[0].text
    }
    return ''
  }

  getDataForQRCode() {
    return 'recipe_' + this.id
  }

  static getIdFromQRCode(data) {
    if (data && typeof data === 'string' && data.length > 0) {
      const regex = /^recipe_([1-9][0-9]*)$/
      const matches = data.match(regex)
      if (matches && matches.length === 2) {
        return parseInt(matches[1])
      }

      return null
    }
    return null
  }

  async toString() {
    let response = this.name
    const newline = "\r\n"

    await this.$loadIngredients(this.id)
    const ingredients = useRepo(RecipeIngredient).with('ingredient').where('recipe_id', this.id).get()
    if (ingredients.length > 0) {
      response += newline + newline + "Ingredients :" + newline
      ingredients.map(i => {
        if (i.amount === 0) {
          response += i.ingredient.name
        }
        else {
          response += i.amount + ' ' + i.unit_shorthand + ' ' + i.ingredient.name
        }
        if (i.original && i.original.trim().toLowerCase() !== i.ingredient.name.toLowerCase()) {
          response += ' (' + i.original.trim() + ')'
        }
        response += newline
      })
    }

    if (this.instructions && this.instructions.length > 0) {
      response += newline + newline + "Instructions :" + newline
      this.instructions.map(i => {
        response += '- ' + i + newline
      })
    }

    if (this.source) {
      response += newline + newline + "Vu en premier sur : " + this.source
    }

    return response
  }

  async $toString() {
    let response = this.name
    const newline = "\r\n"

    await useRepo(RecipeRepo).$loadIngredients(this.id)
    const ingredients = useRepo(RecipeIngredient).with('ingredient').where('recipe_id', this.id).get()
    if (ingredients.length > 0) {
      response += newline + newline + "Ingredients :" + newline
      ingredients.map(i => {
        if (i.amount === 0) {
          response += i.ingredient.name
        }
        else {
          response += i.amount + ' ' + i.unit_shorthand + ' ' + i.ingredient.name
        }
        if (i.original && i.original.trim().toLowerCase() !== i.ingredient.name.toLowerCase()) {
          response += ' (' + i.original.trim() + ')'
        }
        response += newline
      })
    }

    if (this.instructions && this.instructions.length > 0) {
      response += newline + newline + "Instructions :" + newline
      this.instructions.map(i => {
        response += '- ' + i + newline
      })
    }

    if (this.source) {
      response += newline + newline + "Vu en premier sur : " + this.source
    }

    return response
  }
}

class RecipeRepo extends Repository {
  use = Recipe

  async $fetch(settings) {
    let params = ''
    if(settings && settings.ids) {
      params += 'ids=' + encodeURIComponent(JSON.stringify(settings.ids)) + '&'
    }
    if(settings && settings.hasOwnProperty('offset')) {
      params += 'offset=' + settings.offset + '&'
    }
    if(settings && settings.hasOwnProperty('perPage')) {
      params += 'limit=' + settings.perPage + '&'
    }
    if(settings && settings.hasOwnProperty('search')) {
      params += 'search=' + encodeURIComponent(settings.search) + '&'
    }
    if(settings && settings.hasOwnProperty('filters') && Object.keys(settings.filters).length > 0) {
      const nbFilters = Object.keys(settings.filters).filter((f) => {
        return !!settings.filters[f] || settings.filters[f].length
      }).length
      if (nbFilters) {
        params += 'filters=' + encodeURIComponent(JSON.stringify(settings.filters)) + '&'
      }
    }
    return new Promise(async (resolve, reject) => {
      try {
        const recipes = await axios.get('/recipe/get.php?' + params)
        resolve(this.save(recipes.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchForWeek(firstDay) {
    return new Promise(async (resolve, reject) => {
      try {
        const recipes = await axios.get('/recipe/get.php', {
          params: {
            mode: 'usedByMeal',
            firstDay: firstDay
          }
        })
        resolve(this.save(recipes.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const recipe = await axios.get('/recipe/get.php?id=' + id)
        resolve(this.save(recipe.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getByMeal(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const recipe = await axios.get('/recipe/get.php?meal_id=' + id)
        resolve(this.save(recipe.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $loadIngredients(id) {
    return new Promise(async function(resolve, reject) {
      try {
        await useRepo(IngredientRepo).$getByRecipe(id)
        await useRepo(RecipeIngredientRepo).$getByRecipe(id)
        resolve()
      }
      catch(e) {
        reject()
      }
    })
  }

  async $create(data) {
    let filteredData = data
    if (data.ingredients) {
      data.ingredients.map(i => {
        delete i.ingredient
      })
    }
    return new Promise(async (resolve, reject) => {
      try {
        const recipe = await axios.post('/recipe/post.php', filteredData)
        resolve(this.save(recipe.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    let filteredData = data
    if(data.hasOwnProperty('ingredients')) {
      data.ingredients.map(i => {
        delete i.ingredient
      })
      // Delete linked between recipe and ingredients since they will be re-created
      useRepo(RecipeIngredient).where('recipe_id', id).delete()
    }
    return new Promise(async (resolve, reject) => {
      try {
        const recipe = await axios.put('/recipe/put.php?id=' + id, filteredData)
        resolve(this.save(recipe.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/recipe/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $search(value, type, filters) {
    let url = '/recipe/search/get.php?value=' + encodeURIComponent(value)
    if(type) {
      url += '&type=' + encodeURIComponent(type)
    }
    if(filters) {
      url += '&filters=' + encodeURIComponent(JSON.stringify(filters))
    }
    return axios.get(url)
  }

  async $onlineSearch(source, value) {
    let ret = null
    switch (source) {
      case 'marmiton':
        ret = await axios.get('/recipe/remote/marmiton/get.php?search=' + encodeURIComponent(value))
        break;
      case 'magimix_cook_expert':
        ret = await axios.get('/recipe/remote/magimix_cook_expert/get.php?search=' + encodeURIComponent(value))
        break;
      case 'cookomix':
        ret = await axios.get('/recipe/remote/cookomix/get.php?search=' + encodeURIComponent(value))
        break;

      default:
        break;
    }
    return ret.data
  }

  async $onlineDetails(source, id) {
    let ret = null
    switch (source) {
      case 'marmiton':
        ret = await axios.get('/recipe/remote/marmiton/get.php?id=' + encodeURIComponent(id))
        break;
      case 'magimix_cook_expert':
        ret = await axios.get('/recipe/remote/magimix_cook_expert/get.php?id=' + encodeURIComponent(id))
        break;
      case 'cookomix':
        ret = await axios.get('/recipe/remote/cookomix/get.php?id=' + encodeURIComponent(id))
        break;

      default:
        break;
    }
    return ret.data
  }

  async $getImagesFromURL(url) {
    return new Promise(async function(resolve, reject) {
      try {
        if(url) {
          const response = await axios.get('/recipe/parseForImages/get.php?url=' + encodeURIComponent(url))
          resolve(response.data.images)
        }

        resolve([])
      }
      catch(e) {
        reject()
      }

    })
  }

  async $share(communityCode) {
    return axios.post('/recipe/share/post.php', {
      id: this.id,
      communityCode: communityCode
    })
  }
}

export {
  Recipe,
  RecipeRepo
}
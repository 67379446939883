// Storage Model
import axios from 'axios'
import { Model, Repository } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'

class Storage extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'storages'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.string(''),
      nb_stacks: this.attr(0),
      location: this.string(''),
      type: this.string(''),
      color: this.string('')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      nb_stacks: NumberCast
    }
  }

  static allTypes() {
    const types = [
      { icon: 'mdi-snowflake-variant', text: 'Congélateur', pluralText: 'Congélateurs', value: 'freezer' },
      { icon: 'mdi-fridge-outline', text: 'Réfrigérateur', pluralText: 'Réfrigérateurs', value: 'fridge' },
      { icon: 'mdi-nutrition', text: 'Garde-manger', pluralText: 'Gardes-manger', value: 'panty' }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }
}

class StorageRepo extends Repository {
  use = Storage

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const storages = await axios.get('/storage/get.php')
        resolve(this.save(storages.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = await axios.get('/storage/get.php?id=' + id)
        resolve(this.save(storage.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getLatest(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = await axios.get('/storage/get.php?filter=latest')
        resolve(this.save(storage.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    if (!data) {
      data = {}
    }
    if (!data.name) {
      throw "Name is mandatory"
    }
    return new Promise(async (resolve, reject) => {
      try {
        const storage = await axios.post('/storage/post.php', data)
        resolve(this.save(storage.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = await axios.put('/storage/put.php?id=' + id, data)
        resolve(this.save(storage.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = await axios.delete('/storage/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  Storage,
  StorageRepo
}
<template>
  <div class="user">
    <div class="avatarContainer">
      <v-btn icon color="background" @click="prevAvatar">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="avatar">
        <img :src="`/avatar/${avatar}.png`">
      </div>
      <v-btn icon color="background" @click="nextAvatar">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <form>
      <v-text-field
        v-model="title"
        type="text"
        hide-details
        autocomplete="off"
        data-lpignore="true"
      />
      <v-text-field
        id="username"
        v-model="username"
        type="email"
        hide-details
        autocomplete="username"
      />
      <v-text-field
        id="password"
        v-model="oldPassword"
        name="password"
        label="Mot de passe actuel"
        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showOldPassword ? 'text' : 'password'"
        hide-details
        autocomplete="current-password"
        @click:append="showOldPassword = !showOldPassword"
      />
      <v-text-field
        v-model="newPassword"
        label="Nouveau mot de passe"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showNewPassword ? 'text' : 'password'"
        hide-details
        autocomplete="new-password"
        @click:append="showNewPassword = !showNewPassword"
      />
    </form>
    <div class="actionBar mt-4">
      <v-btn
        variant="text"
        :disabled="!isValidPassword"
        :loading="saving"
        @click="save"
      >
        Enregistrer
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useRepo } from "pinia-orm"
import { User, UserRepo } from "@/models/User"

export default {
  name: "UserCard",
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      id: this.user.id,
      title: this.user.name,
      username: this.user.email,
      avatar: this.user.avatar || "food-biscuit",
      avatars: [],
      oldPassword: "",
      showOldPassword: false,
      newPassword: "",
      showNewPassword: false,
      saving: false
    }
  },
  computed: {
    isValidPassword() {
      return (
        !this.newPassword ||
        (this.newPassword && this.oldPassword && this.newPassword !== this.oldPassword)
      )
    }
  },
  mounted: async function() {
    const avatars = await useRepo(UserRepo).$getAvatars()
    this.avatars = avatars
  },
  methods: {
    prevAvatar() {
      const currIndex = this.avatars.indexOf(this.avatar)
      if (currIndex > 0) {
        this.avatar = this.avatars[currIndex - 1]
      }
    },
    nextAvatar() {
      const currIndex = this.avatars.indexOf(this.avatar)
      if (currIndex > -1 && currIndex < this.avatars.length - 2) {
        this.avatar = this.avatars[currIndex + 1]
      }
    },
    save: async function() {
      this.saving = true
      const data = {
        name: this.title,
        email: this.username,
        avatar: this.avatar
      }
      if(this.newPassword && this.isValidPassword) {
        data.oldPassword = this.oldPassword
        data.password = this.newPassword
      }
      await useRepo(UserRepo).$update(this.id, data)
      const cU = User.getCurrentUser()
      this.oldPassword = ''
      this.newPassword = ''
      if(this.id === cU.id) {
        useRepo(UserRepo).$loadCurrentUser()
      }
      this.saving = false
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  background: rgb(var(--v-theme-background));
  border-radius: 3px;
  padding: $padding;
  margin-top: 76px;

  .avatarContainer {
    margin-top: -85px;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      padding: 2 * $padding;
      margin: 0 10px;
      border: 5px solid rgb(var(--v-theme-secondary));
      background: rgb(var(--v-theme-background));
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .actionBar {
    display: flex;
    justify-content: center;
  }
}
</style>
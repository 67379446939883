<template>
  <v-card rounded="lg" height="100%">
    <v-card-text class="pa-0">
      <v-row dense align="stretch">
        <v-col>
          <v-row dense justify="space-between" align="center">
            <v-col cols="auto">
              <p class="text-md-h5 text-h6 mb-0">
                {{ storeditem.name }}
              </p>
            </v-col>
          </v-row>
          <v-row dense justify="space-between" align="center">
            <v-col>
              <template v-if="nbDayBeforeExpired >= 15">
                <v-icon>
                  mdi-calendar
                </v-icon>
                {{ nbDayBeforeExpired }} jour(s) avant expiration
              </template>
              <template v-if="nbDayBeforeExpired > 0 && nbDayBeforeExpired < 15">
                <v-icon color="secondary">
                  mdi-calendar
                </v-icon>
                <span class="text-secondary text-subtitle-2">
                  {{ nbDayBeforeExpired }} jour(s) avant expiration
                </span>
              </template>
              <template v-if="nbDayBeforeExpired === 0">
                <v-icon color="secondary">
                  mdi-calendar-alert
                </v-icon>
                <span class="text-secondary font-weight-bold">
                  Expiration aujourd'hui
                </span>
              </template>
              <template v-if="nbDayBeforeExpired < 0">
                <v-icon color="accent">
                  mdi-calendar-alert
                </v-icon>
                <span class="text-accent font-weight-bold">
                  Expiré depuis {{ -nbDayBeforeExpired }} jour(s)
                </span>
              </template>
            </v-col>
            <v-col cols="auto">
              <v-chip v-if="storeditem.quantity_parts" :small="$vuetify.display.smAndDown">
                {{ storeditem.quantity_parts }} parts
                <template v-if="storeditem.quantity_volume">
                  ({{ storeditem.quantity_volume }}{{ quantity_unit }})
                </template>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="d-flex flex-column justify-space-around rightButtonsContainer pl-2 ml-2">
          <v-btn
            icon
            size="x-small"
            variant="flat"
            @click="edit"
          >
            <v-icon size="large">
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            icon
            size="x-small"
            variant="flat"
            @click="copy"
          >
            <v-icon size="large">
              mdi-content-copy
            </v-icon>
          </v-btn>
          <v-btn
            icon
            size="x-small"
            variant="flat"
            @click="remove"
          >
            <v-icon size="large">
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { useRepo } from 'pinia-orm'
import { StoredItem } from '@/models/StoredItem'

export default {
  name: "StoredItemCard",
  components: {

  },
  props: {
    storedItemId: {
      type: Number,
      default: 0
    },
  },
  emits: ['edit', 'remove', 'copy'],
  data: () => ({
  }),
  computed: {
    storeditem() {
      return useRepo(StoredItem).find(this.storedItemId)
    },
    nbDayBeforeExpired() {
      return moment(this.storeditem.expiration_date).diff(moment(), 'days')
    },
    quantity_unit() {
      return this.storeditem.quantity_unit
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    remove() {
      this.$emit('remove')
    },
    copy() {
      this.$emit('copy')
    }
  }
}
</script>

<style lang="scss" scoped>
.rightButtonsContainer {
  border-left: 1px solid silver;
}
</style>
<template>
  <v-offline
    @detected-condition="onNetworkChange"
  >
    <v-app
      v-if="autologged"
      :style="{background: 'rgb(var(--v-theme-background))'}"
    >
      <app-navigation v-if="isAuthenticated" />
      <v-main :class="mainClass">
        <router-view />
      </v-main>
      <app-bottom-navigation v-if="isAuthenticated && $vuetify.display.smAndDown" />
      <v-snackbar
        v-model="showUpdateNotif"
        :location="true && 'top'"
        color="success"
        :timeout="10000"
        multi-line
      >
        <div class="text-center">
          Une nouvelle version est disponible !
          <v-btn
            variant="elevated"
            color="white"
            class="mt-2"
            @click="refreshApp"
          >
            <v-icon size="small" class="mr-2">
              mdi-refresh
            </v-icon>
            Mettre à jour
          </v-btn>
        </div>
      </v-snackbar>
      <v-dialog v-model="confirm" max-width="550">
        <v-card>
          <v-card-title class="text-h5 bg-primary text-white">
            {{ confirmDef.title }}
          </v-card-title>

          <v-card-text>
            <v-container class="pl-0">
              <v-row no-gutters>
                <v-col v-if="confirmDef.text" cols="12" class="text--primary">
                  {{ confirmDef.text }}
                </v-col>
                <!-- eslint-disable-next-line -->
                <v-col v-if="confirmDef.html" cols="12" class="text--primary" v-html="confirmDef.html" />
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              variant="text"
              :disabled="confirmLoading"
              @click="confirmCancel"
            >
              {{ confirmDef.cancelText }}
            </v-btn>
            <v-btn
              color="primary"
              :loading="confirmLoading"
              @click="confirmValidate"
            >
              {{ confirmDef.validateText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </v-offline>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { UserRepo } from '@/models/User'
import { Globalconfig}  from '@/models/Globalconfig'
import { IngredientRepo } from '@/models/Ingredient'
import AppNavigation from '@/components/AppNavigation'
import AppBottomNavigation from '@/components/AppBottomNavigation'
import { VOffline } from 'v-offline'
import moment from 'moment'
import 'moment/dist/locale/fr'
import '@/assets/login.scss'
import { useToast } from 'vue-toastification'
import emitter from 'tiny-emitter/instance'
import { googleLogout } from "vue3-google-login"

export default {
  name: 'App',
  components: {
    AppNavigation,
    AppBottomNavigation,
    VOffline
  },
  data: () => ({
    autologged: false,

    confirmDefault: {
      title: '',
      text: '',
      html: '',
      cancelText: 'Annuler',
      validateText: 'Valider'
    },
    confirmDef: {},
    confirm: false,
    confirmLoading: false,

    showUpdateNotif: false,
    swCallback: null,
    refreshing: false,

    offlineToast: 0,
    onlineToast: 0
  }),
  computed: {
    isAuthenticated () {
      const config = useRepo(Globalconfig).find('isAuthenticated')
      if(config) {
        return config.value
      }
      else {
        return false
      }
    },
    mainClass () {
      let mClass = 'scrollbars'
      if(this.isAuthenticated) {
        mClass += ' hasTop'
        if(this.$vuetify.display.smAndDown) {
          mClass += ' hasBottom'
        }
      }
      return mClass
    }
  },
  created: async function () {
    moment.locale('fr')

    emitter.on('showConfirm', this.showConfirm)
    emitter.on('loggedIn', this.loggedIn)

    this.autologged = false
    try {
      await useRepo(UserRepo).autolog()
    }
    catch(e) {
      await useRepo(UserRepo).logout()
      try {
        await googleLogout()
      }catch(e){}
    }
    this.autologged = true

    // Bootstrap it
    useRepo(Globalconfig).save({
      name: 'isOffline',
      value: false
    })
  },
  mounted: async function() {
    // Service worker listeners to notify of update
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    navigator.serviceWorker && navigator.serviceWorker.addEventListener(
      'controllerchange',
      () => {
        if (this.refreshing) {
          return
        }
        this.refreshing = true
        window.location.reload()
      }
    );
  },
  methods: {
    toastOnline() {
      const toast = useToast()
      // Make sure to only have one toast
      toast.dismiss(this.offlineToast)
      toast.dismiss(this.onlineToast)
      this.onlineToast = toast('Vous êtes de retour !', {
        type: 'success',
        timeout: 3000,
        position: 'top-right',
        icon: {
          iconClass: 'mdi mdi-network-strength-3'
        }
      })
    },
    toastOffline() {
      const toast = useToast()
      // Make sure to only have one toast
      toast.dismiss(this.offlineToast)
      toast.dismiss(this.onlineToast)
      this.offlineToast = toast('Vous êtes hors ligne.', {
        type: 'error',
        timeout: false,
        position: 'top-right',
        icon: {
          iconClass: 'mdi mdi-network-strength-off'
        }
      })
    },
    onNetworkChange(online) {
      if (useRepo(Globalconfig).find('isOffline') === null) {
        // Not boostraped yet => ignore
        return
      }

      const oldVal = useRepo(Globalconfig).find('isOffline').value
      const newVal = !online

      if (oldVal != newVal) {
        useRepo(Globalconfig).save({
          name: 'isOffline',
          value: newVal
        })

        if (online) {
          this.toastOnline()
        }
        else {
          this.toastOffline()
        }
      }
    },
    showConfirm (confirmDef) {
      this.confirmDef = {
        ...this.confirmDefault,
        ...confirmDef
      }
      this.confirm = true
    },
    confirmCancel() {
      this.confirmDef = {...this.confirmDefault}
      this.confirm = false
    },
    async confirmValidate() {
      if(typeof this.confirmDef.validate === 'function') {
        this.confirmLoading = true
        await this.confirmDef.validate()
        this.confirmLoading = false
      }
      this.confirmCancel()
    },
    loggedIn: async function () {
      await useRepo(IngredientRepo).$loadUnits()
    },
    showRefreshUI (e) {
      this.swCallback = e.detail.callback
      this.showUpdateNotif = true
    },
    refreshApp () {
      if (!this.swCallback) {
        return
      }
      this.swCallback()
    }
  }
};
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
  background: rgb(var(--v-theme-background));
}
</style>
import axios from 'axios'
import { useRepo } from 'pinia-orm';
import { UserRepo } from "@/models/User"
import { Community } from '@/models/Community'
import { useToast } from 'vue-toastification'


export default function setup(router) {
  // Add a request interceptor
  axios.interceptors.request.use(async function (config) {
    const community = Community.getCurrentCommunity()
    if(community) {
      config.headers['X-Community-Id'] = community.id
    }

    return config;
  });

  axios.interceptors.response.use(undefined, function (err) {
    const error = err.response
    if (error && error.status === 401) {
      useRepo(UserRepo).logout()
      if(router && router.app) {
        const toast = useToast()
        toast('Informations de connexion invalides.', {
          type: 'error'
        })
      }

    }

    return Promise.reject(err)
  })
}
<template>
  <v-container
    :class="'pContainer' + (elevation ? ' elevation-2 ' : '') + (mini ? ' mini ' : '') + (micro ? ' micro ' : '') + ' layout-' + layout"
  >
    <v-row
      dense
    >
      <v-col
        class="pIcon"
        :cols="layout === 'vertical' ? '12' : 'auto'"
        align-self="center"
      >
        <v-icon
          :size="!mini && !micro ? 'x-large' :'large'"
          color="primary"
        >
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col
        v-if="layout === 'horizontal'"
        cols="auto"
      >
        <v-row dense>
          <v-col class="pTitle">
            {{ title }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="pValue">
            {{ value }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="layout === 'vertical'"
      dense
    >
      <v-col
        class="pTitle"
      >
        {{ title }}
      </v-col>
    </v-row>
    <v-row
      v-if="layout === 'vertical'"
      dense
    >
      <v-col
        class="pValue"
      >
        {{ value }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'PictoInfo',
  components: {
  },
  props: {
    icon: {
      type: String,
      default: 'Recette'
    },
    title: {
      type: String,
      default: 'Recette'
    },
    value: {
      type: String,
      default: 'Recette'
    },
    mini: {
      type: Boolean,
      default: false
    },
    micro: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'vertical'
    },
    elevation: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
  }),
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.pContainer {
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  letter-spacing: 0.1px;

  &.layout-vertical {
    width: 100px;
  }
  &.layout-horizontal {
    height: 100px;
    width: auto;
  }

  .pTitle {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pValue {
    color: rgb(var(--v-theme-primary));
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mini {
  &.pContainer {
    width: 75px;
  }

  .pTitle {
    font-size: 12px;
    line-height: 12px;
  }

  .pValue {
    font-size: 16px;
    line-height: 16px;
  }
}

.micro {
  &.pContainer {
    border-radius: 10px;

    &.layout-vertical {
      width: 50px;
    }
    &.layout-horizontal {
      height: 50px;
      width: auto;
    }
  }


  .pTitle {
    font-size: 10px;
    line-height: 10px;
  }

  .pValue {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
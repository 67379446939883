<template>
  <div class="calendar">
    <week-calendar />
  </div>
</template>

<script>
// @ is an alias to /src
import WeekCalendar from '@/components/WeekCalendar.vue'

export default {
  name: 'CalendarView',
  components: {
    WeekCalendar
  }
}
</script>

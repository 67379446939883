<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="centerPlace">
        <v-card elevation="24" class="rounded-xl">
          <v-toolbar color="primary" flat height="64">
            <v-toolbar-title>
              <v-avatar class="mr-2">
                <v-img :src="logo" />
              </v-avatar>
              Pâtes au jambon
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" align-self="center" class="text-center">
                <v-btn
                  color="white"
                  :disabled="!gInit"
                  @click="loginWithGoogle"
                >
                  <v-icon class="mr-2" :color="'#' + changingColor">
                    mdi-google
                  </v-icon>
                  Connexion avec Google
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-4">
              <v-col>
                <v-divider class="ml-3" />
              </v-col>
              <v-col cols="2" class="pa-0 text-center">
                <span class="mx-2">OU</span>
              </v-col>
              <v-col>
                <v-divider class="mr-3" />
              </v-col>
            </v-row>
            <v-alert
              v-if="specialMessage"
              border-color
              color="primary"
              border="start"
              elevation="2"
            >
              {{ specialMessage }}
            </v-alert>
            <v-row dense>
              <v-col cols="12">
                <v-form>
                  <v-text-field
                    id="username"
                    v-model="username"
                    label="Email"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                    autocomplete="username"
                    @keydown="keyCheck"
                  />

                  <v-text-field
                    id="password"
                    v-model="password"
                    label="Mot de passe"
                    name="password"
                    prepend-icon="mdi-lock"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    @keydown="keyCheck"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row dense class="text-center">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="connexionLoading"
                  @click="login"
                >
                  Se connecter
                </v-btn>
                <v-btn variant="text" size="small" to="/forgotten-password">
                  J'ai tout oublié...
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mx-2 my-5" />
            <v-row class="text-center">
              <v-col cols="12">
                Pas de compte ? C'est par ici :
                <v-btn variant="outlined" color="primary" to="/sign-up">
                  Je suis nouveau
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { UserRepo } from "@/models/User"
import shared from "@/shared"
import { googleAuthCodeLogin } from "vue3-google-login"
import logoImg from '@/assets/logo.png'

export default {
  name: "LoginView",
  beforeRouteEnter: function (to, from, next) {
    document.body.classList.add("login")
    next()
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove("login")
    next()
  },
  data: () => ({
    publicPath: import.meta.env.BASE_URL,
    username: "",
    password: "",
    specialMessage: "",
    messages: {
      validatedAccount:
        "Votre compte a été activé. Vous pouvez maintenant vous connecter.",
    },
    showPassword: false,
    changingColor: "",
    changingColorInterval: null,
    connexionLoading: false,
    gInit: false,
    logo: logoImg
  }),
  mounted() {
    const messageCode = shared.getUrlVars()["message"]
    if (messageCode) {
      this.specialMessage = this.messages[messageCode] || ""
    }

    // Blue, red, yellow, green
    const gColor = ["4285F4", "DB4437", "F4B400", "0F9D58"]
    this.changingColorInterval = setInterval(() => {
      const prevColorIndex = gColor.indexOf(this.changingColor)
      this.changingColor = !this.changingColor
        ? gColor[0]
        : gColor[(prevColorIndex + 1) % 4]
    }, 2000)

    this.waitForInit()
  },
  beforeUnmount() {
    clearInterval(this.changingColorInterval)
  },
  methods: {
    login: async function () {
      this.connexionLoading = true
      try {
        await useRepo(UserRepo).$login(this.username, this.password)
        this.$root.$emit("login")
        this.$router.push("/").catch((err) => {})
      } catch (e) {
        //debugger;
      }
      this.connexionLoading = false
    },
    loginWithGoogle: async function () {
      try {
        this.connexionLoading = true
        const authCode = await googleAuthCodeLogin()
        await useRepo(UserRepo).$loginWithGoogle(authCode)
        this.$root.$emit("login")
        this.$router.push("/").catch((err) => {})
      } catch (error) {
        //debugger;
        // On fail do something
      }
      this.connexionLoading = false
    },
    waitForInit() {
      if (!this.gInit) {
        this.gInit = true
      } else {
        setTimeout(() => {
          this.waitForInit()
        }, 1000)
      }
    },
    keyCheck(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.centerPlace {
  max-width: 500px;
}
</style>
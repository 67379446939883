<template>
  <v-card
    elevation="3"
    :loading="loadingRecipe || loading"
    :class="small ? 'small' : ''"
    @click="click"
  >
    <v-card-text :class="small ? 'pa-1' : 'pa-2'">
      <div v-if="addedToPrint" class="banner-container">
        <div class="banner">
          <v-icon color="white">
            mdi-printer-check
          </v-icon>
        </div>
      </div>
      <v-row
        dense
        class="flex-nowrap my-0"
      >
        <v-col
          cols="4"
          align-self="start"
        >
          <v-img
            :src="recipeToUse.image ? recipeToUse.image : defaultRecipeImage"
            aspect-ratio="1"
            cover
            width="100%"
            class="image align-end"
          >
            <v-row v-if="recipeToUse.score > 0" dense justify="center" class="score my-0">
              <v-col cols="auto" class="px-0">
                <v-icon size="x-small" color="white">
                  {{ recipeToUse.score >= 1 ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </v-col>
              <v-col cols="auto" class="px-1">
                <v-icon size="x-small" color="white">
                  {{ recipeToUse.score >= 2 ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </v-col>
              <v-col cols="auto" class="px-1">
                <v-icon size="x-small" color="white">
                  {{ recipeToUse.score >= 3 ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </v-col>
              <v-col cols="auto" class="px-0">
                <v-icon size="x-small" color="white">
                  {{ recipeToUse.score >= 4 ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
        <v-col
          cols="8"
          :class="small ? 'pl-1' : 'pl-2'"
        >
          <v-row
            dense
            class="pb-2 my-0"
          >
            <v-col
              class="name upperFirstLetter pa-0"
            >
              {{ recipeToUse.name }}
            </v-col>
          </v-row>
          <v-row
            v-if="description"
            dense
            class="my-0"
          >
            <v-col class="pa-0 description">
              {{ description }}
            </v-col>
          </v-row>
          <v-row
            v-if="!descriptionOnly"
            dense
            class="pb-2 my-0"
          >
            <v-col
              cols="auto"
              class="desc pa-0 text-caption"
            >
              {{ attributes.join(' - ') }}
            </v-col>
          </v-row>
          <v-row
            v-if="(recipeToUse.baking_time+recipeToUse.preparation_time)>0 || recipeToUse.nb_people>0"
            dense
            class="my-0 times"
          >
            <v-col
              class="pa-0 d-flex align-center"
            >
              <v-icon color="primary" :size="small ? 'small' : 'default'" class="mr-1">
                mdi-clock
              </v-icon>
              {{ formatTime(recipeToUse.baking_time+recipeToUse.preparation_time) }}
            </v-col>
            <v-col
              v-if="recipeToUse.nb_people>0"
              cols="auto"
              class="pa-0 d-flex align-center"
            >
              <v-icon color="primary" :size="small ? 'small' : 'default'">
                mdi-account-group
              </v-icon>
              <span class="px-2">
                {{ recipeToUse.nb_people }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Recipe, RecipeRepo } from "@/models/Recipe"
import { EntityToPrint } from "@/models/EntityToPrint"
import moment from 'moment'
import recipeImg from '@/assets/recipe.png'

export default {
  name: "RecipeCard",
  props: {
    recipe: {
      type: Object,
      default: null
    },
    description: {
      type: String,
      default: ''
    },
    descriptionOnly: {
      type: Boolean,
      default: false
    },
    recipeId: {
      type: Number,
      default: 0
    },
    scannedCode: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['clickedCard'],
  data: () => ({
    defaultRecipeImage: recipeImg,
    loadingRecipe: false,
    workingRecipeId: null
  }),
  computed: {
    dishTypes () {
      return Recipe.dishTypes()
    },
    cuisineTypes () {
      return Recipe.cuisineTypes()
    },
    recipeToUse () {
      return useRepo(Recipe).find(this.workingRecipeId) || this.recipe || {}
    },
    attributes () {
      const res = []
      if(this.recipeToUse.dish_type) {
        res.push(this.dishTypes.filter(m => m.value === this.recipeToUse.dish_type)[0].text)
      }
      if(this.recipeToUse.cuisine_type) {
        res.push(this.cuisineTypes.filter(m => m.value === this.recipeToUse.cuisine_type)[0].text)
      }
      return res
    },
    addedToPrint () {
      if(this.recipeId) {
        return useRepo(EntityToPrint).where('entity_id', this.recipeId).get().length > 0
      }
      else {
        return false
      }
    }
  },
  created() {
    if(this.recipeId) {
      this.workingRecipeId = this.recipeId
    }
    else if(this.scannedCode) {
      const recipeId = Recipe.getIdFromQRCode(this.scannedCode)
      this.workingRecipeId = recipeId
    }
  },
  mounted() {
    if(this.workingRecipeId) {
      const recipe = useRepo(Recipe).find(this.workingRecipeId)
      if(!recipe) {
        this.loadRecipe(this.workingRecipeId)
      }
    }
  },
  methods: {
    click () {
      this.$emit('clickedCard')
    },
    formatTime (minutes) {
      const d = moment.duration(minutes, 'minutes')
      let time = ''
      time +=  d.hours() === 0 ? '' : d.hours() + 'h'
      time += d.minutes() === 0 ? '' : (d.minutes() < 10 ? '0' + d.minutes() : d.minutes()) + 'm'
      return time
    },
    async loadRecipe(id) {
      this.loadingRecipe = true
      await useRepo(RecipeRepo).$get(id)
      this.loadingRecipe = false
    }
  }
}
</script>

<style lang="scss" scoped>
.name {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-primary));
}
.small .name {
  font-size: .9rem;
}

.image {
  border-radius: 10px;
  background: rgb(var(--v-theme-primary));
}
.desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small .desc {
  font-size: .5rem;
}

.score {
  background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
}

.description {
  white-space: pre-line;
}

.small .times {
  font-size: .7rem;
  line-height: .7rem;
}

.banner-container {
	width: 75px;
	height: 75px;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;

	&:after {
		content: "";
		display: block;
		width: 10px;
		height: 5px;
		background-color: rgb(var(--v-theme-primary-darken-1));
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	&:before {
		content: "";
		display: block;
		height: 10px;
		width: 5px;
		background-color: rgb(var(--v-theme-primary-darken-1));
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
	}
}

.banner {
	width: 100px;
	background-color: rgb(var(--v-theme-primary));
	color: #fff;
	text-align: center;
	transform: rotate(45deg) translate(9%, 0%);
	text-transform: uppercase;
}
</style>
<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <video ref="video" class="video" />
      </v-col>
    </v-row>
    <v-row v-if="loadingResult" dense>
      <v-col cols="12" class="text-center">
        <v-btn icon variant="outlined" loading />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        v-for="r in results"
        :key="r.type+'_'+r.id"
        cols="12"
        md="6"
        class="text-center"
      >
        <slot name="prepend-card" :item="r" />
        <print-card
          :recipe-id="r.type === 'recipe' ? r.id : undefined"
          :ingredient-id="r.type === 'ingredient' ? r.id : undefined"
          :entity-type="r.type"
          :custom-data="r.type === 'custom' ? {preset: r.id} : {}"
          :image-full-view="r.type === 'ingredient'"
          class="printCard"
          @clicked-card="select(r)"
        />
        <slot name="append-card" :item="r" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Recipe, RecipeRepo } from '@/models/Recipe'
import { Ingredient, IngredientRepo } from '@/models/Ingredient'
import PrintCard from '@/components/PrintCard'
import QrScanner from 'qr-scanner'
import { useRepo } from 'pinia-orm'

export default {
  name: "LiveScanner",
  components: {
    PrintCard
  },
  props: {
    activeScan: {
      type: Boolean,
      default: false
    },
  },
  emits: ['select'],
  data: () => ({
    loadingScan: false,
    debugtxt: "",
    scanner: "",
    scanning: false,
    results: [],
    resultMap: {},
    loadingResult: false
  }),
  watch: {
    activeScan(newVal) {
      if(newVal) {
        this.startScanning()
      }
      else {
        this.stopScanning()
      }
    },
  },
  mounted() {
    this.scanner = new QrScanner(
      this.$refs.video,
      result => {
        this.treatFoundQRCode(result)
      },
      {
        preferredCamera: 'environment',
        returnDetailedScanResult: true
      })
    this.startScanning()
  },
  beforeUnmount() {
    this.stopScanning()
  },
  methods: {
    async treatFoundQRCode(result) {
      if(result && !!result.data) {
        const rId = Recipe.getIdFromQRCode(result.data)
        const ingId = Ingredient.getIdFromQRCode(result.data)
        const sId = ['restaurant', 'leftover'].indexOf(result.data) > -1 ? result.data : null
        if(rId) {
          if(!this.resultMap['r_'+rId]) {
            this.loadingResult = true
            this.resultMap['r_'+rId] = true
            await useRepo(RecipeRepo).$get(rId)
            this.results.unshift({
              type: 'recipe',
              id: rId
            })
            this.loadingResult = false
          }
        }
        else if(ingId) {
          if(!this.resultMap['i_'+ingId]) {
            this.loadingResult = true
            this.resultMap['i_'+ingId] = true
            await useRepo(IngredientRepo).$get(ingId)
            this.results.unshift({
              type: 'ingredient',
              id: ingId
            })
            this.loadingResult = false
          }
        }
        else if (sId) {
          if(!this.resultMap[sId]) {
            this.resultMap[sId] = true
            this.results.unshift({
              type: 'custom',
              id: sId
            })
          }
        }
      }
    },
    startScanning() {
      if(!this.scanning && this.scanner) {
        this.scanner.start()
        this.scanning = true
      }
    },
    stopScanning() {
      if(this.scanning && this.scanner) {
        this.scanner.stop()
        this.scanner.destroy()
        this.scanner = null
      }
      this.scanning = false
    },
    select(item) {
      this.$emit('select', item)
    }
  },
}
</script>

<style lang="scss" scoped>
.video {
  width: 70%;
  aspect-ratio: 1;
  object-fit: cover;
}
.printCard {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
</style>
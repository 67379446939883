// UserCommunity Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'
import { User } from '@/models/User'
import axios from 'axios'
import { Community } from '@/models/Community'

class UserCommunity extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'usercommunities'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null).notNullable(),
      user_id: this.attr(null).notNullable(),
      user: this.belongsTo(User, 'user_id'),
      community_id: this.attr(null).notNullable(),
      community: this.belongsTo(Community, 'community_id'),
      is_admin: this.attr('false')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      user_id: NumberCast,
      community_id: NumberCast,
      is_admin: BooleanCast
    }
  }
}

class UserCommunityRepo extends Repository {
  use = UserCommunity

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const ucs = await axios.get('/usercommunity/get.php')
        resolve(this.save(ucs.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const uc = await axios.get('/usercommunity/get.php?id=' + id)
        resolve(this.save(uc.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/usercommunity/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  UserCommunity,
  UserCommunityRepo
}
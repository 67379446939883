<template>
  <v-list-item
    @click.stop="showCal = true"
  >
    <template #prepend>
      <v-icon size="large" color="primary">
        mdi-calendar-clock
      </v-icon>
    </template>
    Planifier
  </v-list-item>

  <v-dialog v-model="showCal" width="450">
    <v-card>
      <v-card-title class="text-h5 bg-primary text-white">
        Ajouter un rappel dans un calendrier
      </v-card-title>

      <v-card-text>
        <v-btn variant="text" target="_blank" :href="calendarUrl('google')">
          <v-icon class="mr-2">
            mdi-google
          </v-icon>
          Google
        </v-btn>

        <br />

        <v-btn variant="text" target="_blank" :href="calendarUrl('microsoft')">
          <v-icon class="mr-2">
            mdi-microsoft
          </v-icon>
          Microsoft live
        </v-btn>

        <br />

        <v-btn variant="text" target="_blank" :href="calendarUrl('office365')">
          <v-icon class="mr-2">
            mdi-microsoft-office
          </v-icon>
          Outlook Office365
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          variant="text"
          @click="showCal = false"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ReminderButton",
  components: {
  },
  props: {
    title: {
      type: String,
      default: 'Repas'
    },
    details: {
      type: String,
      default: 'Pâtes au jambon'
    },
    location: {
      type: String,
      default: ''
    },
    start: {
      type: Date,
      default: null
    },
    end: {
      type: Date,
      default: null
    },
  },
  data: () => ({
    showCal: false,
    calendars: {
      google: {
        url: 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false',
        parameters (title, location, details, start, end) {
          const parameters = {
            text: title,
            location: location,
            details: details
          };

          if (start && end) {
            parameters.dates = `${start}/${end}`;
          }

          return parameters;
        }
      },

      microsoft: {
        url: 'https://outlook.live.com/owa/?rru=addevent',
        parameters (title, location, details, start, end) {
          return {
            subject: title,
            location: location,
            body: details,
            startdt: start,
            enddt: end
          };
        }
      },

      office365: {
        url: 'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent',
        parameters (title, location, details, start, end) {
          return {
            subject: title,
            location: location,
            body: details,
            startdt: start,
            enddt: end
          };
        }
      }
    }
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    calendarUrl (calendar) {
      let url = this.calendars[calendar].url;
      const parameters = this.calendars[calendar].parameters(
        this.formatString(this.title),
        this.formatString(this.location),
        this.formatString(this.details),
        this.formatDate(this.start),
        this.formatDate(this.end)
      );

      for (const key in parameters) {
        if (parameters.hasOwnProperty(key) && parameters[key]) {
          url += `&${key}=${parameters[key]}`;
        }
      }

      return url;
    },

    formatString (string) {
      return encodeURIComponent(string).replace(/%20/g, '+');
    },

    formatDate (date) {
      return date ? date.toISOString().replace(/-|:|\.\d+/g, '') : null;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <v-container fluid class="groceryList">
    <edit-grocery
      :key="itemDialogId"
      :item-id="itemId"
      :show="showNewDialog"
      :store="selectedStore"
      @close="closeDialog"
    />

    <div class="summary mb-3">
      <v-row dense justify="center" align="center">
        <v-col
          :class="bagItems.length || boxItems.length ? 'groceryCount text-right title pr-3' : 'groceryCount text-center title pr-2'"
          cols="auto"
        >
          A prendre :
          <VDropdown
            placement="bottom"
            class="inlineTooltip"
            auto-hide
          >
            <v-chip>
              {{ nbGroceriesToGet }}
            </v-chip>
            <template #popper>
              <div class="denseTooltipContainer scrollbars">
                <div v-for="item in groceriesToGet" :key="item.id">
                  {{ item.summary() }}
                </div>
              </div>
            </template>
          </VDropdown>
        </v-col>
        <v-col v-if="bagItems.length>0 || boxItems.length>0" class="text-left pr-2" cols="auto">
          <VDropdown
            v-if="bagItems.length>0"
            placement="bottom"
            class="inlineTooltip"
            auto-hide
          >
            <v-chip
              pill
              title="Sacs nécessaires"
              class="mr-2"
            >
              <v-avatar start color="secondary">
                <v-icon>
                  mdi-shopping
                </v-icon>
              </v-avatar>
              {{ bagItems.length }}
            </v-chip>
            <template #popper>
              <div class="denseTooltipContainer">
                <div
                  v-for="item in bagItems"
                  :key="item.id"
                >
                  {{ item.summary() }}
                </div>
              </div>
            </template>
          </VDropdown>

          <VDropdown
            v-if="boxItems.length>0"
            placement="bottom"
            class="inlineTooltip"
          >
            <v-chip
              pill
              title="Sacs nécessaires"
            >
              <v-avatar start color="secondary">
                <v-icon>
                  mdi-basket-fill
                </v-icon>
              </v-avatar>
              {{ boxItems.length }}
            </v-chip>
            <template #popper>
              <div class="denseTooltipContainer">
                <div
                  v-for="item in boxItems"
                  :key="item.id"
                >
                  {{ item.summary() }}
                </div>
              </div>
            </template>
          </VDropdown>
        </v-col>
        <v-col cols="12" md="auto" class="pr-2 d-flex align-center justify-center">
          <VDropdown
            trigger="click"
            :hide-on-target-click="false"
            class="filter mr-2"
            container="#app"
            auto-hide
          >
            <template #popper>
              <v-container>
                <v-row dense class="filterContent">
                  <v-col class="text-center">
                    <v-btn
                      variant="outlined"
                      @click="filter === 'prefer_organic' ? filter = '' : filter = 'prefer_organic'"
                    >
                      <v-icon class="mr-2">
                        {{ filter === 'prefer_organic' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      Uniquement le bio
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense class="filterContent">
                  <v-col class="text-center">
                    <v-btn
                      variant="outlined"
                      @click="filter === 'prefer_bulk' ? filter = '' : filter = 'prefer_bulk'"
                    >
                      <v-icon class="mr-2">
                        {{ filter === 'prefer_bulk' ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      Uniquement le vrac
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <v-badge
              :model-value="filter != ''"
              dot
              bordered
              color="primary"
            >
              <v-btn
                size="x-small"
                variant="outlined"
                icon
                title="Filtrer"
                color="secondary"
              >
                <v-icon color="secondary" size="x-large">
                  mdi-filter-variant
                </v-icon>
              </v-btn>
            </v-badge>
          </VDropdown>

          Magasin :
          <v-select
            v-model="selectedStore"
            :items="stores"
            item-title="name"
            return-object
            variant="outlined"
            density="compact"
            hide-details
            class="storeSelector ml-2"
          >
            <template #no-data>
              <span class="text-caption mx-3">Demandez à un admin de rajouter un magasin</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>

    <v-row v-if="loading">
      <v-col class="text-h5 text-center loadingText py-12">
        Chargement
      </v-col>
    </v-row>
    <template v-if="!loading">
      <v-row class="mb-12" :dense="$vuetify.display.xs">
        <template v-for="section in groceriesBySection">
          <v-col
            v-if="section.groceries.length > 0 && section.groceries.length > 0"
            :key="section.value"
            class="my-sm-3 my-2 d-flex align-center"
            cols="12"
          >
            <span class="pr-1">
              <span class="ingredientType mr-1">{{ section.pluralText || section.text }}</span>
              <span v-if="section.groceries.filter(g => !g.gathered).length > 0" class="ingredientTypeLeftover">
                A prendre : {{ section.groceries.filter(g => !g.gathered).length }}/{{ section.groceries.length }}
              </span>
              <span v-else class="ingredientTypeLeftover">
                Tout est pris
                <v-icon color="secondary" size="x-small">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </span>
            <v-spacer />
            <v-btn
              icon
              size="small"
              class="collapseBtn"
              variant="text"
              @click="collapsed[section.value] = !collapsed[section.value]"
            >
              <v-icon size="x-large">
                {{ collapsed[section.value] ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
              </v-icon>
            </v-btn>
          </v-col>
          <template
            v-for="item in section.groceries"
            :key="item.id"
          >
            <v-col
              cols="12"
              md="6"
              xl="4"
              :class="'grocery ' + (collapsed[section.value] ? 'collapsed' : '')"
            >
              <grocery-card :key="'card'+item.id" :item="item" @edit="editGroceryItem" />
            </v-col>
          </template>
        </template>
      </v-row>
    </template>
    <v-btn
      icon
      size="x-small"
      color="success"
      class="text-white mb-16 mb-md-3 refreshBtn"
      title="Actualiser la liste"
      :loading="loadingRefresh"
      :disabled="isOffline"
      @click="refresh"
    >
      <v-icon size="large">
        mdi-refresh
      </v-icon>
    </v-btn>
    <v-btn
      icon
      size="x-small"
      color="accent"
      class="text-white mb-16 mb-md-3 newList"
      title="Nouvelle liste"
      :disabled="isOffline || nbGroceriesTotal === 0"
      @click="confirmNewList = true"
    >
      <v-icon size="large">
        mdi-playlist-plus
      </v-icon>
    </v-btn>
    <v-btn
      icon
      color="primary"
      class="text-white mb-16 mb-md-3 newItem"
      title="Ajouter un élément"
      :disabled="isOffline"
      @click="addGroceryItem()"
    >
      <v-icon size="x-large">
        mdi-plus
      </v-icon>
    </v-btn>

    <v-dialog v-model="confirmNewList" max-width="500">
      <v-card>
        <v-card-title class="text-h5 bg-primary text-white">
          Confirmation de création de liste
        </v-card-title>

        <v-card-text>
          <v-container class="pl-0">
            <v-row no-gutters dense class="my-0">
              <v-col class="text--primary">
                <h4>
                  Vous allez archiver la liste actuelle et créer une nouvelle liste.
                </h4>
              </v-col>
            </v-row>
          </v-container>
          <p v-if="nbGroceriesToGet > 0">
            Voulez-vous reporter les {{ nbGroceriesToGet }} items non pris dans la nouvelle liste ?
          </p>
          <div class="ma-0">
            <v-btn
              size="x-small"
              variant="text"
              class="transferGroceriesAllBtn"
              @click="transferGroceriesSelect"
            >
              <v-icon>
                {{ transferGroceriesSelector }}
              </v-icon>
              <i>
                Tout sélectionner
              </i>
            </v-btn>
          </div>
          <div class="transferList scrollbars">
            <v-checkbox
              v-for="g in groceriesToGet"
              :key="g.id"
              v-model="transferGroceries[g.id]"
              :label="g.name"
              hide-details
              density="compact"
              class="ma-0"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            variant="text"
            :disabled="loadingNewList"
            @click="confirmNewList = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingNewList"
            @click="newList"
          >
            Nouvelle liste
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Grocery, GroceryRepo } from "@/models/Grocery"
import { GroceryList, GroceryListRepo } from "@/models/GroceryList"
import { GroceryMealRepo } from "@/models/GroceryMeal"
import { Ingredient, IngredientRepo } from "@/models/Ingredient"
import { MealRepo } from "@/models/Meal"
import { Community } from '@/models/Community'
import { Store, StoreRepo } from '@/models/Store'
import { CompositionRepo } from "@/models/Composition"
import GroceryCard from "@/components/GroceryCard"
import EditGrocery from "@/components/EditGrocery"
import moment from 'moment'

export default {
  name: "CurrentGroceryList",
  components: {
    GroceryCard,
    EditGrocery
  },
  data: () => ({
    loading: false,
    loadingRefresh: false,

    showNewDialog: false,
    itemDialogId: 0,
    itemId: 0,

    confirmNewList: false,
    loadingNewList: false,
    includeUncollectedClear: false,
    filter: '',
    collapsed: {},
    hookIdUpdate: null,
    hookIdInsert: null,
    hookIdDelete: null,
    transferGroceries: {},
    selectedStore: null
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    transferGroceriesSelector () {
      const checked = Object.keys(this.transferGroceries).filter(a => this.transferGroceries[a])
      if(checked.length === this.nbGroceriesToGet) {
        return 'mdi-checkbox-marked-outline'
      }
      if(checked.length === 0) {
        return 'mdi-checkbox-blank-outline'
      }
      return 'mdi-minus-box-outline'
    },
    latestList() {
      // Bug in orderby on dates: switching to timestamp to sort on numbers
      const list = useRepo(GroceryList).orderBy(gl => gl.created.getTime(), 'desc').limit(1).get()
      if(list.length === 1) {
        return list[0]
      }
      return null
    },
    gatheredNb() {
      if(!this.latestList) {
        return 0
      }
      return useRepo(Grocery)
        .where("grocerylist_id", this.latestList.id)
        .where("gathered", true)
        .with("ingredient")
        .with("grocerymeal")
        .orderBy("name", "asc")
        .get().length
    },
    bagItems() {
      if(!this.latestList) {
        return []
      }
      const groceriesWithBags = useRepo(Grocery)
        .where("grocerylist_id", this.latestList.id)
        .where("gathered", false)
        .with("ingredient", query => {
          query.where("need_bag", true)
        })
        .get()
      return groceriesWithBags.filter(grocery => grocery.ingredient !== null)
    },
    boxItems() {
      if(!this.latestList) {
        return []
      }
      const groceriesWithBoxes = useRepo(Grocery)
        .where("grocerylist_id", this.latestList.id)
        .where("gathered", false)
        .with("ingredient", query => {
          query.where("need_box", true)
        })
        .get()
      return groceriesWithBoxes.filter(grocery => grocery.ingredient !== null)
    },
    groceriesToGet() {
      if(!this.latestList) {
        return []
      }
      return this.groceriesBySection.reduce((prev, section) => {
        return prev.concat(section.groceries.filter(item => !item.gathered))
      }, [])
    },
    nbGroceriesToGet() {
      return this.groceriesToGet.length
    },
    nbGroceriesTotal() {
      if(!this.latestList) {
        return 0
      }
      return useRepo(Grocery)
          .where("grocerylist_id", this.latestList.id)
          .get().length
    },
    gatheredOrder() {
      return this.nbGroceriesToGet + this.groceriesBySection.length + 1
    },
    groceriesBySection() {
      if(!this.latestList) {
        return []
      }
      let sections
      if(this.selectedStore) {
        sections = [...this.selectedStore.sections]
      }
      else {
        sections = Ingredient.allTypes()
      }
      let query = useRepo(Grocery)
          .where('grocerylist_id', this.latestList.id)
          .with("ingredient")
          .with("grocerymeal")
          .orderBy("name", "asc")
      let allGroceries = query.get()

      // Applying user filters
      if(this.filter === 'prefer_organic') {
        allGroceries =  allGroceries.filter((g) => {
          return !g.ingredient || g.ingredient.prefer_organic
        })
      }
      if(this.filter === 'prefer_bulk') {
        allGroceries = allGroceries.filter((g) => {
          return !g.ingredient || g.ingredient.prefer_bulk
        })
      }

      // Sorting by type of gorcery
      const found = {}
      sections.map(t => {
        t.groceries = allGroceries.filter(g => {
          if (g.section === t.value || (g.section === null && g.ingredient && g.ingredient.type === t.value)) {
            found[g.id] = true
            return true
          }
        })
      })
      // Getting all groceries with ingredient with no type
      const otherGroceries = allGroceries.filter(g => {
        return !found[g.id]
      })
      sections.push({
        text: "Autre",
        pluralText: "Autres",
        value: "_",
        groceries: otherGroceries
      })

      return sections
    },
    stores() {
      const c = Community.getCurrentCommunity()
      if(c) {
        return useRepo(Store)
                .where('community_id', c.id)
                .get()
      }

      return []
    },
    defaultStore() {
      const c = Community.getCurrentCommunity()
      if(c) {
        const s = useRepo(Store)
                .where('community_id', c.id)
                .where('default_store', true)
                .get()
        if(s.length) {
          return s[0]
        }
        else if(this.stores.length) {
          return this.stores[0]
        }
      }

      return null
    }
  },
  created() {
    const storedFilter = localStorage.getItem(this.activeFilterStorageKey)
    if(storedFilter) {
      const foundIt = this.filters.filter(f => f.value === storedFilter)
      if(foundIt && foundIt.length > 0) {
        this.activeFilter = foundIt[0]
      }
    }

    // Init to get reactivity
    this.collapsed = {
      '_': false,
      ...Ingredient.allTypes().reduce((prev, curr) => {
        prev[curr.value] = false
        return prev
      }, {})
    }

    useRepo(Grocery).piniaStore().$onAction(({
      name, // name of the action
      store, // store instance, same as `someStore`
      args, // array of parameters passed to the action
      after, // hook after the action returns or resolves
      onError, // hook if the action throws or rejects
    }) => {
      if(['delete', 'insert', 'update', 'save'].indexOf(name) > -1) {
        after((result) => {
          if(name === 'update') {
            if(result.gathered) {
              this.updateCollapses()
            }
          }
          else {
            this.updateCollapses()
          }
        })
      }
    })
  },
  async mounted() {
    if(!this.activeFilter) {
      this.activeFilter = this.defaultFilter
    }
    if(!this.isOffline) {
      this.loading = true
      await Promise.all([
        useRepo(IngredientRepo).$fetchForGrocery(),
        useRepo(MealRepo).$fetchForGrocery(),
        useRepo(CompositionRepo).$fetchForGrocery(),
        useRepo(GroceryRepo).$fetch(),
        useRepo(GroceryListRepo).$getLatest(),
        useRepo(GroceryMealRepo).$fetch(),
        useRepo(StoreRepo).$fetch()
      ])
      this.loading = false

      if (this.stores.length > 0) {
        if (this.defaultStore) {
          this.selectedStore = this.defaultStore
        }
        else {
          this.selectedStore = null
        }
      }
    }
  },
  methods: {
    refresh: async function() {
      this.loadingRefresh = true
      await Promise.all([
        useRepo(IngredientRepo).$fetchForGrocery(),
        useRepo(MealRepo).$fetchForGrocery(),
        useRepo(GroceryRepo).$fetch(),
        useRepo(GroceryMealRepo).$fetch()
      ])
      this.loadingRefresh = false
    },
    newList: async function() {
      this.loadingNewList = true
      const checked = Object.keys(this.transferGroceries).filter(a => this.transferGroceries[a])
      const response = await useRepo(GroceryListRepo).$create({
        name: 'Liste du '+moment().format('DD-MM-Y'),
        start_date: moment().format('Y-MM-DD'),
        end_date: moment().format('Y-MM-DD'),
        transferIds: checked
      })
      if(checked.length > 0) {
        await useRepo(GroceryRepo).$fetchByList(response.id)
      }
      this.transferGroceries = {}
      this.confirmNewList = false
      this.loadingNewList = false
    },
    addGroceryItem() {
      this.itemDialogId = Date.now()
      this.itemId = 0
      this.showNewDialog = true
    },
    editGroceryItem(id) {
      this.itemDialogId = 'grocery_'+id
      this.itemId = id
      this.showNewDialog = true
    },
    closeDialog() {
      this.showNewDialog = false
    },
    updateCollapses() {
      // Check is all groceries from all type and collapse what needs to be
      this.groceriesBySection.map((t) => {
        if(t.groceries.filter(g => !g.gathered).length === 0) {
          this.collapsed[t.value] = true
        }
        else {
          this.collapsed[t.value] = false
        }
      })
    },
    transferGroceriesSelect() {
      const checked = Object.keys(this.transferGroceries).filter(a => this.transferGroceries[a])
      if(checked.length === this.nbGroceriesToGet) {
        this.transferGroceries = {}
      }
      else {
        this.transferGroceries = this.groceriesToGet.reduce((p, c) => {
          p[c.id] = true
          return p
        }, {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary {
  text-align: center;
  font-weight: 500;
}

.groceryCount > * {
  display: inline-block;
}

.inlineTooltip {
  display: inline-block;
}
.denseTooltipContainer {
  max-height: 300px;
  font-size: 14px;
  line-height: 1.2em;
  text-align: left;
  overflow: auto;
  padding: 4px;
}

.refreshBtn {
  margin-right: 104px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.newList {
  margin-right: 64px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.newItem {
  position: fixed;
  bottom: 0;
  right: 8px;
}

.ingredientType {
  border-bottom: 5px solid rgb(var(--v-theme-secondary));
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(var(--v-theme-primary));

}

.ingredientTypeLeftover {
  font-size: .7em;
  font-weight: bold;
  color: rgb(var(--v-theme-secondary));
}

.filter {
  display: inline-block;
}
.filterContent {
  max-width: 90vw;
}

.hidden {
  display: none;
}

.grocery {
  max-height: 500px;
  transition: padding 0.3s ease, max-height 0.3s ease, opacity 0.3s ease;
}
.collapseBtn {
  display: inline-block;
}
.collapsed {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}

.transferList {
  max-height: 250px;
  overflow: auto;
}
.transferGroceriesAllBtn {
  padding-left: 2px !important;
}

.storeSelector {
  display: inline-block;
  max-width: 150px;
  vertical-align: middle;
}
</style>
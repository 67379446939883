<template>
  <v-dialog :model-value="show" :fullscreen="$vuetify.display.xs" width="600" scrollable>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="text-h5 bg-primary text-white d-flex justify-space-between align-center">
        Item de courses
        <v-spacer v-if="$vuetify.display.xs" />
        <span v-if="$vuetify.display.xs">
          <v-btn
            icon
            variant="text"
            size="small"
            @click="cancel"
          >
            <v-icon size="x-large">
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            icon
            variant="text"
            size="small"
            @click="upsert"
          >
            <v-icon size="x-large">
              mdi-check-circle
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="pb-3">
        <v-row class="my-0">
          <v-col>
            <v-text-field
              ref="search"
              v-model="name"
              name="name"
              label="Nom"
              single-line
              variant="outlined"
              hide-details
              class="mb-2"
              :clearable="!hasLinkedCompos"
              :readonly="hasLinkedCompos"
              @update:model-value="searchIngredient"
              @click:clear="clear"
            />
            <p v-if="searchLoading" class="loadingText">
              Recherche d'ingrédient
            </p>
            <p v-if="name && !searchLoading && searchResults.length === 0 && !selectedIngredient">
              Aucun ingrédient correspondant
            </p>
            <p v-if="!!selectedIngredient">
              Ingrédient sélectionné :
              <v-chip
                :closable="!hasLinkedCompos"
                @click:close="setNoIngredient"
              >
                <v-avatar v-if="selectedIngredient.need_bag || selectedIngredient.need_box" start>
                  <v-icon>
                    {{ selectedIngredient ? (selectedIngredient.need_bag ? 'mdi-shopping' : (selectedIngredient.need_box ? 'mdi-basket-fill' : '')) : '' }}
                  </v-icon>
                </v-avatar>
                {{ selectedIngredient.name }}
              </v-chip>
            </p>
            <div
              v-if="(!selectedIngredient || (selectedIngredient && searchResultsFound)) && !searchLoading && searchResults.length"
              class="scrollbars searchResults"
            >
              <span v-if="searchResultsFound">
                Alternatives :
              </span>
              <span v-else>
                {{ searchResults.length }} ingrédient(s) trouvé(s)
              </span>
              <template v-for="ing in searchResults">
                <v-chip
                  v-if="!searchResultsFound || searchResultsFound.id != ing.id"
                  :key="ing.id"
                  class="mr-1 mb-1"
                  @click="setIngredient(ing)"
                >
                  {{ ing.name }}
                </v-chip>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="4" align-self="center">
            <span class="text-h6">Quantité</span>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="amount"
              name="amount"
              type="number"
              variant="outlined"
              hide-details
              min="0"
            />
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="unit"
              :items="ingredientUnits"
              item-title="text"
              item-value="value"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" align-self="center">
            <span class="text-h6">Section</span>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedSection"
              :items="sections"
              item-title="text"
              item-value="value"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="$vuetify.display.smAndUp">
        <v-spacer />
        <v-btn
          variant="text"
          @click="cancel"
        >
          Annuler
        </v-btn>
        <v-btn
          :disabled="!canSave"
          color="primary"
          :loading="createLoading"
          @click="upsert"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash"
import { useRepo } from "pinia-orm"
import { Grocery, GroceryRepo } from "@/models/Grocery"
import { Ingredient, IngredientRepo } from "@/models/Ingredient"
import { GroceryMeal } from "@/models/GroceryMeal"
import { useToast } from 'vue-toastification'

export default {
  name: "EditGrocery",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object,
      default: ()=>{}
    },
    itemId: {
      type: Number,
      default: 0
    }
  },
  emits: ['close'],
  data: () => ({
    search: false,
    searchLoading: false,
    searchResults: [],
    searchResultsFound: null,
    loading: false,
    createLoading: false,

    editedItem: {},
    hasLinkedCompos: false,

    selectedIngredient: null,
    unit: 'nb',
    amount: 1,
    name: '',
    selectedSection: ''
  }),
  computed: {
    ingredientUnits() {
      return Ingredient.units()
    },
    canSave() {
      return !!this.name || this.selectedIngredient
    },
    sections() {
      if(this.store && this.store.sections) {
        return this.store.sections
      }

      return Ingredient.allTypes()
    }
  },
  async mounted() {
    setTimeout(() => this.$refs.search && this.$refs.search.focus(), 100)

    if(this.itemId) {
      this.loading = true
      await useRepo(GroceryRepo).$get(this.itemId)
      this.editedItem = useRepo(Grocery).find(this.itemId)
      if(this.editedItem.ingredient_id) {
        await useRepo(IngredientRepo).$get(this.editedItem.ingredient_id)
        this.selectedIngredient = useRepo(Ingredient).find(this.editedItem.ingredient_id)
        this.selectedSection = this.selectedIngredient.type

        this.hasLinkedCompos = useRepo(GroceryMeal)
          .where("grocery_id", this.itemId)
          .get().length > 1
      }
      this.name = this.editedItem.name
      this.unit = this.editedItem.unit
      this.amount = this.editedItem.amount
      if (this.editedItem.section) {
        // section might be overridden by grocery
        this.selectedSection = this.editedItem.section
      }
      this.loading = false
    }
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return true
    },
    searchIngredient: _.debounce(async function () {
      if(this.name && !this.selectedIngredient && !this.loading) {
        this.searchLoading = true
        try {
          const results = await useRepo(IngredientRepo).$search(this.name)
          // Use raw data since not stored
          if(results.data) {
            if(results.data.matches && results.data.matches.length > 0) {
              this.searchResults = results.data.matches
            }
            else {
              this.searchResults = []
            }
            if(results.data.matches && results.data.exactmatches.length === 1) {
              this.searchResultsFound = results.data.exactmatches[0]
              this.presetIngredient(this.searchResultsFound)
            }
            else {
              this.searchResultsFound = null
            }
          }
          else {
            this.searchResults = []
          }
        }
        catch(e) {
          const toast = useToast()
          toast('Le garde-manger semble fermé pour l\'instant...', {
            type: 'error'
          })
        }
        this.searchLoading = false
      }
      else {
        this.searchResults = []
      }
    }, 500),
    setIngredient(ingredient) {
      this.presetIngredient(ingredient)
      // No going back
      this.searchResults = []
    },
    presetIngredient(ingredient) {
      this.selectedIngredient = ingredient
      this.name = ingredient.name
      this.amount = ingredient.default_amount
      this.unit = ingredient.default_unit
      const existingSection = this.sections.filter((s) => s.value === ingredient.type)

      if(existingSection.length === 1) {
        this.selectedSection = existingSection[0].value
      }
    },
    setNoIngredient() {
      this.selectedIngredient = null
      this.searchResults = []
    },
    clear () {
      this.searchResults = []
      this.selectedIngredient = null
      this.name = ''
    },
    upsert: async function() {
      if(this.itemId) {
        await this.save()
      }
      else {
        await this.create()
      }
    },
    create: async function() {
      const data = {
        name: this.name,
        amount: this.amount,
        unit: this.unit,
        section: this.selectedSection
      }
      if(this.selectedIngredient) {
        data.ingredient_id = this.selectedIngredient.id
      }
      this.createLoading = true
      await useRepo(GroceryRepo).$create(data)
      this.createLoading = false
      this.$emit('close')
    },
    save: async function() {
      const data = {
        name: this.name,
        amount: this.amount,
        unit: this.unit,
        section: this.selectedSection
      }
      if(this.selectedIngredient) {
        data.ingredient_id = this.selectedIngredient.id
      }
      this.createLoading = true
      await useRepo(GroceryRepo).$update(this.itemId, data)
      this.createLoading = false
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResults {
  max-height: 200px;
  overflow: auto;
}
</style>
<template>
  <v-card>
    <v-card-title class="bg-secondary text-white">
      <span class="text-h5">
        {{ title }}
      </span>
    </v-card-title>

    <v-card-text>
      <v-dialog
        :id="imageEditionId"
        v-model="dialogImageEdition"
        width="960"
        persistent
        scrollable
        :fullscreen="$vuetify.display.smAndDown"
      >
        <v-card max-height="80vh" min-height="80vh">
          <v-card-title class="bg-primary text-h5 text-white">
            Image de "{{ localRecipe.name ? localRecipe.name : '-' }}"
          </v-card-title>

          <v-card-text
            class="pa-0"
            height="600"
          >
            <v-tabs
              v-model="imageTab"
              fixed-tabs
              class="mb-2"
            >
              <v-tab>
                Trouver dans la recette
              </v-tab>
              <v-tab>
                Saisir une URL
              </v-tab>
            </v-tabs>
            <v-window
              v-model="imageTab"
              class="pa-5"
            >
              <v-window-item
                v-if="localRecipe.source"
              >
                <v-row>
                  <v-col class="text-center">
                    <v-alert
                      v-if="errorOccurred"
                      variant="tonal"
                      color="error"
                      class="mb-2"
                    >
                      Une erreur s'est produite lors de la récupération des images. Veuillez ré-essayer ultérieurement.
                    </v-alert>
                    <v-btn
                      :loading="loadingImages"
                      @click="getImagesFromSource"
                    >
                      <v-icon>
                        mdi-image-search-outline
                      </v-icon>
                      Trouver les images
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  justify="space-around"
                >
                  <v-col
                    v-for="image in detectedImages"
                    :key="image.id"
                    cols="auto"
                  >
                    <v-img
                      :src="image.src"
                      :class="'detectedImage ' + (image.selected ? 'selected' : '')"
                      width="200"
                      aspect-ratio="1"
                      @click="selectImage(image)"
                    >
                      <span
                        v-if="image.alt"
                        class="alt"
                        :title="image.alt"
                      >{{ image.alt }}</span>
                    </v-img>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item
                v-if="!localRecipe.source"
              >
                <v-row>
                  <v-col>
                    <v-alert
                      type="info"
                      border="top"
                      elevation="2"
                      border-color
                      color="accent"
                    >
                      La source (URL du site) de la recette doit être remplie pour pouvoir en extraire une image.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="tmpImageURL"
                      variant="outlined"
                      label="URL de l'image"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="tmpImageURL"
                  justify="center"
                >
                  <v-col cols="auto">
                    <v-img
                      :src="tmpImageURL"
                      aspect-ratio="1"
                      width="200"
                      class="detectedImage"
                    />
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn color="primary" variant="text" @click="dialogImageEdition = false">
              Annuler
            </v-btn>
            <v-btn color="primary" variant="text" @click="saveImage">
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container fluid>
        <v-row dense class="my-0">
          <v-col cols="auto" align-self="center" class="ma-5 mt-0">
            <v-img
              :src="localRecipe.image ? localRecipe.image : defaultRecipeImage"
              height="150px"
              width="150px"
              class="image"
              cover
              @click="openImageEdition"
            />
          </v-col>
          <v-col grow>
            <v-row dense class="my-0">
              <v-col grow>
                <v-text-field
                  v-model="localRecipe.name"
                  label="Nom"
                />
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="localRecipe.nb_people"
                  type="number"
                  min="1"
                  prepend-inner-icon="mdi-account-group"
                  class="nb_people"
                />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col>
                <v-text-field
                  v-model="localRecipe.source"
                  label="Source (URL du site)"
                  variant="outlined"
                  hide-details
                />
              </v-col>
              <v-col v-if="localRecipe.source" cols="auto" align-self="center">
                <v-btn
                  icon
                  :href="localRecipe.source"
                  target="_blank"
                  variant="flat"
                >
                  <v-icon>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col>
            <p class="sectionTitle">
              Description
            </p>
          </v-col>
        </v-row>
        <v-row dense class="my-0" justify="space-around">
          <v-col cols="12" sm="5">
            <v-row dense class="my-0">
              <v-col cols="12">
                <v-select
                  v-model="localRecipe.dish_type"
                  :items="dishTypes"
                  item-title="text"
                  item-value="value"
                  label="Type de plat"
                  clearable
                  variant="outlined"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="localRecipe.cuisine_type"
                  :items="cuisineTypes"
                  item-title="text"
                  item-value="value"
                  label="Type de cuisine"
                  clearable
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
            <v-row class="my-0" dense>
              <v-col cols="12">
                <v-row class="my-0">
                  <v-col cols="4">
                    Préparation :
                  </v-col>
                  <v-col class="pr-1">
                    <v-slider
                      v-model="preparation_time_hours"
                      hide-details
                      thumb-label="always"
                      :thumb-size="20"
                      step="1"
                      min="0"
                      max="10"
                      show-ticks
                    />
                  </v-col>
                  <v-col cols="auto" class="pl-0">
                    h
                  </v-col>
                  <v-col class="pr-1">
                    <v-slider
                      v-model="preparation_time_minutes"
                      hide-details
                      thumb-label="always"
                      :thumb-size="20"
                      step="1"
                      min="0"
                      max="55"
                      show-ticks
                    />
                  </v-col>
                  <v-col cols="auto" class="pl-0">
                    min
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row class="my-0">
                  <v-col cols="4">
                    Cuisson :
                  </v-col>
                  <v-col class="pr-1">
                    <v-slider
                      v-model="baking_time_hours"
                      hide-details
                      thumb-label="always"
                      :thumb-size="20"
                      step="1"
                      min="0"
                      max="10"
                      show-ticks
                    />
                  </v-col>
                  <v-col cols="auto" class="pl-0">
                    h
                  </v-col>
                  <v-col class="pr-1">
                    <v-slider
                      v-model="baking_time_minutes"
                      hide-details
                      thumb-label="always"
                      :thumb-size="20"
                      step="1"
                      min="0"
                      max="55"
                      show-ticks
                    />
                  </v-col>
                  <v-col cols="auto" class="pl-0">
                    min
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="localRecipe.baking_type"
                  density="compact"
                  hide-details
                  :items="bakingTypes"
                  item-title="text"
                  item-value="value"
                  label="Type de cuisson"
                  clearable
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="ingredients px-10" cols="12" md="6">
            <p class="sectionTitle mx-n8">
              Ingrédients
            </p>
            <v-row
              v-if="localRecipe.ingredients.length > 0"
              class="ingredientList my-0"
              dense
            >
              <v-col>
                <v-card>
                  <v-card-text id="ingredientContainer" class="ingredientContainer">
                    <v-row
                      v-for="(ingredient, index) in localRecipe.ingredients"
                      :key="ingredient.id"
                      dense
                      class="my-0"
                    >
                      <v-col cols="auto">
                        <v-text-field
                          v-model="ingredient.amount"
                          min="0"
                          type="number"
                          label="Quantité"
                          hide-details
                          class="amount"
                          variant="underlined"
                        />
                      </v-col>
                      <v-col grow>
                        <v-select
                          v-model="ingredient.unit"
                          :items="ingredientUnits"
                          item-title="text"
                          item-value="value"
                          label="Unité"
                          hide-details
                          class="unit"
                          variant="underlined"
                        />
                      </v-col>
                      <v-col grow class="text-center" align-self="center">
                        <h3>
                          {{ ingredient.ingredient ? ingredient.ingredient.name : '' }}
                        </h3>
                      </v-col>
                      <v-col cols="auto" align-self="center">
                        <VDropdown
                          trigger="click"
                          :auto-hide="true"
                          :hide-on-target-click="false"
                          :dispose-timeout="1000"
                          container="#ingredientContainer"
                          @apply-show="focusInput('original-'+index)"
                        >
                          <template #popper>
                            <v-text-field
                              :id="'original-'+index"
                              v-model="ingredient.original"
                              hide-details
                              class="ma-0 originalInput theme--dark"
                              density="compact"
                              single-line
                              placeholder="Aucun détail sur l'ingrédient"
                            />
                          </template>
                          <v-btn
                            icon
                            variant="flat"
                            size="small"
                          >
                            <v-icon
                              :color="ingredient.original ? 'secondary' : ''"
                              size="x-large"
                            >
                              mdi-information-outline
                            </v-icon>
                          </v-btn>
                        </VDropdown>
                      </v-col>
                      <v-col cols="auto" align-self="center">
                        <v-btn
                          size="small"
                          icon
                          variant="flat"
                          @click="removeIngredient(ingredient)"
                        >
                          <v-icon size="x-large">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense class="my-0">
              <v-col>
                <v-autocomplete
                  ref="search"
                  v-model="selectedIngredient"
                  v-model:search="searchIngredient"
                  :loading="searchLoading"
                  :items="searchResults"
                  item-value="id"
                  item-title="name"
                  hide-details
                  label="Cherchez un ingrédient..."
                  variant="outlined"
                  single-line
                  clearable
                  return-object
                  no-data-text="Aucun ingrédient trouvé"
                  no-filter
                  @update:model-value="addIngredient"
                />
              </v-col>
            </v-row>
            <div class="choice">
              ou
            </div>
            <v-row dense class="my-0">
              <v-col grow>
                <v-textarea
                  v-model="pastedIngredients"
                  :loading="loadingIngredients"
                  label="Collez ici des ingrédients copiés depuis une recette en ligne"
                  variant="outlined"
                  density="compact"
                  :error-messages="pastedIngredientsError ? 'Impossible de comprendre les ingrédients restant. Vérifiez leur orthographe ou ajoutez-les manuellement.' : ''"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row dense class="my-0">
              <v-col>
                <v-btn
                  block
                  color="primary"
                  variant="flat"
                  :disabled="!pastedIngredients"
                  :loading="loadingParse"
                  @click="parseIngredients"
                >
                  Ajouter les ingrédients
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="px-10">
            <p class="sectionTitle mx-n8">
              Instructions
            </p>
            <v-row
              v-for="(instruction, index) in localRecipe.instructions"
              :key="index"
              dense
              class="my-0"
            >
              <v-col cols="auto" align-self="center">
                <v-btn
                  :disabled="index === 0"
                  icon
                  size="x-small"
                  variant="flat"
                  @click="moveInstructionUp(index)"
                >
                  <v-icon size="large">
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
                <v-chip
                  color="primary"
                  class="instructionIndex"
                  variant="elevated"
                >
                  {{ index+1 }}
                </v-chip>
                <v-btn
                  :disabled="index === localRecipe.instructions.length-1"
                  icon
                  size="x-small"
                  variant="flat"
                  @click="moveInstructionDown(index)"
                >
                  <v-icon size="large">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col grow>
                <v-card>
                  <v-card-text>
                    <v-row
                      dense
                      class="my-0"
                    >
                      <v-col>
                        <span v-if="instructionEdit !== index">
                          {{ instruction }}
                        </span>
                        <v-textarea
                          v-if="instructionEdit === index"
                          :model-value="instruction"
                          rows="1"
                          auto-grow
                          hide-details
                          class="pt-0"
                          variant="underlined"
                          @change="updateInstruction"
                          @update:model-value="instructionCheckKey"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        align-self="center"
                      >
                        <v-btn
                          v-if="instructionEdit !== index"
                          icon
                          size="x-small"
                          variant="flat"
                          @click="instructionEdit = index"
                        >
                          <v-icon size="large">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="instructionEdit !== index"
                          icon
                          size="x-small"
                          variant="flat"
                          @click="deleteInstruction(index)"
                        >
                          <v-icon size="large">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="instructionEdit === index"
                          icon
                          variant="flat"
                          size="small"
                          @click="instructionEdit = -1"
                        >
                          <v-icon size="x-large">
                            mdi-check-circle
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense class="my-0">
              <v-col cols="auto" align-self="center">
                <v-chip
                  color="primary"
                  variant="elevated"
                  class="ml-8 mr-8 instructionIndex"
                >
                  {{ localRecipe.instructions.length + 1 }}
                </v-chip>
              </v-col>
              <v-col grow>
                <v-text-field
                  v-model="tmpInstruction"
                  variant="outlined"
                  hide-details
                  clearable
                  placeholder="Saisissez une instruction..."
                  @keydown="newInstructionCheckKey"
                />
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn
                  icon
                  primary
                  variant="flat"
                  :disabled="!tmpInstruction"
                  @click="addInstruction"
                >
                  <v-icon size="x-large">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div class="choice">
              ou
            </div>
            <v-row dense class="my-0">
              <v-col grow>
                <v-textarea
                  v-model="pastedInstructions"
                  label="Collez ici des instructions copiées depuis une recette en ligne"
                  variant="outlined"
                  density="compact"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense class="my-0">
              <v-col>
                <v-btn
                  block
                  color="primary"
                  variant="flat"
                  :disabled="!pastedInstructions"
                  @click="parseInstructions"
                >
                  Ajouter les instructions
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col>
            <v-textarea
              v-model="localRecipe.comment"
              label="Commentaire"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="footer">
      <div class="flex-grow-1" />
      <v-btn color="primary" variant="text" @click="cancel">
        Annuler
      </v-btn>
      <v-btn color="primary" variant="text" @click="save">
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Recipe, RecipeRepo } from '@/models/Recipe'
import { Ingredient, IngredientRepo } from '@/models/Ingredient'
import { useToast } from 'vue-toastification'
import _ from 'lodash'
import recipeImg from '@/assets/recipe.png'

export default {
  name: 'RecipeEdition',
  components: {
  },
  props: {
    recipe: {
      type: Object,
      default: null
    },
    extraIngredients: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Recette'
    },
    creating: {
      type: Boolean,
      default: true
    }
  },
  emits: ['cancel', 'validate'],
  data: () => ({
    localRecipe: {},
    dialogImageEdition: false,
    imageEditionId: 1,
    imageTab: 0,
    tmpImageURL: '',
    detectedImages: [],
    loadingImages: false,

    preparation_time_hours: 0,
    preparation_time_minutes: 0,
    baking_time_hours: 0,
    baking_time_minutes: 0,
    selectedIngredient: null,
    searchLoading: false,
    searchIngredient: null,
    searchResults: [],
    loadingIngredients: false,
    pastedIngredients: '',
    loadingParse: false,
    pastedIngredientsError: false,
    pastedInstructions: '',
    tmpInstruction: '',
    instructionEdit: -1,

    defaultRecipeImage: recipeImg,
    errorOccurred: false
  }),
  computed: {
    dishTypes () {
      return Recipe.dishTypes()
    },
    cuisineTypes () {
      return Recipe.cuisineTypes()
    },
    bakingTypes () {
      return Recipe.bakingTypes()
    },
    ingredientUnits () {
      return Ingredient.units()
    }
  },
  watch: {
    searchIngredient: _.debounce(async function (val) {
      if(val) {
        this.searchLoading = true
        try {
          const results = await useRepo(IngredientRepo).$search(val)
          this.searchResults = results.data.matches
        }
        catch(e) {
          const toast = useToast()
          toast('Impossible de chercher parmis les ingrédients pour le moment.', {
            type: 'error'
          })
        }
        this.searchLoading = false
      }
    }, 500)
  },
  created () {
    this.localRecipe = {...this.recipe}

    if(this.localRecipe && this.localRecipe.baking_time) {
      this.baking_time_hours = Math.floor(this.localRecipe.baking_time/60)
      this.baking_time_minutes = Math.round(this.localRecipe.baking_time - this.baking_time_hours*60)
    }
    if(this.localRecipe && this.localRecipe.preparation_time) {
      this.preparation_time_hours = Math.floor(this.localRecipe.preparation_time/60)
      this.preparation_time_minutes = Math.round(this.localRecipe.preparation_time - this.preparation_time_hours*60)
    }
    if(!this.localRecipe.ingredients) {
      this.localRecipe.ingredients = []
    }
    if(!this.localRecipe.instructions) {
      this.localRecipe.instructions = []
    }
    if(this.extraIngredients) {
      this.pastedIngredients = this.extraIngredients
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      this.localRecipe.baking_time = this.baking_time_hours*60+this.baking_time_minutes
      this.localRecipe.preparation_time = this.preparation_time_hours*60+this.preparation_time_minutes
      if (!this.creating) {
        await useRepo(RecipeRepo).$update(this.localRecipe.id, {
          ...this.localRecipe
        })
      } else {
        await useRepo(RecipeRepo).$create({
          ...this.localRecipe
        })
      }
      this.$emit('validate')
    },
    addIngredient () {
      if(this.selectedIngredient && !this.insertedIng(this.selectedIngredient.id)) {
        const existing = this.localRecipe.ingredients.filter(ing => ing.ingredient_id === this.selectedIngredient.id).length > 0
        if(!existing) {
          this.localRecipe.ingredients.push({
            amount: this.selectedIngredient.default_amount * this.localRecipe.nb_people,
            unit: this.selectedIngredient.default_unit,
            ingredient_id: this.selectedIngredient.id,
            ingredient: this.selectedIngredient
          })
        }
      }
      this.searchIngredient = null
      this.selectedIngredient = null
    },
    removeIngredient(ingredient) {
      const ingredients = this.localRecipe.ingredients.filter(ing => ing.ingredient_id != ingredient.ingredient_id)
      this.localRecipe.ingredients = ingredients
    },
    insertedIng (id) {
      return this.localRecipe.ingredients.filter(i => i.ingredient.id === id).length > 0
    },
    parseIngredients:async function () {
      this.loadingParse = true
      const ingredients = await useRepo(IngredientRepo).$parse(this.pastedIngredients)
      if(ingredients.data.success.length > 0) {
        ingredients.data.success.map(i => {
          if(!this.insertedIng(i.ingredient.id)) {
            this.localRecipe.ingredients.push({
              amount: i.amount,
              unit: i.unit,
              ingredient_id: i.ingredient.id,
              ingredient: i.ingredient,
              original: i.original
            })
          }
          else {
            ingredients.data.fail.unshift(i.original)
          }
        })
      }
      if(ingredients.data.fail.length > 0 || ingredients.data.several.length > 0) {
        this.pastedIngredientsError = true
        this.pastedIngredients = ''
        if(ingredients.data.fail.length) {
          this.pastedIngredients += ingredients.data.failLabel + "\n"
          this.pastedIngredients += ingredients.data.fail.join("\n") + "\n"
        }
        if(ingredients.data.several.length) {
          this.pastedIngredients += ingredients.data.severalLabel + "\n"
          this.pastedIngredients += ingredients.data.several.join("\n") + "\n"
        }
      }
      else {
        this.pastedIngredientsError = false
        this.pastedIngredients = ''
      }
      this.loadingParse = false
    },
    parseInstructions () {
      const newInstructions = this.pastedInstructions.split('\n').filter(l => l.length > 0)
      this.localRecipe.instructions = this.localRecipe.instructions.concat(newInstructions)
      this.pastedInstructions = ''
    },
    addInstruction () {
      this.localRecipe.instructions.push(this.tmpInstruction)
      this.tmpInstruction = ''
    },
    newInstructionCheckKey (e) {
      if(e.keyCode === 13) {
        this.addInstruction()
      }
    },
    resetImageEdition() {
      this.tmpImageURL = ''
      this.detectedImages = []
    },
    openImageEdition () {
      this.resetImageEdition()
      this.dialogImageEdition = true
      this.imageEditionId = Date.now()
    },
    getImagesFromSource: async function () {
      this.loadingImages = true
      this.errorOccurred = false
      this.detectedImages = []
      try {
        const images = await useRepo(RecipeRepo).$getImagesFromURL(this.localRecipe.source)
        // Add ids to be able to sort by image size after it is loaded
        let id = 0
        let i = 0;
        const treatedImages = []
        for(i = 0; i < images.length; i++) {
          treatedImages.push({
            src: images[i].src,
            alt: images[i].alt,
            id: id++,
            selected: false
          })
        }
        this.detectedImages = treatedImages
      }
      catch(e) {
        this.errorOccurred = true
      }
      this.loadingImages = false
    },
    selectImage (image) {
      // First unselect all other image
      this.detectedImages.map(i => {
        i.selected = false
      })
      image.selected = true
    },
    saveImage () {
      switch(this.imageTab) {
        case 0:
          const selection = this.detectedImages.filter(i => i.selected)
          if(selection.length === 1) {
            this.localRecipe.image = selection[0].src
          }
          break
        case 1:
          if(this.tmpImageURL) {
            this.localRecipe.image = this.tmpImageURL
          }
          break
      }
      this.dialogImageEdition = false
    },
    updateInstruction (value) {
      if(this.instructionEdit > -1 && this.localRecipe.instructions && this.localRecipe.instructions[this.instructionEdit]) {
        this.localRecipe.instructions[this.instructionEdit] = value
      }
    },
    instructionCheckKey (value) {
      if(value && value.indexOf("\n") > 1) {
        this.localRecipe.instructions[this.instructionEdit] = value.replace(/\n|\r|\r\n/g, '')
        this.instructionEdit = -1
      }
    },
    deleteInstruction (index) {
      this.localRecipe.instructions.splice(index, 1)
    },
    moveInstructionUp (index) {
      if(index > 0) {
        const removed = this.localRecipe.instructions.splice(index, 1)
        this.localRecipe.instructions.splice(index-1, 0, removed[0])
      }
    },
    moveInstructionDown (index) {
      if(index < this.localRecipe.instructions.length-1) {
        const removed = this.localRecipe.instructions.splice(index, 1)
        this.localRecipe.instructions.splice(index+1, 0, removed[0])
      }
    },
    focusInput (id) {
      const input = document.getElementById(id)
      if(input) {
        setTimeout(()=>{
          input.focus()
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  border-radius: 5px;
  background-color: rgb(var(--v-theme-primary));
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
  }
}

.nb_people {
  width: 100px;

  :deep(input) {
    text-align: center;
  }
}

.ingredients {
  .ingredientContainer {
    padding: 8px 16px;

    .amount {
      max-width: 100px
    }
    .unit {
      max-width: 200px
    }
  }
}

.instructionIndex {
  min-width: 40px;
  justify-content: center;
}

.choice {
  overflow: hidden;
  text-align: center;
  margin: 10px 25%;
  text-transform: uppercase;
}

.choice:before,
.choice:after {
  background-color: rgba(0, 0, 0, .6);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.choice:before {
  right: 0.5em;
  margin-left: -50%;
}

.choice:after {
  left: 0.5em;
  margin-right: -50%;
}

.sectionTitle {
  text-align: center;
  font-size: 20px;
  color: rgb(var(--v-theme-accent));
  padding-bottom: 5px;
  border-bottom: 3px solid rgb(var(--v-theme-accent));
}

.footer {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
  z-index: 1;
}

.detectedImage {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  &.selected {
    border: 2px solid rgb(var(--v-theme-primary));
  }

  .alt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    height: 25px;
    color: #FFF;
    padding: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.originalInput {
  width: 300px;

  :deep(input) {
    caret-color: #fff;
  }

  :deep(.v-input__slot) {
    &:after {
      border-color: #fff !important;
    }
  }
}
</style>
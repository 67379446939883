// GroceryMeal Model
import { Model, Repository } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'
import { Grocery } from '@/models/Grocery'
import { Meal } from '@/models/Meal'
import axios from 'axios'

class GroceryMeal extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'grocerymeals'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(0).notNullable(),
      grocery_id: this.attr(null),
      grocery: this.belongsTo(Grocery, 'grocery_id'),
      meal_id: this.attr(null),
      meal: this.belongsTo(Meal, 'meal_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      grocery_id: NumberCast,
      meal_id: NumberCast
    }
  }
}

class GroceryMealRepo extends Repository {
  use = GroceryMeal

  async $fetch () {
    return new Promise(async (resolve, reject) => {
      try {
        const gms = await axios.get('/grocerymeal/get.php')
        resolve(this.save(gms.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchByList(listId) {
    return new Promise(async (resolve, reject) => {
      try {
        const gms = await axios.get('/grocerymeal/get.php?listId=' + encodeURIComponent(listId))
        resolve(this.save(gms.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get (id) {
    return new Promise(async (resolve, reject) => {
      try {
        const gm = await axios.get('/grocerymeal/get.php?id=' + id)
        resolve(this.save(gm.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete (id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/grocerymeal/delete.php?id=' + id,)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  GroceryMeal,
  GroceryMealRepo
}
// Grocery list Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast, DateCast } from 'pinia-orm/casts'
import moment from 'moment'
import { GroceryRepo } from './Grocery'
import axios from 'axios'

class GroceryList extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'groceryLists'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0).notNullable(),
      name: this.attr(''),
      nb_items: this.attr(0),
      created: this.attr(),
      start_date: this.attr(null),
      end_date: this.attr(null)
    }
  }

  static casts() {
    return {
      id: NumberCast,
      nb_items: NumberCast,
      created: DateCast,
      start_date: DateCast,
      end_date: DateCast
    }
  }

}

class GroceryListRepo extends Repository {
  use = GroceryList

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        const glists = await axios.get('/grocerylist/get.php')
        resolve(this.save(glists.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const glist = await axios.get('/grocerylist/get.php?id=' + id)
        resolve(this.save(glist.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getLatest(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const glist = await axios.get('/grocerylist/get.php?filter=latest')
        resolve(this.save(glist.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    if (!data) {
      data = {}
    }
    if (!data.name) {
      data.name = 'Liste du ' + moment().format('DD/MM/YY')
    }
    if (!data.start_date) {
      data.start_date = moment().format('Y-MM-DD')
    }
    let idsToTransfer = ''
    if (data.transferIds) {
      idsToTransfer = 'idsToTransfer=' + JSON.stringify(data.transferIds)
      delete data.transferIds
    }
    return new Promise(async (resolve, reject) => {
      try {
        const glist = await axios.post('/grocerylist/post.php?' + idsToTransfer, data)
        resolve(this.save(glist.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const glist = await axios.put('/grocerylist/put.php?id=' + id, data)
        resolve(this.save(glist.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const glist = await axios.delete('/grocerylist/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $newListFromMeals(startDate, endDate, create) {
    if (create) {
      const response = await this.$create({
        name: moment(startDate).format('DD/MM/YY') + '-' + moment(endDate).format('DD/MM/YY'),
        start_date: moment(startDate).format('Y-MM-DD'),
        end_date: moment(endDate).format('Y-MM-DD'),
      })
      await useRepo(GroceryRepo).$addFromMeals(startDate, endDate, response.id)
    }
    else {
      await useRepo(GroceryRepo).$addFromMeals(startDate, endDate, null)
    }
  }

  async $copyFromList(groceryId) {
    return axios.post('/grocerylist/copyFromList/post.php', {
      id: groceryId
    })
  }
}

export {
  GroceryList,
  GroceryListRepo
}
// EntityToPrint Model
import { Model } from 'pinia-orm'
import { NumberCast } from 'pinia-orm/casts'

class EntityToPrint extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'entitiestoprint'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.uid(),
      entity_id: this.attr(0),
      entity_data: this.attr({}),
      entity_type: this.string('')
    }
  }

  static casts() {
    return {
      entity_id: NumberCast
    }
  }
}

export {
  EntityToPrint
}
<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        xl="4"
      >
        <v-card elevation="24" class="rounded-xl">
          <v-toolbar
            color="primary"
            flat
          >
            <v-toolbar-title>
              <v-toolbar-title>
                <v-avatar class="mr-2">
                  <v-img
                    :src="logo"
                  />
                </v-avatar>
                Bienvenue parmi nous !
              </v-toolbar-title>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="!successMessage" class="pt-0">
            <template v-if="!gInfoOK">
              <v-row class="mt-2">
                <v-col cols="12" align-self="center" class="text-center">
                  <v-btn color="white" @click="signupWithGoogle">
                    <v-icon class="mr-2" :color="'#'+changingColor">
                      mdi-google
                    </v-icon>
                    Inscription avec Google
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-4">
                <v-col>
                  <v-divider class="ml-3" />
                </v-col>
                <v-col cols="2" class="pa-0 text-center">
                  <span class="mx-2">OU</span>
                </v-col>
                <v-col>
                  <v-divider class="mr-3" />
                </v-col>
              </v-row>
              <v-form ref="form">
                <v-text-field
                  v-model="username"
                  label="Nom"
                  prepend-icon="mdi-account"
                  type="text"
                  hide-details
                  autocomplete="off"
                  data-lpignore="true"
                  :rules="[rules.required]"
                />
                <v-text-field
                  id="username"
                  v-model="email"
                  label="Email"
                  name="username"
                  prepend-icon="mdi-at"
                  type="text"
                  hide-details
                  autocomplete="username"
                  :rules="[rules.required]"
                />
                <v-text-field
                  id="password"
                  v-model="password"
                  label="Mot de passe"
                  name="password"
                  hide-details
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="current-password"
                  :rules="[rules.required, rules.min]"
                  @click:append="showPassword = !showPassword"
                />
              </v-form>
            </template>
            <template v-else>
              <v-row justify="center" class="mt-2">
                <v-col cols="auto">
                  <v-icon size="x-large" color="success">
                    mdi-account-check-outline
                  </v-icon>
                  Informations Google OK
                </v-col>
              </v-row>
            </template>
            <v-divider class="my-4" />
            <v-form ref="communityForm">
              <div class="text-center text-h5 text--primary">
                Communauté
                <VDropdown
                  :triggers="['hover']"
                  class="inlineTooltip"
                >
                  <v-icon
                    size="16"
                    color="grey-lighten-1"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                  <template #popper>
                    <p class="text-caption communityDesc">
                      Vous devez obligatoirement <b>rejoindre</b> ou <b>créer</b> une communauté pour partager un même menu.
                      Pour en rejoindre une, demandez son code à l'un de ses membres.
                    </p>
                  </template>
                </VDropdown>
              </div>
              <v-tabs
                v-model="communityTab"
                fixed-tabs
              >
                <v-tab>
                  {{ ['xs', 'sm'].indexOf(currentBreakpoint) > -1 ? 'Rejoindre' : 'Rejoindre une communauté' }}
                </v-tab>
                <v-tab>
                  {{ ['xs', 'sm'].indexOf(currentBreakpoint) > -1 ? 'Créer' : 'Créer une communauté' }}
                </v-tab>
              </v-tabs>
              <v-window
                v-model="communityTab"
              >
                <v-window-item>
                  <v-text-field
                    id="communityCode"
                    v-model="communityCode"
                    label="Code de communauté"
                    name="communityCode"
                    prepend-icon="mdi-account-group"
                    type="text"
                  />
                </v-window-item>
                <v-window-item>
                  <v-text-field
                    id="communityName"
                    v-model="communityName"
                    label="Nom de la communauté"
                    name="communityName"
                    prepend-icon="mdi-account-multiple-plus"
                    type="text"
                  />
                </v-window-item>
              </v-window>
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                sitekey="6Lc9qsoUAAAAAEKXMmhEh4CIGnNO1xmQZ0O6bd4w"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
              />
            </v-form>
          </v-card-text>
          <v-card-actions v-if="!successMessage">
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  variant="outlined"
                  to="/login"
                  class="mr-2"
                >
                  Retour
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="submitting"
                  @click="signup"
                >
                  C'est parti !
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

          <v-card-text v-if="successMessage">
            <p>
              Vous allez recevoir un email de confirmation pour finaliser la création du compte
            </p>
          </v-card-text>
          <v-card-actions v-if="successMessage">
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  variant="outlined"
                  class="mb-2"
                  @click="resend"
                >
                  Renvoyer l'email
                </v-btn>
                <v-btn
                  color="primary"
                  to="/login"
                  class="ml-2 mb-2"
                >
                  Se connecter
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { UserRepo } from '@/models/User'
import { VueRecaptcha } from 'vue-recaptcha'
import { useToast } from 'vue-toastification'
import logoImg from '@/assets/logo.png'

export default {
  name: 'SignUpView',
  components: {
    VueRecaptcha
  },
  beforeRouteEnter: function(to, from, next) {
    document.body.classList.add('login')
    next()
  },
  beforeRouteLeave: function(to, from, next) {
    document.body.classList.remove('login')
    next()
  },
  data: () => ({
    logo: logoImg,
    username: '',
    email: '',
    password: '',
    communityTab: null,
    communityCode: '',
    communityName: '',
    gAuthCode: '',
    gInfoOK: false,

    showPassword: false,
    rules: {
      required: v => !!v || 'Obligatoire',
      min: v => v.length >= 8 || 'Min 8 charactères'
    },
    submitting : false,
    successMessage: false,

    changingColor: '',
    changingColorInterval: null
  }),
  computed: {
    currentBreakpoint () {
      const bps = ['xs', 'sm', 'md', 'lg', 'xl']
      const bp = bps.filter(b => this.$vuetify.display[b])
      return bp.length ? bp[0] : '';
    }
  },
  mounted() {
    // Blue, red, yellow, green
    const gColor = ['4285F4', 'DB4437', 'F4B400', '0F9D58']
    this.changingColorInterval = setInterval(() => {
      const prevColorIndex = gColor.indexOf(this.changingColor)
      this.changingColor = !this.changingColor ? gColor[0] : gColor[(prevColorIndex+1) % 4]
    }, 2000);
  },
  beforeUnmount() {
    clearInterval(this.changingColorInterval)
  },
  methods: {
    isValidForm () {
      return (this.gAuthCode || this.$refs.form.validate()) &&
             ((this.communityTab === 0 && this.communityCode) ||
              (this.communityTab === 1 && this.communityName))
    },
    signup: async function () {
      if(!this.isValidForm()) {
        const toast = useToast()
        toast("Le formulaire n'est pas correctement rempli.", {
          type: 'error'
        })
        return;
      }

      this.submitting = true
      this.$refs.recaptcha.execute()
    },
    onCaptchaVerified: async function(token) {
      this.$refs.recaptcha.reset()

      try {
        await useRepo(UserRepo).$create({
          name: this.username,
          email: this.email,
          password: this.password,
          communityCode: this.communityTab === 0 ? this.communityCode : '',
          communityName: this.communityTab === 1 ? this.communityName : '',
          recaptcha: token,
          gAuthCode: this.gAuthCode
        })
        this.successMessage = true
      }
      catch(e) {
        const toast = useToast()
        toast('Nous n\'avons pas pu créer cet utilisateur...', {
          type: 'error'
        })
      }
      this.submitting = false
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    resend () {
      if(this.email) {
        useRepo(UserRepo).$validate(this.email)
      }
    },
    async signupWithGoogle () {
      try {
        this.gAuthCode = await this.$gAuth.getAuthCode()
        this.gInfoOK = true
      } catch (error) {
        debugger
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.centerPlace {
  max-width: 500px;
}

.communityDesc {
  max-width: 300px;
  padding: 5px;
}

.inlineTooltip {
  display: inline-block;
}
</style>
<template>
  <v-app-bar color="primary navigationHeader">
    <v-avatar class="mx-5">
      <v-img
        :src="logo"
      />
    </v-avatar>
    <v-toolbar-title v-if="$vuetify.display.smAndDown" class="mx-0">
      <span>
        Pâtes au Jambon
      </span>
    </v-toolbar-title>
    <template v-if="$vuetify.display.mdAndUp">
      <v-btn variant="text" to="/calendar" class="mr-2">
        <v-icon class="mr-2">
          mdi-calendar
        </v-icon>
        Calendrier
      </v-btn>
      <v-btn variant="text" to="/groceries" class="mr-2">
        <v-icon class="mr-2">
          mdi-format-list-checkbox
        </v-icon>
        Courses
      </v-btn>
      <v-btn variant="text" to="/ingredients" class="mr-2">
        <v-icon class="mr-2">
          mdi-food-apple
        </v-icon>
        Ingrédients
      </v-btn>
      <v-btn variant="text" to="/recipes" class="mr-2">
        <v-icon class="mr-2">
          mdi-pasta
        </v-icon>
        Recettes
      </v-btn>
      <v-btn variant="text" to="/storage" class="mr-2">
        <v-icon class="mr-2">
          mdi-snowflake-variant
        </v-icon>
        Stockage
      </v-btn>
    </template>

    <v-spacer v-if="$vuetify.display.mdAndUp" />

    <v-menu
      v-if="$vuetify.display.smAndUp"
      open-on-hover
    >
      <template #activator="{ props }">
        <v-chip
          pill
          color="secondary"
          v-bind="props"
          variant="elevated"
          @click="navigateToUser"
        >
          <v-avatar start color="background">
            <v-img :src="currentUserAvatar" height="24px" aspect-ratio="1" eager />
          </v-avatar>
          <span class="username">
            {{ (currentUser ? currentUser.name : '') + (currentCommunity ? ' - '+currentCommunity.name : '') }}
          </span>
        </v-chip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in communities"
          :key="index"
          rounded="xl"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>

          <template #append>
            <v-btn
              icon
              variant="flat"
              @click="switchCommunity(item)"
            >
              <v-icon>
                mdi-account-switch
              </v-icon>
            </v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-if="$vuetify.display.smAndUp"
      icon
      title="Déconnexion"
      class="ml-1 mr-1"
      @click="logout"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>

    <v-menu
      v-if="$vuetify.display.xs"
    >
      <template #activator="{ props }">
        <v-btn
          icon
          color="background"
          size="small"
          v-bind="props"
          variant="flat"
        >
          <v-img
            :src="currentUserAvatar"
            height="24px"
            width="24px"
            aspect-ratio="1"
            cover
            eager
          />
        </v-btn>
      </template>

      <v-list>
        <v-btn variant="text" to="/users">
          Mon compte
        </v-btn>
        <v-btn variant="text" @click="logout">
          Déconnexion
        </v-btn>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item
          v-for="(item, index) in communities"
          :key="index"
        >
          <v-list-item-title @click="switchCommunity(item)">
            <v-icon>
              {{ currentCommunity && item.id === currentCommunity.id ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
            </v-icon>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { User, UserRepo } from '@/models/User'
import { Community } from '@/models/Community'
import { Globalconfig } from '@/models/Globalconfig'
import { googleLogout } from "vue3-google-login"
import logoImg from '@/assets/logo.png'

export default {
    name: 'AppNavigation',
    data: () => ({
      logo: logoImg
    }),
    computed: {
      currentUser () {
        return User.getCurrentUser()
      },
      currentUserAvatar () {
        if(this.currentUser) {
          const img = this.currentUser.avatar || 'food-biscuit'
          return '/avatar/'+img+'.png'
        }
        else {
          return '/avatar/food-biscuit.png'
        }
      },
      currentCommunity () {
        const config = useRepo(Globalconfig).find('currentCommunity')
        if(config) {
          return config.value
        }
        else {
          return null
        }
      },
      communities () {
        return useRepo(Community).all()
      }
    },
    methods: {
      async logout () {
        useRepo(UserRepo).logout()
        try {
          await googleLogout()
        }catch(e){}
      },
      navigateToUser () {
        this.$router.push('users')
      },
      switchCommunity(community) {
        Community.setCurrentCommunity(community.id)
        this.$router.go()
      }
    }
};
</script>

<style scoped lang="scss">
.navigationHeader {
    flex-grow: 0;
}
</style>
<template>
  <v-bottom-navigation grow bg-color="accent">
    <v-btn variant="text" to="/calendar" title="Calendrier" class="bottomBtn">
      <v-icon>
        mdi-calendar
      </v-icon>
    </v-btn>
    <v-btn variant="text" to="/groceries" title="Courses" class="bottomBtn">
      <v-icon>
        mdi-format-list-checkbox
      </v-icon>
    </v-btn>
    <v-btn variant="text" to="/ingredients" title="Ingrédients" class="bottomBtn">
      <v-icon>
        mdi-food-apple
      </v-icon>
    </v-btn>
    <v-btn variant="text" to="/recipes" title="Recettes" class="bottomBtn">
      <v-icon>
        mdi-pasta
      </v-icon>
    </v-btn>
    <v-btn variant="text" to="/storage" title="Stockage" class="bottomBtn">
      <v-icon>
        mdi-snowflake-variant
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="scss" scoped>
.bottomBtn {
  min-width: 60px !important;
}
</style>
<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        xl="4"
      >
        <v-card elevation="24" :loading="sending">
          <v-toolbar
            color="primary"
            flat
          >
            <v-toolbar-title>
              <v-toolbar-title>
                <v-avatar class="mr-2">
                  <v-img
                    :src="logo"
                  />
                </v-avatar>
                Encore bloqué ?
              </v-toolbar-title>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert
              v-if="displayConfirm"
              color="primary"
              border="start"
              border-color
              elevation="2"
            >
              Vous allez recevoir un email pour terminer la procédure de réinitialisation
            </v-alert>
            <v-form>
              <v-text-field
                id="username"
                v-model="username"
                label="Email"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                autocomplete="username"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-btn variant="text" to="/login">
              En fait c'est bon
            </v-btn>
            <v-btn variant="flat" color="primary" @click="renew">
              Débloquez-moi !
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { UserRepo } from '@/models/User'
import { useToast } from 'vue-toastification'
import logoImg from '@/assets/logo.png'

export default {
  name: 'ForgottenPwdView',
  beforeRouteEnter: function(to, from, next) {
    document.body.classList.add('login')
    next()
  },
  beforeRouteLeave: function(to, from, next) {
    document.body.classList.remove('login')
    next()
  },
  data: () => ({
    username: '',
    password: '',
    sending: false,
    displayConfirm: false,
    logo: logoImg
  }),
  methods: {
    renew: async function () {
      try {
        this.sending = true
        this.displayConfirm = false
        const newPwd = await useRepo(UserRepo).$forgottenPassword(this.username)
        this.sending = false
        this.displayConfirm = true
      }
      catch(e) {
        this.sending = false
        const toast = useToast()
        toast('Nous n\'avons pas pu trouver cet utilisateur...', {
          type: 'error'
        })
      }
    }
  }
}
</script>
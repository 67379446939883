<template>
  <v-dialog
    v-model="computedShow"
    :fullscreen="$vuetify.display.xs"
    width="600"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 bg-primary text-white d-flex align-center">
        Stockage
        <v-spacer v-if="$vuetify.display.xs" />
        <span v-if="$vuetify.display.xs">
          <v-btn
            icon
            :disabled="saving"
            variant="text"
            size="small"
            @click="cancel"
          >
            <v-icon size="large">
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            icon
            :loading="saving"
            variant="text"
            size="small"
            @click="handleValidation"
          >
            <v-icon size="large">
              mdi-check-circle
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="pb-3">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              v-model="type"
              :items="storageTypes"
              label="Type"
              item-title="text"
              item-value="value"
              variant="outlined"
              hide-details
              :prepend-inner-icon="typeIcon"
              @update:model-value="autofillName"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location"
              name="location"
              label="Emplacement"
              variant="outlined"
              hide-details
              clearable
              @change="autofillName"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col cols="auto">
            Nombre d'étages :
            <int-stepper
              v-model="nb_stacks"
              :min="1"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="name"
              name="name"
              label="Nom"
              variant="outlined"
              hide-details
              class="mb-2"
              clearable
              @click:clear="clear"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <VDropdown
              placement="top"
              :popper-triggers="['click']"
            >
              <v-btn
                :color="color"
                variant="elevated"
                :class="isColorDark ? 'text-white' : ''"
              >
                Choisir une couleur
                <v-icon class="ml-2" :color="isColorDark ? 'white' : 'black'">
                  mdi-palette
                </v-icon>
              </v-btn>
              <template #popper>
                <v-color-picker
                  v-model="color"
                  hide-canvas
                  hide-inputs
                  hide-sliders
                  show-swatches
                  swatches-max-height="200"
                  :elevation="0"
                />
              </template>
            </VDropdown>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="$vuetify.display.smAndUp">
        <v-spacer />
        <v-btn
          variant="text"
          :disabled="saving"
          @click="cancel"
        >
          Annuler
        </v-btn>
        <v-btn
          :disabled="!canSave"
          :loading="saving"
          color="primary"
          @click="handleValidation"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Storage, StorageRepo } from "@/models/Storage"
import IntStepper from "@/components/IntStepper"
import shared from '@/shared'

export default {
  name: "StorageEdition",
  components: {
    IntStepper
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    new: {
      type: Boolean,
      default: false
    },
    storageId: {
      type: Number,
      default: 0
    },
  },
  emits: ['close'],
  data: () => ({
    saving: false,
    name: '',
    type: '',
    nb_stacks: 1,
    location: '',
    color: '#FFFFFF',
    showColorPicker: false,
    allowCloseColorPicker: false
  }),
  computed: {
    storage() {
      return useRepo(Storage).find(this.storageId)
    },
    computedShow: {
      get() {
        return this.show
      },
      set(v) {
        if(!v) {
          this.$emit('close')
        }
      }
    },
    storageTypes() {
      return Storage.allTypes()
    },
    typeIcon() {
      if(this.type) {
        return this.storageTypes.filter(t => t.value === this.type)[0].icon
      }
      else {
        return 'mdi-help-circle-outline'
      }
    },
    canSave() {
      return !!this.name
    },
    isColorDark() {
      return shared.isHexDark(this.color)
    }
  },
  created() {
    if(this.storage) {
      this.name = this.storage.name
      this.nb_stacks = this.storage.nb_stacks
      this.type = this.storage.type
      this.color = this.storage.color
      this.location = this.storage.location
    }
  },
  methods: {
    clear () {
      this.name = ''
    },
    handleValidation: async function() {
      if(this.new) {
        await this.create()
      }
      else {
        await this.update()
      }
    },
    create: async function() {
      const data = {
        name: this.name,
        type: this.type,
        nb_stacks: this.nb_stacks,
        location: this.location,
        color: this.color,
      }
      await useRepo(StorageRepo).$create(data)
      this.$emit('close')
    },
    update: async function() {
      this.saving = true
      const data = {
        name: this.name,
        type: this.type,
        nb_stacks: this.nb_stacks,
        location: this.location,
        color: this.color
      }
      await useRepo(StorageRepo).$update(this.storageId, data)
      this.saving = false
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
    toggleColorPicker() {
      this.allowCloseColorPicker = false
      this.showColorPicker = !this.showColorPicker
    },
    autofillName() {
      if(this.new) {
        const typeLabel = this.type ? this.storageTypes.filter(s => s.value === this.type)[0].text : ''
        if(!this.name ||
          this.name === typeLabel ||
          this.name === typeLabel + ' ('+this.location+')'
          ) {
          this.name = typeLabel + (this.location ? ' ('+this.location+')' : '')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
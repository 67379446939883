<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="auto" align-self="center">
          <h4>Nom</h4>
        </v-col>
        <v-col>
          <v-text-field v-if="!readOnly" v-model="name" density="compact" />
          <p v-if="readOnly" class="communityName">
            {{ name }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Code à partager pour rejoindre la communauté</h4>
          <p>
            <b>{{ code }}</b> (généré le {{ code_date }})
            <v-btn v-if="!readOnly" variant="outlined" class="ml-2" @click="renewCode">
              Nouveau code
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>iCal URL</h4>
          <p>
            <template v-if="ical">
              <b>{{ icalUrl }}</b> (généré le {{ ical_date }})
            </template>
            <v-btn v-if="!readOnly" variant="outlined" class="ml-2" @click="renewICal">
              {{ ical_date ? 'Renouveler' : 'Générer' }}
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center">
          <h4>Nombre de personnes par défaut pour les repas</h4>
        </v-col>
        <v-col cols="auto">
          <v-icon class="pr-4">
            mdi-account-group
          </v-icon>
          <int-stepper v-model="default_nb_people" :min="1" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!readOnly" class="text-right">
      <v-btn variant="text" color="accent" @click="removeCommunity">
        Supprimer
      </v-btn>
      <v-btn variant="text" @click="saveCommunity">
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Community, CommunityRepo } from '@/models/Community'
import { UserCommunityRepo } from '@/models/UserCommunity'
import IntStepper from '@/components/IntStepper.vue'
import emitter from 'tiny-emitter/instance'

export default {
  name: "CommunityCard",
  components: [
    IntStepper
  ],
  components: { IntStepper },
  props: {
    community: {
      type: Object,
      default: () => { }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["deleted"],
  data() {
    return {
      id: this.community.id,
      name: this.community.name,
      code: this.community.code,
      code_date: this.community.code_date,
      default_nb_people: this.community.default_nb_people,
      ical: this.community.ical,
      ical_date: this.community.ical_date
    }
  },
  computed: {
    icalUrl() {
      return import.meta.env.VITE_SITE_API_ENDPOINT + '/community/ical/get.php?token=' + this.ical
    }
  },
  methods: {
    saveCommunity() {
      useRepo(CommunityRepo).$update(this.id, {
        name: this.name,
        default_nb_people: this.default_nb_people
      });
    },
    removeCommunity() {
      const that = this;
      emitter.emit("showConfirm", {
        title: "Suppression",
        text: "Voulez-vous supprimer la communauté \"" + that.name + "\" ?",
        validate: async function () {
          await useRepo(CommunityRepo).$delete(that.id);
          that.$emit("deleted");
          // Deleting a community will cascade on relations so we need to update
          useRepo(UserCommunityRepo).$fetch();
        }
      });
    },
    renewCode: async function () {
      await useRepo(CommunityRepo).$renewCode(this.id);
      const newCom = useRepo(Community).find(this.id);
      this.code = newCom.code;
      this.code_date = newCom.code_date;
    },
    renewICal: async function () {
      await useRepo(CommunityRepo).$renewICal(this.id);
      const newCom = useRepo(Community).find(this.id);
      this.ical = newCom.ical;
      this.ical_date = newCom.ical_date;
    }
  }
}

</script>

<style scoped lang="scss">
.communityName {
  font-size: 1.2em;
}
</style>
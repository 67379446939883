<template>
  <div
    class="recipe"
    @click="click"
  >
    <div class="imgWrapper">
      <img
        :src="entity.image ? entity.image : defaultRecipeImage"
        :class="'image ' + (imageFullView && entity.image ? 'fullView' : '')"
      />
    </div>
    <div class="contentWrapperShadow">
      <div class="contentWrapper">
        <span class="clipper" />
        <p class="recipeTitle">
          {{ entity.name }}
        </p>
        <div class="barcodeContainer">
          <barcode-render
            :key="entity.getDataForQRCode()+'_'+id"
            :data="entity.getDataForQRCode()"
            type="qrcode"
            :height="25"
            :width="25"
            class="barcode"
          />
        </div>
        <div class="seasons">
          <template v-for="(m, i) in entity.seasonnality()" :key="'m_'+i">
            <span class="month" :style="'order:'+i">
              {{ monthsShortText[i] }}
            </span>
            <v-icon :color="m === '1' ? 'secondary' : ''" size="small" :style="'order:'+(12+i)">
              {{ m === '1' ? 'mdi-check-circle-outline' : 'mdi-timer-sand' }}
            </v-icon>
          </template>
        </div>
        <div
          v-if="entityType === 'recipe'"
          :class="'infos ' + (!entity.dish_type ? 'maxHeight' : '')"
        >
          <picto-info
            icon="mdi-clock-outline"
            title="Préparation"
            :value="formatTime(entity.preparation_time)"
            :micro="true"
            :elevation="false"
            layout="horizontal"
            class="infoEl"
          />
          <picto-info
            icon="mdi-pot-steam-outline"
            title="Cuisson"
            :value="formatTime(entity.baking_time)"
            :micro="true"
            :elevation="false"
            layout="horizontal"
            class="infoEl"
          />
        </div>
        <div v-if="entity.dish_type" class="type">
          <span class="text">
            {{ entity.dishTypeLabel }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Recipe } from '@/models/Recipe'
import { Ingredient } from '@/models/Ingredient'
import moment from 'moment'
import BarcodeRender from '@/components/BarcodeRender'
import PictoInfo from '@/components/PictoInfo'
import shared from '@/shared'
import recipeImg from '@/assets/recipe.png'
import restaurantImg from '@/assets/restaurant.jpg'
import leftoverImg from '@/assets/leftover.jpg'

export default {
  name: 'PrintCard',
  components: {
    BarcodeRender,
    PictoInfo
  },
  props: {
    entityType: {
      type: String,
      default: ''
    },
    recipeId: {
      type: Number,
      default: 0
    },
    ingredientId: {
      type: [Number, String],
      default: 0
    },
    customData: {
      type: Object,
      default: () => {}
    },
    imageFullView: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clickedCard'],
  data: () => ({
    id: shared.getUid(),
    localCustomData: {},
    defaultRecipeImage: recipeImg,
    monthsShortText: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
  }),
  computed: {
    entity () {
      if(this.entityType === 'recipe') {
        return useRepo(Recipe).withAllRecursive().find(this.recipeId)
      }
      else if(this.entityType === 'ingredient') {
        if(typeof this.ingredientId === 'number') {
          return useRepo(Ingredient).find(this.ingredientId)
        }
        else {
          const ingList = useRepo(Ingredient).where('uid', this.ingredientId).get()
          if(ingList.length === 1) {
            return ingList[0]
          }
          else {
            return {}
          }
        }
      }
      else if(this.entityType === 'custom') {
        return this.localCustomData
      }

      return {}
    }
  },
  created () {
    this.localCustomData = this.customData

    if(this.localCustomData && this.localCustomData.preset) {
      switch (this.localCustomData.preset) {
        case 'restaurant':
          this.localCustomData.name = 'Restaurant'
          this.localCustomData.image= restaurantImg
          this.localCustomData.seasonnality = () => []
          this.localCustomData.getDataForQRCode = () => this.localCustomData.preset
          break;
        case 'leftover':
          this.localCustomData.name = 'Restes'
          this.localCustomData.image= leftoverImg
          this.localCustomData.seasonnality = () => []
          this.localCustomData.getDataForQRCode = () => this.localCustomData.preset
          break;

        default:
          break;
      }
    }
  },
  mounted () {
  },
  methods: {
    click () {
      this.$emit('clickedCard')
    },
    formatTime (minutes) {
      const d = moment.duration(minutes, 'minutes')
      let time = ''
      time +=  d.hours() === 0 ? '' : d.hours() + ' h '
      time += d.minutes() === 0 ? '' : (d.minutes() < 10 ? '0' + d.minutes() : d.minutes()) + ' m'
      return time || '-'
    },
  }
}
</script>

<style lang="scss" scoped>
.recipe {
  outline: 1px dashed silver;
  border-radius: .3cm;
  width: 9cm;
  height: 5cm;
  position: relative;
  float: left;
  overflow: hidden;

  .imgWrapper {
    width: 4cm;
    height: 5cm;
    border-top-left-radius: .3cm;
    border-bottom-left-radius: .3cm;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    overflow: hidden;

    .image {
      height: 5cm;
      margin-left: -25%;

      &.fullView {
        height: 3cm;
        margin-top: 2cm;
      }
    }
  }

  .contentWrapperShadow {
    filter: drop-shadow(0 0 5px rgba(50, 50, 0, 0.5));
  }

  .contentWrapper {
    height: 5cm;
    background-color: #FFFFFF;
    border-radius: .3cm;
    position: absolute;
    left: 0;
    right: 0;
    //box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 40% 100%);

    .clipper {
      width: 40%;
      height: 100%;
      float: left;
      -webkit-shape-outside: polygon(0 0, 10% 0, 100% 100%, 0 100%);
      shape-outside: polygon(0 0, 10% 0, 100% 100%, 0 100%);
    }

    .recipeTitle {
      text-align: center;
      font-weight: 300;
      font-size: 12pt;
      line-height: 1.25rem;
      text-transform: uppercase;
      padding-right: 2.6cm;
      padding-top: .2cm;
      margin-bottom: 0;
      max-height: 2.3cm;
      text-overflow: ellipsis;
      overflow: clip;
    }

    .barcodeContainer {
      position: absolute;
      top: .1cm;
      right: .3cm;
      border-radius: .4cm;
      background-color: #FFFFFF;
      height: 2cm;
      width: 2cm;
      text-align: center;
      display: flex;

      .barcode {
        margin: auto;
      }
    }

    .seasons {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 20px 20px;
      justify-items: center;
      position: absolute;
      top: 2.1cm;
      right: .2cm;
      left: 30%;

      .month {
        font-weight: 300;
      }
    }

    .infos {
      position: absolute;
      left: 40%;
      right: .2cm;
      bottom: .5cm;
      height: 1.3cm;
      display: flex;
      justify-content: space-between;

      &.maxHeight {
        bottom: 0;
        height: 1.5cm;
      }

      .infoEl {
        display: inline-block;
      }
    }

    .type {
      position: absolute;
      left: 40%;
      right: 0;
      bottom: 0;
      height: .5cm;
      display: flex;
      justify-content: center;

      .text {
        padding: 0 .2cm;
        text-transform: uppercase;
        font-size: 10pt;
        line-height: .6cm;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: rgb(var(--v-theme-secondary));
        font-weight: bold;
        border: 1px solid rgb(var(--v-theme-secondary));
        border-bottom: none;
      }
    }
  }

}
</style>
<template>
  <v-container class="pa-0 rootContainer">
    <v-row align="center" dense class="ma-0">
      <v-col cols="4" class="pa-0">
        <v-btn
          icon
          :color="modelValue === min ? 'grey' : 'secondary'"
          size="x-small"
          elevation="2"
          :disabled="modelValue === min"
          @click="decrease"
        >
          <v-icon> mdi-minus-thick </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" class="text-center pa-0">
        {{ modelValue }}
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-btn
          icon
          :color="modelValue === max ? 'grey' : 'secondary'"
          size="x-small"
          elevation="2"
          :disabled="modelValue === max"
          @click="increase"
        >
          <v-icon> mdi-plus-thick </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "IntStepper",
  components: {},
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: -Number.MAX_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    decrease() {
      if (this.min !== null) {
        if (this.modelValue > this.min) {
          this.$emit("update:modelValue", this.modelValue-1)
        }
      } else {
        this.$emit("update:modelValue", this.modelValue-1)
      }
    },
    increase() {
      if (this.max !== null) {
        if (this.modelValue < this.max) {
          this.$emit("update:modelValue", this.modelValue+1)
        }
      } else {
        this.$emit("update:modelValue", this.modelValue+1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rootContainer {
  display: inline-block;
  min-width: 96px;
  width: auto;
}
</style>
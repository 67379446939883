<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        xl="4"
      >
        <v-card elevation="24" :loading="saving">
          <v-toolbar
            color="primary"
            flat
          >
            <v-toolbar-title>
              <v-toolbar-title>
                <v-avatar class="mr-2">
                  <v-img
                    :src="logo"
                  />
                </v-avatar>
                Il est temps d'être créatif
              </v-toolbar-title>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text v-if="!displayConfirm">
            <v-form>
              <h3>
                Définissez votre nouveau mot de passe :
              </h3>
              <v-text-field
                id="password"
                v-model="password"
                label="Mot de passe"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
            <h4>
              Les recommandations en terme de sécurité sont :
            </h4>
            <ul>
              <li>Au moins 8 charactères (obligatoire)</li>
              <li>Pas le même mot de passe qu'avant (obligatoire)</li>
              <li>Si il est peu long (moins de 50 lettres), utiliser des chiffres et caractères spéciaux</li>
              <li>Soyez créatifs : 5 mots du dictionnaire racontent facilement une histoire</li>
            </ul>
          </v-card-text>
          <v-card-text v-if="displayConfirm">
            <v-alert
              color="success"
              border="top"
              border-color
              elevation="2"
            >
              Bravo, tout est paré ! Vous pouvez maintenant vous connecter.
            </v-alert>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-btn
              v-if="!displayConfirm"
              variant="text"
              to="/login"
            >
              Pas besoin de changer
            </v-btn>
            <v-btn
              v-if="!displayConfirm"
              color="primary"
              @click="save"
            >
              Sauvegarder
            </v-btn>
            <v-btn
              v-if="displayConfirm"
              to="/login"
              color="primary"
            >
              C'est parti !
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import shared from "@/shared"
import { useRepo } from 'pinia-orm'
import { UserRepo } from '@/models/User'
import { useToast } from 'vue-toastification'
import logoImg from '@/assets/logo.png'

export default {
  name: 'NewPasswordView',
  beforeRouteEnter: function(to, from, next) {
    document.body.classList.add('login')
    next()
  },
  beforeRouteLeave: function(to, from, next) {
    document.body.classList.remove('login')
    next()
  },
  data: () => ({
    logo: logoImg,
    password: '',
    token: '',
    showPassword: false,
    saving: false,
    displayConfirm: false
  }),
  created() {
    const vars = shared.getUrlVars()
    if(vars['token']) {
      this.token = vars['token']
    }
  },
  methods: {
    save: async function () {
      try {
        this.saving = true
        this.displayConfirm = false
        const newPwd = await useRepo(UserRepo).$setPassword({
          password: this.password,
          token: this.token
        })
        this.saving = false
        this.displayConfirm = true
      }
      catch(e) {
        this.saving = false
        const toast = useToast()
        toast('Nous n\'avons pas pu sauvegarder ce mot de passe...', {
          type: 'error'
        })
      }
    }
  }
}
</script>
<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    class="maximizedCard"
  >
    <v-card-title class="text-h5 bg-primary text-white d-flex justify-space-between">
      Recettes en ligne
      <v-btn
        icon
        size="small"
        variant="text"
        @click="close"
      >
        <v-icon size="x-large">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="scrollbars">
      <v-alert
        border="top"
        border-color
        color="accent"
        elevation="2"
        class="mb-6"
        density="compact"
        variant="tonal"
      >
        <v-row dense align="center">
          <v-col cols="auto">
            <v-icon>
              mdi-information
            </v-icon>
          </v-col>
          <v-col>
            Cette fonctionnalité est encore en phase de test et peut être instable...
          </v-col>
        </v-row>
      </v-alert>
      <v-row
        class="listHeader"
        dense
      >
        <v-col cols="12" sm="4" md="2">
          <v-select
            v-model="source"
            :items="sources"
            item-title="text"
            item-value="value"
            label="Source"
            hide-details
            variant="outlined"
            density="comfortable"
          />
        </v-col>
        <v-col>
          <v-text-field
            ref="search"
            v-model="search"
            label="Rechercher"
            single-line
            hide-details
            clearable
            @keydown="validateSearch"
            @click:clear="clear"
          />
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-btn
            icon
            variant="outlined"
            size="small"
            :loading="searchLoading"
            @click="searchOnline"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="recipe in results"
          :key="recipe.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          align-self="stretch"
        >
          <recipe-card
            :recipe="recipe"
            :description="recipe.description"
            :description-only="source==='marmiton'"
            class="fill-height"
            @clicked-card="select(recipe)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm';
import { Recipe, RecipeRepo } from '@/models/Recipe'
import RecipeCard from '@/components/RecipeCard.vue'
import { useToast } from 'vue-toastification'
import _ from 'lodash'

export default {
    name: 'RecipeOnlineSearch',
    components: {
      RecipeCard
    },
    emits: ['close', 'create'],
    data: () => ({
      loading: false,
      search: '',
      searchLoading: false,
      results: [],
      source: 'marmiton',
      sources: [
        {text: 'Marmiton', value: 'marmiton'},
        {text: 'Magimix Cook Expert', value: 'magimix_cook_expert'},
        {text: 'Cookomix', value: 'cookomix'}
      ]
    }),
    computed: {
    },
    created() {
    },
    mounted () {
      setTimeout(() => this.$refs.search.focus(), 100)
    },
    methods: {
      clear () {
        this.searchOnline(null)
      },
      validateSearch (e) {
      if(e.keyCode === 13) {
        this.searchOnline()
      }
      },
      searchOnline: _.debounce(async function (e) {
        if(this.search) {
          this.searchLoading = true
          this.results = []
          useRepo(RecipeRepo).$onlineSearch(this.source, this.search)
            .then(res => {
              // Use entities cleanup by store
              this.results = res.map((r, index) => {
                let recipe = {
                  ...useRepo(Recipe).new(),
                  ...r,
                  id: index
                }
                if(recipe.description) {
                  recipe.description = recipe.description.replace(/\.([^ \.])/g, ".\r\n$1")
                }
                return recipe
              })
            })
            .catch(err => {
              const toast = useToast()
              toast('Il y a eu un problème avec la recherche. Essayez un autre terme.', {
                type: 'error'
              })
            })
            .finally(() => {
              this.searchLoading = false
            })
        }
        else {
          this.results = []
        }
      }, 500),
      async select (recipe) {
        if(recipe.link) {
          this.loading = true
          try {
            const response = await useRepo(RecipeRepo).$onlineDetails(this.source, recipe.link)
            this.$emit('close')
            this.$emit('create', response)
          }
          catch(e) {
            const toast = useToast()
            toast('Il y a eu un problème lors de la récupération des informations de cette recette.', {
              type: 'error'
            })
          }
          finally {
            this.loading = false
          }
        }
      },
      close () {
        this.$emit('close')
      }
    }
};
</script>

<style lang="scss" scoped>
.maximizedCard {
  flex-basis: 80vh !important;
}
</style>
<template>
  <v-container
    fluid
    :class="'pa-0 recipeList flex-column align-stretch fill-height ' + (recipes.length > 0 ? 'filled' : 'empty')"
  >
    <full-page-list
      ref="list"
      :load-items="loadRecipes"
      :per-page="defaultPerPage"
      :filters="activeFilters"
    >
      <template #prepend-buttons>
        <v-badge
          v-model="hasFiltersCounter"
          :content="filtersCounter"
        >
          <v-btn
            icon
            variant="outlined"
            color="primary"
            size="small"
            :disabled="isOffline"
            @click="openFilters()"
          >
            <v-icon size="large">
              mdi-filter-outline
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <template #buttons>
        <slot name="buttons" />
      </template>

      <template #item="scope">
        <recipe-card :recipe-id="scope.item.id" :loading="loadingRecipe === scope.item.id" @clicked-card="$emit('clickedRecipe', scope.item)" />
      </template>

      <template #empty>
        <v-row
          v-if="!loading && recipes.length === 0"
          align="start"
          justify="center"
          class="listPlaceholder"
        >
          <v-col cols="12" md="2" class="text-center">
            <v-img
              src="@/assets/recipe.png"
              max-width="60vw"
              class="emptyImg"
            />
          </v-col>
          <v-col cols="12 mt-12" md="2" class="text-center">
            <p>Aucune recette...</p>
          </v-col>
        </v-row>
      </template>
    </full-page-list>
    <v-navigation-drawer
      id="filterPanel"
      v-model="showFilters"
      absolute
      temporary
      class="pa-3"
    >
      <h2 class="d-flex">
        Filtres
        <v-spacer />
        <v-btn
          icon
          variant="outlined"
          size="x-small"
          color="primary"
          @click="closeFilters()"
        >
          <v-icon size="large">
            mdi-close
          </v-icon>
        </v-btn>
      </h2>
      <v-switch
        v-model="editionFilters.season"
        label="Uniquement de saison"
        hide-details
        class="mb-5 ml-3"
      />
      <v-select
        v-model="editionFilters.dish_type"
        :items="dishTypes"
        item-title="text"
        item-value="value"
        label="Type de plat"
        clearable
        variant="outlined"
        multiple
        hide-details
        class="mb-3"
      />
      <v-select
        v-model="editionFilters.cuisine_type"
        :items="cuisineTypes"
        item-title="text"
        item-value="value"
        label="Type de cuisine"
        clearable
        variant="outlined"
        multiple
        hide-details
        class="mb-3"
      />
      <v-select
        v-model="editionFilters.baking_type"
        :items="bakingTypes"
        item-title="text"
        item-value="value"
        label="Type de cuisson"
        clearable
        variant="outlined"
        multiple
        hide-details
        class="mb-5"
      />
      <div class="text-center">
        <v-btn
          color="primary"
          variant="outlined"
          class="mb-2"
          @click="resetFilters()"
        >
          Réinitialiser
        </v-btn>
        <v-btn
          color="primary"
          @click="validateFilters()"
        >
          Valider
        </v-btn>
      </div>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Recipe, RecipeRepo } from '@/models/Recipe'
import RecipeCard from '@/components/RecipeCard.vue'
import FullPageList from '@/components/FullPageList.vue'
import _ from 'lodash'

export default {
    name: 'RecipeList',
    components: {
      RecipeCard,
      FullPageList
    },
    props: {
      loadingRecipe: {
        type: Number,
        default: 0
      }
    },
    emits: ['clickedRecipe'],
    data: () => ({
      defaultPerPage: 20,
      loading: false,
      nothingToLoad: false,
      offset: 0,
      perPage: 10,
      recipes: [],
      search: '',
      activeFilters: {},
      editionFilters: {},
      showFilters: false
    }),
    computed: {
      isOffline() {
        return useRepo(Globalconfig).find('isOffline').value
      },
      dishTypes () {
        return Recipe.dishTypes()
      },
      cuisineTypes () {
        return Recipe.cuisineTypes()
      },
      bakingTypes () {
        return Recipe.bakingTypes()
      },
      filtersCounter() {
        return Object.keys(this.activeFilters).filter((f) => {
          return (!Array.isArray(this.activeFilters[f]) && !!this.activeFilters[f]) || this.activeFilters[f].length
        }).length
      },
      hasFiltersCounter () {
        return this.filtersCounter > 0
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      openFilters() {
        this.showFilters = true
        this.editionFilters = {...this.activeFilters}
      },
      async applyFilters() {
        this.activeFilters = {...this.editionFilters}
      },
      async resetFilters() {
        this.editionFilters = {}
      },
      closeFilters() {
        this.showFilters = false
      },
      validateFilters() {
        this.showFilters = false
        this.applyFilters()
      },
      loadRecipes:async function (settings) {
        if(this.isOffline) {
          return
        }

        const recipes = await useRepo(RecipeRepo).$fetch(settings)
        // Force sorting since API response order is not respected
        if(recipes && recipes.length) {
          recipes.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1
            }
            return 0
          })
          return recipes
        }
        else {
          return []
        }
      },
      reload () {
        this.$refs.list.reload()
      },
    }
};
</script>

<style lang="scss" scoped>
.listContent {
  flex-basis: 0;
  overflow: auto;
  padding: 0 12px;
  margin: 0 -12px;
}
.listPlaceholder {
  align-self: stretch;

  .emptyImg {
    margin: 0 auto;
  }
}
.loader{
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(var(--v-theme-lowkey));
}

.minuteFilter {
  width: 80px;
  display: inline-block;
}
</style>
<template>
  <div class="daysList">
    <v-btn
      icon
      color="primary"
      :size="$vuetify.display.mdAndUp ? 'large' : ''"
      title="Semaine précédente"
      variant="text"
      @click="previousWeek"
    >
      <v-icon size="x-large">
        mdi-chevron-double-left
      </v-icon>
    </v-btn>
    <div
      v-for="(item, index) in days"
      :key="item.key"
      :class="'daySelector' + (item.today ? ' today ' : '') + ($vuetify.display.smAndUp ? '' : ' narrow ') + (allowSelection && item.selected ? ' selected ' : '') + (!allowSelection ? ' noSelection ' : '')"
      @click="selectDay(item.date)"
    >
      <div class="label">
        {{ $vuetify.display.smAndUp ? item.weekDayLabel : item.weekDayLetter }}
      </div>
      <div class="number">
        {{ item.dayNumber }}
      </div>
      <div v-if="(allowSelection && item.selected) || (!allowSelection && (index === 0 || ''+item.dayNumber === '1'))" class="month">
        {{ $vuetify.display.smAndUp ? item.monthLabel : item.monthShortLabel }}
      </div>
    </div>
    <v-btn
      icon
      color="primary"
      :size="$vuetify.display.mdAndUp ? 'large' : ''"
      title="Semaine suivante"
      variant="text"
      @click="nextWeek"
    >
      <v-icon size="x-large">
        mdi-chevron-double-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
    name: 'DateSwitch',
    components: {
    },
    props: {
      days: {
        type: Array,
        default: () => []
      },
      allowSelection: {
        type: Boolean,
        default: true
      }
    },
    emits: ['previousWeek', 'nextWeek', 'selectDay'],
    methods: {
      previousWeek () {
        this.$emit('previousWeek')
      },
      nextWeek () {
        this.$emit('nextWeek')
      },
      selectDay(date) {
        if(this.allowSelection) {
          this.$emit('selectDay', date)
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.daysList {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .daySelector {
    text-align: center;
    user-select: none;
    display: inline-block;
    border: 1px solid rgb(var(--v-theme-background-darken-1));
    background-color: rgb(var(--v-theme-background-lighten-1));
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    width: 60px;
    margin: 0 5px;
    padding: 10px;
    height: 80px;

    .month {
      text-transform: capitalize;
      font-weight: 300;
      font-size: .70em;
      text-align: center;
      margin: 0 -10px;
    }

    &.noSelection {
      cursor: default;

      .month {
        margin-top: -8px;
      }
    }

    &.selected {
      background-color: rgb(var(--v-theme-primary));
      color: white;
      transform: scale(1.15);
      margin: 0 7px;
      padding: 5px;

      .number {
        line-height: 30px;
      }

    }

    &.today::after {
      content: '';
      height: 4px;
      display: absolute;
      background: rgb(var(--v-theme-primary));
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.selected.today {
        .number {
          line-height: 22px;
        }

        .month {
          margin-bottom: 8px;
        }

      &::after {
        background: rgb(var(--v-theme-background));
      }
    }

    &.narrow {
      margin: 0 2px;
      padding: 3px;
      width: 35px;
      height: 65px;

      .number {
        line-height: 30px;
      }

      &.selected {
        .number {
          line-height: 22px;
        }

        &.today {
          .label {
            line-height: 20px;
          }
          .number {
            line-height: 18px;
          }
        }
      }
    }

    .label {
      text-transform: capitalize;
    }

    .number {
      font-size: 22px;
      line-height: 35px;
      font-weight: bold;
    }
  }
}
</style>
<template>
  <v-container
    fluid
    :class="'ingredientList fill-height pb-0 ' + (ingredients.length > 0 || fillingBasics ? 'filled' : 'empty')"
  >
    <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.display.xs" scrollable>
      <v-card>
        <v-card-title class="bg-primary text-white">
          <span class="text-h5">
            {{ formTitle }}
          </span>
          <span v-if="editedItem.basic" class="basic">
            <v-icon size="small">
              mdi-water-check
            </v-icon>
            Ingrédient par défaut
          </span>
        </v-card-title>

        <v-card-text>
          <v-container class="pa-3">
            <v-row class="my-0">
              <v-col cols="auto">
                <v-img
                  v-if="editedItem.image_url"
                  :src="editedItem.image_url"
                  height="100"
                  width="100"
                />
                <div
                  v-if="!editedItem.image_url"
                  class="noIngredientImage"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.name"
                  :readonly="editedItem.basic"
                  label="Nom"
                  hide-details
                >
                  <template #append>
                    <v-icon
                      v-if="editedItem.basic"
                    >
                      mdi-pencil-lock-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col grow>
                <v-select
                  v-model="editedItem.type"
                  :readonly="editedItem.basic"
                  :items="ingredientType"
                  item-title="text"
                  item-value="value"
                  label="Type"
                  hide-details
                  clearable
                  variant="underlined"
                >
                  <template #append>
                    <v-icon
                      v-if="editedItem.basic"
                    >
                      mdi-pencil-lock-outline
                    </v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row dense justify="center" class="my-0">
              <v-col cols="auto">
                <v-btn
                  variant="outlined"
                  @click="editedItem.prefer_organic = !editedItem.prefer_organic"
                >
                  <v-icon class="mr-2">
                    {{ editedItem.prefer_organic ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  J'achète bio
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense justify="center" class="my-0">
              <v-col cols="auto">
                <v-btn
                  variant="outlined"
                  @click="editedItem.prefer_bulk = !editedItem.prefer_bulk"
                >
                  <v-icon class="mr-2">
                    {{ editedItem.prefer_bulk ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  J'achète en vrac
                </v-btn>
              </v-col>
              <v-col v-if="editedItem.prefer_bulk" cols="auto" align-self="center">
                <v-btn
                  icon
                  size="x-small"
                  :variant="!editedItem.need_bag ? 'outlined' : 'flat'"
                  color="primary"
                  class="mr-2"
                  title="Dans un sac"
                  :disabled="editedItem.basic"
                  @click="toggleBag"
                >
                  <v-icon>
                    mdi-shopping
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  size="x-small"
                  :variant="!editedItem.need_box ? 'outlined' : 'flat'"
                  color="primary"
                  title="Dans une boîte"
                  :disabled="editedItem.basic"
                  @click="toggleBox"
                >
                  <v-icon>
                    mdi-basket-fill
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col>
                <v-text-field
                  v-model="editedItem.default_amount"
                  :readonly="editedItem.basic"
                  label="Quantité par défaut"
                  type="number"
                  min="1"
                >
                  <template #append>
                    <v-icon
                      v-if="editedItem.basic"
                    >
                      mdi-pencil-lock-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="editedItem.default_unit"
                  :readonly="editedItem.basic"
                  :items="ingredientUnits"
                  item-title="text"
                  item-value="value"
                  label="Unité"
                  variant="underlined"
                >
                  <template #append>
                    <v-icon
                      v-if="editedItem.basic"
                    >
                      mdi-pencil-lock-outline
                    </v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="isEditedSeasonned && editedItem.season_months" class="my-0">
              <v-col>
                <h3 class="mb-2">
                  Saisonnalité
                </h3>
                <v-btn
                  v-for="month in months"
                  :key="month.value"
                  icon
                  :variant="editedItem.season_months.indexOf(month.value)===-1 ? 'outlined' : 'flat'"
                  :disabled="editedItem.basic"
                  size="small"
                  color="primary"
                  class="mr-1"
                  @click="toggleMonth(month.value)"
                >
                  {{ month.letter }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12">
                <h3>
                  Aliases
                </h3>
                <div v-for="alias in editedItem.aliases" :key="alias">
                  {{ alias.toUpperCase() }}
                  <v-btn
                    v-if="!editedItem.basic"
                    icon
                    size="x-small"
                    variant="flat"
                    @click="removeAlias(alias)"
                  >
                    <v-icon size="large">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="!editedItem.basic" class="my-0">
              <v-col grow>
                <v-text-field v-model="newAlias" label="Ajouter un alias" />
              </v-col>
              <v-col align-self="center">
                <v-btn
                  icon
                  size="small"
                  color="primary"
                  variant="flat"
                  :disabled="!newAlias"
                  @click="addAlias"
                >
                  <v-icon size="large">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="elevation-4">
          <v-btn
            v-if="editedItem.id && !editedItem.basic"
            variant="text"
            color="error"
            @click="deleteItem"
          >
            Supprimer
          </v-btn>
          <div class="flex-grow-1" />
          <v-btn color="primary" variant="text" @click="close">
            Annuler
          </v-btn>
          <v-btn color="primary" variant="flat" @click="save">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displaySeasons" fullscreen scrollable>
      <v-card height="100%">
        <v-card-title class="bg-primary text-white text-h5 d-flex justify-space-between align-center">
          Calendrier saisonnier
          <v-btn
            icon
            variant="text"
            @click="displaySeasons = false"
          >
            <v-icon size="x-large">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 timelineContainer">
          <season-timeline :items="ingredients" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <full-page-list
      :key="'list_redner_'+listRenderKey"
      :items="ingredients"
      :search-properties="['name','aliases']"
      :per-page="12"
      :loading="loading"
    >
      <template #item="scope">
        <ingredient-card :ingredient="scope.item" @click="editItem(scope.item)" />
      </template>

      <template #empty>
        <v-row
          v-if="ingredients.length === 0 && !fillingBasics"
          align="start"
          justify="center"
          class="listPlaceholder"
        >
          <v-col cols="12" md="2" class="text-center">
            <v-img
              src="@/assets/emptyIngredients.png"
              max-width="60vw"
              class="emptyImg"
            />
          </v-col>
          <v-col cols="12 mt-12" md="2" class="text-center">
            <p>En manque d'ingrédients pour préparer tous les repas ? On a ce qu'il vous faut !</p>
            <v-btn
              color="primary"
              :disabled="isOffline"
              @click="confirmFill"
            >
              Ajouter les basiques
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template #buttons>
        <print-button
          v-if="entitiesToPrint.length > 0"
        />
        <v-btn
          v-if="activeFilter && activeFilter.seasonnable"
          icon
          size="small"
          class="mr-2"
          color="primary"
          @click="displaySeasons = true"
        >
          <v-icon size="large">
            mdi-sprout
          </v-icon>
        </v-btn>
        <v-btn
          icon
          variant="outlined"
          color="primary"
          size="small"
          class="mr-2 ligthenBg"
          :disabled="isOffline"
          @click="addItem"
        >
          <v-icon size="large">
            mdi-plus
          </v-icon>
        </v-btn>
        <VDropdown
          v-if="$vuetify.display.mdAndUp"
          trigger="click"
          class="extraAction"
          container="#app"
        >
          <template #popper>
            <v-container class="background text-center pa-2">
              <v-row dense>
                <v-col>
                  <v-btn
                    variant="outlined"
                    :href="downloadLink"
                  >
                    <v-icon class="mr-2">
                      mdi-download-circle
                    </v-icon>
                    Télécharger la liste
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-btn
                    variant="outlined"
                    @click="updateBasics"
                  >
                    <v-icon class="mr-2">
                      mdi-sync-circle
                    </v-icon>
                    Mettre à jour les basiques
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-btn
                    variant="outlined"
                    @click="selectForPrint"
                  >
                    <v-icon class="mr-2">
                      mdi-printer-check
                    </v-icon>
                    Sélectionner tous les ingrédients pour impression
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-btn
            icon
            variant="outlined"
            size="small"
            class="ligthenBg"
            :disabled="isOffline"
          >
            <v-icon size="large">
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </VDropdown>
      </template>

      <template #extraHeader>
        <div v-if="$vuetify.display.mdAndUp" class="filters mt-2">
          <v-btn
            v-for="(filter, index) in filters"
            :key="filter.value"
            :color="activeFilter && activeFilter.value === filter.value ? 'accent': ''"
            :class="index ? 'ml-3': ''"
            rounded
            @click="activateFilter(filter)"
          >
            {{ filter.extendedText }}
          </v-btn>
        </div>
        <div v-if="$vuetify.display.smAndDown" class="mt-2">
          <v-select
            v-model="activeFilter"
            variant="outlined"
            :items="filters"
            item-title="extendedText"
            hide-details
            return-object
            clearable
            @update:model-value="storeFilter"
          />
        </div>
      </template>
    </full-page-list>
  </v-container>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { User } from '@/models/User'
import { Community } from '@/models/Community'
import { Ingredient, IngredientRepo } from '@/models/Ingredient'
import { EntityToPrint } from '@/models/EntityToPrint'
import SeasonTimeline from '@/components/SeasonTimeline'
import FullPageList from '@/components/FullPageList'
import IngredientCard from '@/components/IngredientCard'
import PrintButton from '@/components/PrintButton'
import moment from 'moment'
import _ from 'lodash'
import emitter from 'tiny-emitter/instance'

export default {
    name: 'IngredientList',
    components: {
      SeasonTimeline,
      FullPageList,
      IngredientCard,
      PrintButton
    },
    data: () => ({
      loading: false,
      search: '',
      headers: [
        { text: 'Nom', value: 'name'},
        { text: 'Type', value: 'type', filterable: false},
        { text: 'Alias', value: 'aliases'},
        { text: 'Sac', value: 'need_bag', filterable: false},
        { text: 'Boîte', value: 'need_box', filterable: false},
        { text: 'Quantité', value: 'quantity', filterable: false},
        { text: 'Actions', value: 'action', sortable: false, filterable: false},
      ],
      editedItem: {},
      editedIndex: -1,
      newAlias: '',
      dialog: false,
      defaultFilter: {
        value: "others",
        text: "Autres",
        isValid: item => !item || !item.type
      },
      activeFilter: null,
      activeFilterStorageKey: 'ingredientList_filter',
      displaySeasons: false,
      fillingBasics: false,
      listRenderKey: 0
    }),
    computed: {
      isOffline() {
        return useRepo(Globalconfig).find('isOffline').value
      },
      entitiesToPrint () {
        return useRepo(EntityToPrint).all()
      },
      downloadLink () {
        const c = Community.getCurrentCommunity()
        if (c) {
          return import.meta.env.VITE_API_ENDPOINT + '/ingredient/export/get.php?token=' + User.getToken() + "&community=" + Community.getCurrentCommunity().id
        }
        else {
          return '#'
        }
      },
      ingredients () {
        const ingredients = useRepo(Ingredient).orderBy(i => i.name.toLowerCase()).get()
        if(this.activeFilter) {
          return ingredients.filter((item) => !this.activeFilter || this.activeFilter.isValid(item))
        }
        else {
          return ingredients
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nouvel ingrédient' : 'Edition de l\'ingrédient'
      },
      ingredientType () {
        return Ingredient.allTypes()
      },
      ingredientUnits () {
        return Ingredient.units()
      },
      filters() {
        const filters = [
          this.defaultFilter
        ]
        filters[0].extendedText = this.defaultFilter.text + " (" + useRepo(Ingredient).where("type", '').get().length + ")"
        const seasonnableTypes = Ingredient.seasonTypes()
        const ingTypes = [...Ingredient.allTypes()]
        ingTypes.map(f => {
          f.isValid = item => item && item.type === f.value
          f.count = useRepo(Ingredient)
                    .where("type", f.value)
                    .get()
                    .length
          f.extendedText = f.text + " ("+f.count+")"
          f.seasonnable = seasonnableTypes.indexOf(f.value) > -1
        })
        return ingTypes.concat(filters)
      },
      months () {
        const m = moment.monthsShort()
        return m.map((name, index) => {
          return {
            value: index+1,
            letter: name[0]
          }
        })
      },
      isEditedSeasonned() {
        return this.editedItem.type &&
               Ingredient.seasonTypes().indexOf(this.editedItem.type) > -1
      }
    },
    created() {
      const storedFilter = localStorage.getItem(this.activeFilterStorageKey)
      if(storedFilter) {
        const foundIt = this.filters.filter(f => f.value === storedFilter)
        if(foundIt && foundIt.length > 0) {
          this.activeFilter = foundIt[0]
        }
      }
    },
    mounted: async function () {
      if(!this.activeFilter) {
        this.activeFilter = this.defaultFilter
      }
      if(!this.isOffline) {
        this.loading = true
        await useRepo(IngredientRepo).$fetch()
        this.loading = false
      }
    },
    methods: {
      addItem (item) {
        this.editedItem = {
          ...new Ingredient(),
          aliases: []
        }
        this.editedIndex = -1
        this.dialog = true
      },
      editItem (item) {
        if(!this.isOffline) {
          this.editedIndex = this.ingredients.findIndex((ing) => ing.id == item.id);
          this.editedItem = _.cloneDeep(item)
          this.dialog = true
        }
      },
      addAlias () {
        if(this.newAlias) {
          this.editedItem.aliases.push(this.newAlias)
          this.newAlias = '';
        }
      },
      removeAlias (alias) {
        this.editedItem.aliases = this.editedItem.aliases.filter((el) => { return el !== alias })
      },
      toggleBag () {
        this.editedItem.need_bag = !!!this.editedItem.need_bag
        if(this.editedItem.need_bag) {
          this.editedItem.need_box = false
        }
      },
      toggleBox () {
        this.editedItem.need_box = !!!this.editedItem.need_box
        if(this.editedItem.need_box) {
          this.editedItem.need_bag = false
        }
      },
      toggleMonth(value) {
        const i = this.editedItem.season_months.indexOf(value)
        if(i > -1) {
          this.editedItem.season_months = this.editedItem.season_months.filter((m) => m !== value)
        }
        else {
          this.editedItem.season_months.push(value)
        }
      },
      deleteItem () {
        const item = this.editedItem
        emitter.emit('showConfirm', {
          title: 'Suppression',
          text: 'Voulez-vous supprimer l\'ingrédient "'+item.name+'" ?',
          validate: () => {
            this.dialog = false
            useRepo(IngredientRepo).$delete(item.id)
          }
        })
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, new Ingredient())
          this.editedItem.aliases = []
          this.editedIndex = -1
        }, 300)
      },
      async save () {
        if (this.editedIndex > -1) {
          useRepo(IngredientRepo).$update(this.editedItem.id, {
            ...this.editedItem
          })
          Object.assign(this.ingredients[this.editedIndex], this.editedItem)
        } else {
          useRepo(IngredientRepo).$create({
            ...this.editedItem
          })
          this.ingredients.push(this.editedItem)
        }
        this.close()
      },
      activateFilter(filter) {
        if(!this.activeFilter || this.activeFilter.value !== filter.value) {
          this.activeFilter = filter
          // Reset ingredients selected for print
          useRepo(EntityToPrint).flush()
        } else {
          this.activeFilter = null
        }
        this.storeFilter()
      },
      storeFilter() {
        if(this.activeFilter) {
          localStorage.setItem(this.activeFilterStorageKey, this.activeFilter.value)
        }
        else {
          localStorage.removeItem(this.activeFilterStorageKey)
        }
      },
      confirmFill() {
        var that = this
        emitter.emit('showConfirm', {
          title: 'Ajout de tous les ingrédients de base',
          text: 'Voulez-vous ajouter tous les ingrédients par défaut ? Cette procédure est irréversible.',
          validate: async function() {
            that.fillingBasics = true
            await useRepo(IngredientRepo).$fillBasics()
            await useRepo(IngredientRepo).$fetch()
            that.fillingBasics = false
          }
        })
      },
      updateBasics() {
        var that = this
        emitter.emit('showConfirm', {
          title: 'Mise à jour de tous les ingrédients de base',
          html: 'Voulez-vous mettre à jour tous les ingrédients par défaut ? <br/> '+
                'Cette procédure va supprimer vos modifications du <b>type</b> de produit, des <b>saisons</b>, des <b>alias</b>, des <b>quantités</b> par défaut et des besoins de <b>sac</b> ou <b>boite</b>.',
          validate: async function() {
            that.fillingBasics = true
            await useRepo(IngredientRepo).$updateBasics()
            await useRepo(IngredientRepo).$fetch()
            that.listRenderKey++
            that.fillingBasics = false
          }
        })
      },
      exportAsFile () {
        useRepo(IngredientRepo).$export()
      },
      selectForPrint () {
        this.ingredients.forEach((i) => {
          EntityToPrint.save({
              entity_id: i.id,
              entity_type: 'ingredient'
          })
        })
      }
    }
};
</script>

<style lang="scss" scoped>
.ingredientList {
  &.filled {
    align-content: start;
  }
  &.empty {
    align-content: stretch;
  }
}

.listHeader {
  align-self: start;
}
.listPlaceholder {
  align-self: stretch;

  .emptyImg {
    margin: 0 auto;
  }
}

.ingTable {
  width: 100%;
  align-self: start;

  th {
    white-space: nowrap;
  }
}

.timelineContainer {
  overflow: auto;
  max-height: 100%;
}

.extraAction {
  display: inline-flex;
}

.basic {
  font-size: 12px;
  float: right;
}

.noIngredientImage {
  height: 100%;
  min-height: 100px;
  width: 100px;
  padding-top: 100%;
  position: relative;

  &:after {
    content: "";
    border: 2px dashed rgb(var(--v-theme-background-darken-1));
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>